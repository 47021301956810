import React, { useState } from "react";
import {
  FormControlLabel,
  IconButton,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import "@fontsource/material-icons";
import "./SystemMessages.css";
import clsx from "clsx";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { useGetSystemMessagesOptionalQuery } from "../../../../../store/apis/SystemMessagesAPIs";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { API_ENDPOINTS } from "../../../../../store/store.constants";

const { SYSTEM_MESSAGES } = API_ENDPOINTS;

function SystemMessages() {
  const theme = useTheme();
  const [showArchived, setShowArchived] = useState(false);
  const url = showArchived
    ? `/${SYSTEM_MESSAGES}?skip=0&take=1000`
    : `/${SYSTEM_MESSAGES}/GetSystemMessageActivelist`;
  const {
    data: systemMessages,
    isFetching: loading,
    isError: hasError,
  } = useGetSystemMessagesOptionalQuery(url, {
    refetchOnMountOrArgChange: true,
  });

  const mTypes = [
    { key: 0, value: "System" },
    { key: 1, value: "Corporate Group" },
    { key: 2, value: "Facility" },
    { key: 3, value: "User" },
  ];

  const systemMessagesColumns = [
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      flex: 1,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      flex: 1,
      cellClassName: (params) => {
        return clsx("super-app", {
          expired: new Date(params.value) < Date.now(),
        });
      },
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 3,
      valueFormatter: (params) => {
        return params.value.replace(/(<([^>]+)>)/gi, "");
      },
    },
    {
      field: "type",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Type",
      flex: 1,
      valueFormatter: (params) => {
        return mTypes.find((mType) => mType.key === params.value).value;
      },
    },
    {
      field: "priority",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Priority",
      flex: 1,
    },
    {
      field: "title",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <SystemMessagesEdit
              index={params.row.id}
              systemMessages={systemMessages}
            />
            <SystemMessagesDelete
              id={params.row.id}
              name={params.row["title"] || ""}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box className="actionsRow">
        <Box className="leftActions">
          <Button
            sx={{ marginLeft: "0px !important" }}
            className="baseButton showArchiveAddIn"
            color={"primary"}
            variant="contained"
            endIcon={
              showArchived ? (
                <span className="material-icons">check</span>
              ) : (
                <span className="material-icons">close</span>
              )
            }
            onClick={() => {
              setShowArchived(!showArchived);
            }}>
            Show Archived
          </Button>
          <Button
            component={Link}
            to="addSystemMessage"
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">add_comment</span>}>
            Add System Message
          </Button>
        </Box>
      </Box>

      <BaseDataGrid
        rows={systemMessages}
        columns={systemMessagesColumns}
        error={hasError}
        loading={loading}
        autoHeight={false}
        height={{ md: "50dvh", xl: "53dvh" }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          "& .super-app.expired": {
            color: theme.palette.error.dark,
          },
        }}
      />
    </>
  );
}

const SystemMessagesEdit = ({ index, systemMessages }) => {
  return (
    <FormControlLabel
      control={
        <Link
          to={`addSystemMessage?id=${index}`}
          className="editIcon"
          state={{
            title: "Edit System Message",
            data: systemMessages.find((row) => row.id === index),
          }}>
          <span className="material-icons">edit</span>{" "}
        </Link>
      }
    />
  );
};

const SystemMessagesDelete = ({ id, name }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <FormControlLabel
        control={
          <IconButton
            aria-label="add an alarm"
            color="error"
            onClick={() => {
              setOpenModal(true);
            }}
            disableRipple>
            <span className="material-icons">delete</span>{" "}
          </IconButton>
        }
      />
      <DeleteModal
        id={id}
        entityName={name}
        apiPath={"deleteSystemMessages"}
        open={openModal}
        close={() => {
          setOpenModal(false);
        }}></DeleteModal>
    </>
  );
};

export default SystemMessages;
