import { Box, Typography } from "@mui/material";
import { BaseButton } from "components/shared/BaseButton";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetVisitDetailsQuery } from "store/apis/ConsultantApis";
import { useSentReportToDiningRDMutation } from "store/apis/DietitianAPis";

export const SendReportToDiningRd = () => {
  // states
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const visitPlanId = searchParams.get("visitPlanId");

  // Api Section
  // Api to get visitID
  const { data: visitData } = useGetVisitDetailsQuery(
    visitPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { id } = visitData || [];
  // mutation
  // mutation for submit report to dining Rd

  const [
    SubmitReportToHealthTech,
    { isLoading, isSuccess: submitReportSuccess },
  ] = useSentReportToDiningRDMutation();
  // function which will call mutation
  const submitReport = () => {
    SubmitReportToHealthTech(id);
  };
  // useEffect for navigation
  useEffect(() => {
    submitReportSuccess ? navigate(-1) : null;
  }, [submitReportSuccess]);
  // ui Section
  return (
    <Box
      marginTop={"20px"}
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}>
      <Box
        width={"50%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}>
        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          {"Submit Report To DiningRD"}
        </Typography>
        <Box
          marginY={"10px"}
          border="1px solid #CCCCCC"
          padding={"10px"}
          boxShadow={"var(--tealBoxShadow)"}
          borderRadius={"10px"}>
          <Typography>
            {`This will submit the report to DiningRD, and mark it as final. Once this is done,
 no further changes can be made to this visit report. Are you sure you want to do this?`}
          </Typography>
        </Box>
        <Box>
          <BaseButton
            text={"Yes, Submit the Report"}
            variant={"outlined"}
            isSubmitting={isLoading}
            disabled={isLoading}
            onClick={(event) => {
              event.preventDefault();
              submitReport();
            }}
          />
          <BaseButton
            variant={"outlined"}
            text={"No, Let me edit some  more"}
            To={`/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${visitPlanId}&isEdit=${true}`}
          />
        </Box>
      </Box>
    </Box>
  );
};
