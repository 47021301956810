import React, { useState } from "react"; //{ useEffect }
import "../Ingredient.css";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useGetUnitConversionQuery } from "../../../../../../store/apis/IngredientAPIs";
import DeleteModal from "../../../../../shared/deleteModal/DeleteModal";
import {
  Typography,
  Button,
  FormControlLabel,
  IconButton,
  Grid,
} from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function UnitConversion() {
  const [searchParams] = useSearchParams();
  const ingredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name");

  const {
    data: unitConversionList,
    isError: isError,
    isFetching: isLoading,
  } = useGetUnitConversionQuery(ingredientId);

  const navigate = useNavigate();

  const UnitConversionEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addUnitConversion?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Unit Conversion",
              data: unitConversionList.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const UnitConversionDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteUnitConversion"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  const UnitConversionColumns = [
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            variant="body1"
            component="div">{`${params.row.quantity} ${params.row.unitName}`}</Typography>
        );
      },
    },
    {
      field: "convertToQuantity",
      headerName: "Quantity",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            variant="body1"
            component="div">{`${params.row.convertToQuantity} ${params.row.convertToUnitName}`}</Typography>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <UnitConversionEdit index={params.row.id} />
            <UnitConversionDelete
              id={params.row.id}
              name={`${params.row.quantity} ${params.row.unitName} to ${params.row.convertToQuantity} ${params.row.convertToUnitName} conversion for ${ingredientName}`}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <BaseButton
            text="Back To Ingredients"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
            sx={{ marginBottom: "10px" }}
          />
          <Button
            component={Link}
            to={`addUnitConversion?ingredientId=${ingredientId}`}
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">add_business</span>}
            sx={{ marginBottom: "25px", marginLeft: "20px" }}>
            Add Conversion
          </Button>
        </Grid>
      </Grid>
      <Typography color="primary" variant="h5" className="userFacilityTitle">
        Conversions for {ingredientName}
      </Typography>

      <BaseDataGrid
        rows={unitConversionList}
        columns={UnitConversionColumns}
        loading={isLoading}
        error={isError}
      />
    </>
  );
}
