import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useCopyToCorporateGroupMutation,
  useFacilityDietsQuery,
  useGetFacilityByIdQuery,
  useUpdateFacilityDietsIDDSIMutation,
} from "../../../../../../store/apis/FacilityApis";

import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import YesNoDisplay from "../../../../../shared/YesNoDisplay";
import { BaseButton } from "components/shared/BaseButton";

export default function CommunityDiets() {
  const [isterminology, setIsterminology] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openCopyModal, setOpenCopyModal] = useState(false);

  const { data: community, isSuccess: communitySuccess } =
    useGetFacilityByIdQuery(id, { skip: !id });

  const [addIDDSI] = useUpdateFacilityDietsIDDSIMutation();

  const {
    data: diets,
    isFetching: fetching,
    isError: hasError,
  } = useFacilityDietsQuery(id, { refetchOnMountOrArgChange: true, skip: !id });

  const onSubmit = (value) => {
    addIDDSI({ useIDDSITerminology: value, facilityId: id });
  };

  useEffect(() => {
    if (communitySuccess && community?.useIddsiterminology !== null) {
      setIsterminology(community?.useIddsiterminology);
    }
  }, [communitySuccess]);

  const dietColumns = [
    {
      field: "commonDietName",
      headerName: "Common Diet Name",
      flex: 1,
    },
    {
      field: "isTexture",
      headerName: "Is Texture",
      flex: 1,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.isTexture} />;
      },
    },
    {
      field: "dietName",
      headerName: "Community Diet Name",
      flex: 1,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 0.5,
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addcommunitydiet?id=${id}&fdId=${params.row.id}`}
              data={diets}
              title="View Corporate Group"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.dietName}
              apiPath="deleteFacilityDiet"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={{ md: "center", xs: "start" }}
        paddingBottom={2}>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={{ xs: "100%", md: "60%", lg: "50%" }}>
          <BaseButton
            text={"Add Community Diet"}
            endIcon={"post_add"}
            To={`addcommunitydiet?id=${id}`}
            marginLeft={"0px !important"}
          />

          <div style={{ width: 250 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isterminology}
                  onClick={() => {
                    setIsterminology(!isterminology);
                    onSubmit(!isterminology);
                  }}
                />
              }
              label="Use IDDSI Terminology"
            />
          </div>
        </Grid>
        <Box width={{ xs: "100%", sm: "auto" }}>
          <BaseButton
            sx={{ width: { xs: "100%", sm: "auto" } }}
            text={"Copy to Corporate Group"}
            onClick={() => setOpenCopyModal(true)}
            endIcon={"copy_all"}
            colors="error"
            marginLeft={"0px !important"}
          />
        </Box>
      </Grid>
      {openCopyModal && (
        <CopyToCorporateGroupModal
          open={openCopyModal}
          close={() => setOpenCopyModal(false)}
          cgName={communitySuccess && community?.corporateGroup}
          facilityName={communitySuccess && community?.name}
          id={id}
        />
      )}

      <BaseDataGrid
        rows={diets}
        loading={fetching}
        error={hasError}
        columns={dietColumns}
        autoHeight={true}
      />
    </>
  );
}

function CopyToCorporateGroupModal({ facilityName, cgName, id, open, close }) {
  const [startCopy, { isLoading, isSuccess }] =
    useCopyToCorporateGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}>
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box className="modalContent">
            <p>
              <span className="material-icons modalIcon" color={"warning"}>
                report
              </span>
            </p>
            <Box>
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h6"
                component="h2"
                className="modalTitle">
                {`Diets for "${facilityName}"`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{ mt: 2 }}>
                {`This will remove the diets for all communities that are linked to the "${cgName}" Corporate Group, and replace it with the diets that are currently setup for this community. Are you sure you want to continue?`}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 0.5 }} />
          <Box className="deleteActions" flex={true}>
            <Button
              className="baseButton"
              variant="contained"
              onClick={close}
              color={"warning"}>
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: "10px" }}
              className="baseButton"
              variant="contained"
              onClick={() => {
                startCopy(id);
              }}
              startIcon={
                isLoading ? <CircularProgress color="white" size={18} /> : ""
              }
              color={"error"}>
              Continue
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
