import { PAGE_ROLES } from "../shared/constants";

export let SidebarElements = [
  {
    Name: "Home",
    Link: "/home",
    Icon: "home",
    IsActive: true,
    AuthenticatedOnly: false,
  },
  {
    Name: "Client Portal",
    Link: "/clientPortal",
    Icon: "computer",
    IsActive: false,
    AuthenticatedOnly: true,
    AllowedRoles: PAGE_ROLES.CONSULTANT_REPORTS,
  },
  {
    Name: "Consultant",
    Link: "/Consultant/MyRosters",
    AltLink: "/Consultant",
    Icon: "assignment",
    IsActive: false,
    AuthenticatedOnly: true,
    AllowedRoles: PAGE_ROLES.CONSULTANT,
  },
  {
    Name: "PlateFul",
    Link: "/plateFul/Menus",
    AltLink: "/plateFul",
    Icon: "flatware",
    IsActive: false,
    AuthenticatedOnly: true,
    AllowedRoles: PAGE_ROLES.PLATEFUL.concat(PAGE_ROLES.PLATEFUL_MENUS),
  },
  {
    Name: "MealCard",
    Link: "/MealCard",
    Icon: "groups",
    IsActive: false,
    AuthenticatedOnly: true,
    AllowedRoles: PAGE_ROLES.MEALCARD,
  },
  {
    Name: "TableSide",
    Link: "/TableSide",
    Icon: "table_restaurant",
    IsActive: false,
    AuthenticatedOnly: true,
    AllowedRoles: PAGE_ROLES.TABLESIDE,
  },
  {
    Name: "Administration",
    Link: "/admin",
    Icon: "admin_panel_settings",
    IsActive: false,
    AuthenticatedOnly: true,
    AllowedRoles: PAGE_ROLES.ADMIN,
  },
  {
    Name: "Help",
    Link: "/FAQ",
    Icon: "help",
    IsActive: false,
    AuthenticatedOnly: false,
  },
  {
    Name: "Chat RD",
    Link: "/chatAIConvo",
    Icon: "chat",
    IsActive: false,
    AuthenticatedOnly: true,
    AllowedRoles: PAGE_ROLES.AI_CHAT,
  },
  {
    Name: "Contact Us",
    Link: "/contactUs",
    Icon: "phone",
    IsActive: false,
    AuthenticatedOnly: false,
  },
];
