import React from "react";
import { FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetStandardTextListQuery } from "../../../../../store/apis/StandardTextApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";

export default function RecipeCategories() {
  const {
    data: standardText,
    isLoading: isLoading,
    isError: isError,
  } = useGetStandardTextListQuery();

  const StandardTextEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`editStandardText?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Standard Text",
              data: standardText.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const StandardTextColumns = [
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <StandardTextEdit index={params.row.id} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow"></div>
      <BaseDataGrid
        rows={standardText}
        columns={StandardTextColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "61dvh", xl: "62dvh" }}
      />
    </>
  );
}
