import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RECIPE_INGREDIENTS, INGREDIENTS, UNITS } = API_ENDPOINTS;

export const RecipeIngredient = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipeIngredient: builder.query({
      query: ({ id }) => `/${RECIPE_INGREDIENTS}?recipeId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_INGREDIENTS, id })),
              RECIPE_INGREDIENTS,
            ]
          : [RECIPE_INGREDIENTS];
      },
    }),
    getRecipeIngredientDnD: builder.query({
      query: ({ id }) => `/${RECIPE_INGREDIENTS}?recipeId=${id}`,
      transformResponse: (response) =>
        response?.data
          ?.map((item) => ({
            id: item?.id,
            sortOrder: item?.sortOrder,
            name: item?.ingredient,
          }))
          ?.sort((a, b) => parseFloat(a.sortOrder) - parseFloat(b.sortOrder)),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_INGREDIENTS, id })),
              RECIPE_INGREDIENTS,
            ]
          : [RECIPE_INGREDIENTS];
      },
    }),
    getRecipeIngredientUnits: builder.query({
      query: () => `/${UNITS}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    getRecipeIngredientsName: builder.query({
      query: (searchText) =>
        `/${INGREDIENTS}/IngredientLookup?searchText=${searchText}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
        keepUnusedDataFor: 0,
    }),
    getRecipeIngredientById: builder.query({
      query: (id) => `/${RECIPE_INGREDIENTS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [{ type: RECIPE_INGREDIENTS, id: response.id }]
          : [RECIPE_INGREDIENTS];
      },
    }),
    updateRecipeIngredients: builder.mutation({
      query: (data) => {
        return {
          url: `/${RECIPE_INGREDIENTS}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (response) => {
        return response?.data;
      },
      invalidatesTags: (result, error, arg) => [
        { type: RECIPE_INGREDIENTS, id: arg.id },
      ],
    }),
    addRecipeIngredients: builder.mutation({
      query: (data) => {
        return {
          url: `/${RECIPE_INGREDIENTS}`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => {
        return response?.data;
      },
      invalidatesTags: (result, error, arg) => [
        { type: RECIPE_INGREDIENTS, id: arg.id },
      ],
    }),
    deleteRecipesIngredient: builder.mutation({
      query: (id) => ({
        url: `/${RECIPE_INGREDIENTS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RECIPE_INGREDIENTS, id: arg.id },
      ],
    }),
    reOrderRecipeIngredients: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_INGREDIENTS}/reorder`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => {
        return arg
          ? [
              ...arg.map(({ id }) => ({ type: RECIPE_INGREDIENTS, id })),
              RECIPE_INGREDIENTS,
            ]
          : [RECIPE_INGREDIENTS];
      },
    }),
  }),
});
export const {
  useGetRecipeIngredientQuery,
  useGetRecipeIngredientByIdQuery,
  useGetRecipeIngredientUnitsQuery,
  useUpdateRecipeIngredientsMutation,
  useAddRecipeIngredientsMutation,
  useGetRecipeIngredientsNameQuery,
  useGetRecipeIngredientDnDQuery,
  useReOrderRecipeIngredientsMutation,
} = RecipeIngredient;
