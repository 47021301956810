import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { useGetPurchasingGroupListQuery } from "../../../../../store/apis/PurchasingGroupApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../shared/EditButton";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";

export default function PurchasingGroups() {
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: purchasingGroups,
    isLoading: isLoading,
    isError: isError,
  } = useGetPurchasingGroupListQuery(debouncedValue || "", {
    refetchOnMountOrArgChange: true,
  });

  const PurchasingGroupOptions = ({ index, id, name }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);

    const handleClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Link
          id={"menu-button-" + index}
          aria-controls={open ? "menu-" + index : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          className="menuButton"
          to={"/"}
          onClick={handleClick}>
          <span className="material-icons">more_vert</span>
        </Link>
        <Menu
          id={"edit-menu" + index}
          MenuListProps={{
            "aria-labelledby": "menu-button-" + index,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
              handleClose();
            }}
            className="menuDeleteButton"
            disableRipple>
            Delete
          </MenuItem>
        </Menu>
        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deletePurchasingGroup"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const purchasingGroupColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "sageCustomerId",
      headerName: "Sage Customer Id",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "numberOfFacilities",
      headerName: "# of Facilities",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        if (params.row["numberOfFacilities"] === 0) {
          return params.row["numberOfFacilities"];
        } else
          return (
            <Link
              to={`/admin/communities/communitySetup?purchasingGroupId=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params.row["numberOfFacilities"]}
            </Link>
          );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addPurchasingGroup?id=${params.row.id}`}
              data={purchasingGroups}
              title="Purchasing Group"
            />
            <PurchasingGroupOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row["name"] || ""}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
          />
          <Button
            component={Link}
            to="addPurchasingGroup"
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">person_add</span>}>
            Add Purchasing Group
          </Button>
        </div>
      </div>

      <BaseDataGrid
        loading={isLoading}
        columns={purchasingGroupColumns}
        rows={purchasingGroups}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
