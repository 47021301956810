import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetTagsQuery } from "../../../../../store/apis/TagsApi";
import { FormControlLabel, IconButton, Box } from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";

export default function Tags() {
  const {
    data: tags,
    isError: hasError,
    isLoading: loading,
  } = useGetTagsQuery(1);

  const isError = hasError;
  const isLoading = loading;

  const TagEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addTag?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Tag",
              data: tags.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const TagDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteTag"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  const TagColumns = [
    {
      field: "typeId",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        let type = "Unknown";
        switch (params.value) {
          case 1:
            type = "Recipe";
            break;
          default:
            break;
        }
        return type;
      },
    },
    {
      field: "name",
      headerName: "Tag Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <TagEdit index={params.row.id} />
            <TagDelete id={params.row.id} name={params.row["name"] || ""} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box marginY={"10px"}>
        <BaseButton
          marginLeft={"0px !important"}
          text={"Add Tag"}
          To={"addTag"}
          endIcon={"post_add"}
        />
      </Box>

      <BaseDataGrid
        rows={tags}
        loading={isLoading}
        columns={TagColumns}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
