import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./AdminTabs.css";

export default function AdminTabs({ Data, query, backQuery }) {
  const navigate = useNavigate();

  const currentLocation = useLocation();
  const [value, setValue] = useState(Data[0].Link);

  useEffect(() => {
    if (
      currentLocation.pathname === "/admin/usermenu" ||
      currentLocation.pathname === "/admin/vendors" ||
      currentLocation.pathname === "/admin/dietitian" ||
      currentLocation.pathname === "/admin/communities" ||
      currentLocation.pathname === "/admin/recipes" ||
      currentLocation.pathname === "/admin/mealcard"
    ) {
      navigate(Data[0].Link);
    }

    let currentLink;
    if (currentLocation) {
      currentLink = Data.find((x) =>
        currentLocation?.pathname.includes(x.Link)
      );
      if (!currentLink) {
        currentLink = Data[0];
      }
    }
    setValue(currentLink.Link);
  }, [currentLocation]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          TabIndicatorProps={{ sx: { display: "none" } }}
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
          value={value}
          aria-label="User Menu nav tabs ">
          {Data.map((data, index) => {
            return (
              <LinkTab
                key={index}
                value={data.Link}
                label={data.Name}
                query={query}
                href={data.Link}
                navigate={navigate}
                backquery={backQuery}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
}

function LinkTab({ navigate, ...rest }) {
  return (
    <Tab
      component="a"
      className={rest.label === "Back" ? "backTab" : "adminTabs"}
      onClick={(event) => {
        event.preventDefault();
        rest.label === "Back"
          ? navigate(`${rest.href}${rest?.backquery ? rest?.backquery : ""}`)
          : navigate(`${rest.href}${rest?.query ? rest?.query : ""}`);
      }}
      icon={
        rest.label === "Back" ? (
          <span className="material-icons">{"keyboard_arrow_left"}</span>
        ) : null
      }
      {...rest}
    />
  );
}
