import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../store/slices/authSlice/AuthSlice";
import "./MealCard.css";

import { useGetResidentQuery } from "../../store/apis/ResidentApis";
import BaseDataGrid from "../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../shared/EditButton";
import BaseContent from "../shared/baseContent/BaseContent";
import BaseSearch from "../shared/baseSearch/BaseSearch";

export default function MealCard() {
  const { state } = useLocation();
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);

  const [debouncedValue, setDebouncedValue] = useState(state?.search || "");
  const [filterTray, setFilterTray] = useState(false);
  const [npo, setNPO] = useState(false);

  const [searchValue, setSearchValue] = useState(state?.search || "");

  const {
    data: MealCardResident,
    isFetching: loading,
    isError: hasError,
  } = useGetResidentQuery(
    {
      searchTerm: debouncedValue,
      facilityId: facilityId,
      filterNoTrayCards: filterTray,
      filterNPO: npo,
    },
    { skip: !facilityId, refetchOnMountOrArgChange: true }
  );

  const residents = MealCardResident?.data;

  const residentsColumns = [
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/MealCard/resident/details?id=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>
              {params.row["lastName"]}
            </Link>
          </>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/MealCard/resident/details?id=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>
              {params.row["firstName"]}
            </Link>
          </>
        );
      },
    },
    {
      field: "diningArea",
      headerName: "Dining Area",
      flex: 2,
    },
    {
      field: "building",
      headerName: "Building",
      flex: 1,
    },
    {
      field: "wing",
      headerName: "Wing",
      flex: 1,
    },
    {
      field: "roomNumber",
      headerName: "Room",
      flex: 1,
    },
    {
      field: "bed",
      headerName: "Bed",
      flex: 1,
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`/MealCard/resident/details?id=${params.row.id}`}
              data={residents}
              title="mealCard"
              icon="visibility"
              state={{
                search: searchValue,
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <BaseContent
      disableBack={true}
      headerText={`Resident List for ${facilityName ? facilityName : ""}`}
      disableHeight={true}>
      <Box height={{ md: "84.4dvh", xl: "86.3dvh" }}>
        <div className="btn-position">
          <Box>
            {MealCardResident?.canEdit ? (
              <Button
                component={Link}
                to="addResidentShortForm"
                color={"primary"}
                className="base-btn"
                variant="contained"
                endIcon={<span className="material-icons">group_add</span>}>
                Add New Resident
              </Button>
            ) : null}
          </Box>
          <div>
            <Button
              component={Link}
              color={"error"}
              to={`printReport?id=${facilityId}`}
              className="baseButton"
              variant="contained"
              endIcon={<span className="material-icons">print</span>}>
              Print Report
            </Button>
            <Button
              component={Link}
              className="baseButton"
              to="/MealCard/settings/changehistory"
              color={"warning"}
              variant="contained"
              endIcon={<span className="material-icons">settings</span>}>
              MealCard Settings
            </Button>
          </div>
        </div>

        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
        />

        <Box display={"flex"} flexDirection={"row"} marginBottom={"8px"}>
          <Button
            color={"primary"}
            sx={{ marginLeft: "0 !important" }}
            className={`baseButton ${
              (filterTray == true || npo == true) && "baseButton-filter"
            }`}
            variant="contained"
            onClick={() => {
              setFilterTray(false);
              setNPO(false);
            }}>
            Census : {MealCardResident?.totalCensus}
          </Button>
          <Button
            color={"primary"}
            className={`baseButton ${
              filterTray == false && "baseButton-filter"
            }`}
            variant="contained"
            onClick={() => {
              setFilterTray(true);
              setNPO(false);
            }}>
            No Tray Tickets : {MealCardResident?.totalNoTrayTickets}
          </Button>
          <Button
            color={"primary"}
            className={`baseButton ${npo == false && "baseButton-filter"}`}
            variant="contained"
            onClick={() => {
              setNPO(true);
              setFilterTray(false);
            }}>
            NPO : {MealCardResident?.totalNPO}
          </Button>
        </Box>
        <BaseDataGrid
          rows={residents}
          loading={loading}
          columns={residentsColumns}
          error={hasError}
          getRowHeight={() => "auto"}
          height={{ md: "60dvh", xl: "67dvh" }}
          width={"100%"}
          EmptyRowMessage={"No resident found"}
          autoHeight={false}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
        />
      </Box>
    </BaseContent>
  );
}
