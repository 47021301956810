import { Grid } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetFacilityCareTypeQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "components/shared/EditButton";

export default function CommunityCareType() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const {
    data: careTypes,
    isError: hasError,
    isLoading: loading,
  } = useGetFacilityCareTypeQuery(id, { skip: !id });

  const careTypeColumns = [
    {
      field: "careTypeName",
      headerName: "Care Type",
      flex: 1,
    },
  ];

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        paddingBottom={2}>
        <EditButton To={`edit?id=${id}`} data={careTypes} />
      </Grid>

      <BaseDataGrid
        rows={careTypes}
        columns={careTypeColumns}
        loading={loading}
        hasError={hasError}
        autoHeight={true}
      />
    </>
  );
}
