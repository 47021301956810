import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetRecipeMenuQuery } from "../../../../../store/apis/PlateFulRecipesApis";
import { Box, Card, FormControlLabel, IconButton } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";

export default function MenuUsage() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");

  const {
    data: menuUsage,
    isError: hasError,
    isLoading: loading,
  } = useGetRecipeMenuQuery({ recipeId: recipeId });

  const isError = hasError;
  const isLoading = loading;

  const menuUsageColumns = [
    {
      field: "menuName",
      headerName: "Menu",
      flex: 1,
    },
    {
      field: "day",
      headerName: "Day",
      flex: 1,
    },
    {
      field: "mealName",
      headerName: "meal",
      flex: 1,
    },
    {
      field: "seasonName",
      headerName: "season",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <DeleteDiet
              id={params.row.menuRecipeId}
              name={params.row["menuName"] || ""}
            />
          </div>
        );
      },
    },
  ];

  const DeleteDiet = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteMenuRecipes"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <Box padding={2}>
          <BaseDataGrid
            autoHeight={false}
            height={{ md: "62vh", lg: "65vh", xl: "68vh" }}
            rows={menuUsage}
            columns={menuUsageColumns}
            getRowId={(row) => row.menuName + row.menuRecipeId}
            loading={isLoading}
            error={isError}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        </Box>
      </Card>
    </>
  );
}
