import { Link, useLocation } from "react-router-dom";
import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./SidebarLink.module.css";
import classNames from "classnames";

export function SidebarLink({
  Name,
  To,
  Icon,
  SetActive,
  open,
  SetOpen,
  IsActive = false,
}) {
  const offsetPointer = useRef(null);
  const currentLocation = useLocation();

  const setActive = useCallback(() => {
    const boundingRect = offsetPointer?.current?.getBoundingClientRect();

    if (IsActive) SetActive(boundingRect?.top, boundingRect?.height, Name);
  }, [currentLocation, To, SetActive]);

  useEffect(() => {
    setActive();
  }, [setActive]);

  return (
    <ListItemButton
      title={Name}
      component={Link}
      to={To}
      ref={offsetPointer}
      className={classNames(styles.baseLink, { [styles.active]: IsActive })}
      onClick={(e) => {
        const rect = e.target?.getBoundingClientRect();
        SetActive(rect?.top, rect?.height, Name);
        if (SetOpen) {
          SetOpen(false);
        }
      }}
      sx={{
        minHeight: 48,
        justifyContent: open ? "initial" : "center",
        px: 2.5,
      }}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 1 : "auto",
          justifyContent: "center",
        }}>
        <span className={`${styles.sidebarIcon} material-icons`}>{Icon}</span>
      </ListItemIcon>
      <ListItemText
        className={classNames(styles.baseLink, { [styles.active]: IsActive })}
        primary={Name}
        disableTypography={true}
        sx={{ opacity: open ? 1 : 0 }}
      />
    </ListItemButton>
  );
}
