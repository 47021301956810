import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { SelectCommunity } from "./SelectCommunity";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import useMsalAccount from "utils/useMsalAccount";
import TempPassword from "../admin/groupMenu/userMenu/userSetup/tempPassword/TempPassword";
import { Roles } from "components/shared/constants";

export const SignOutButton = ({ handleClose }) => {
  const { instance } = useMsal();
  const { account, roles, userId, userName, fullName } = useMsalAccount();

  const [openModalPass, setOpenModalPass] = useState(false);

  const handleLogout = async () => {
    await instance.logoutRedirect({ account: account });
  };

  const myCredentials = () => {
    const userAllowedWithRoles = [Roles.Menu_Team, 
                                  Roles.Dietitian_1099, 
                                  Roles.Dietitian_Global_Admin, 
                                  Roles.Dietitian_Hourly, 
                                  Roles.Dietitian_Salary,
                                  Roles.Dietitian_Regional_Admin,
                                  Roles.Menu_Signing];
    const isInRole = roles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );
    return isInRole;
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "300px",
        flexDirection: "column",
        alignItems: "flex-start",
      }}>
      {myCredentials() ? (
        <Button
          color="inherit"
          component={Link}
          to={`mycredentials`}
          onClick={handleClose}>
          My Credentials
        </Button>
      ) : null}
      {/* <Button
        color="inherit"
        component={Link}
        to={`mycredentials`}
        onClick={handleClose}>
        My Credentials
      </Button> */}
      <Button
        color="inherit"
        onClick={() => {
          setOpenModalPass(true);
        }}>
        Change Password
      </Button>

      <Button onClick={() => handleLogout()} color="inherit">
        Logout
      </Button>
      <SelectCommunity handleClose={handleClose} />

      {openModalPass === true ? (
        <TempPassword
          id={userId}
          fullname={fullName}
          username={userName}
          open={openModalPass}
          close={() => {
            setOpenModalPass(false);
            handleClose();
          }}
        />
      ) : null}
    </Box>
  );
};
