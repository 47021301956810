import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";

import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { useGetPagedVendorListQuery } from "../../../../../store/apis/VendorApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../shared/EditButton";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";

export default function VendorSetup() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: vendors,
    isFetching: loading,
    isError: hasError,
  } = useGetPagedVendorListQuery({
    query: debouncedSearchValue || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const vendorColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "numberOfFacilities",
      headerName: "# of Facilities",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        if (params.row["numberOfFacilities"] === 0) {
          return params.row["numberOfFacilities"];
        } else
          return (
            <Link
              to={`/admin/communities/communitySetup?vendorId=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params.row["numberOfFacilities"]}
            </Link>
          );
      },
    },
    {
      field: "pricingUpdatedOn",
      headerName: "Last Pricing Upload",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        if (null === params.value) {
          return "";
        } else {
          return new Date(params.value).toLocaleDateString();
        }
      },
    },
    {
      field: "pricingUpdatedBy",
      headerName: "Uploaded By",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addVendor?id=${params.row.id}`}
              title="Vendors"
            />
            <VendorOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row["name"] || ""}
              // debounceHandler={debounceHandler}
              searchValue={searchValue}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedSearchValue}
            setSearchParams={setSearchParams}
            setPaginationModel={setPaginationModel}
          />
          <BaseButton
            To="addVendor"
            endIcon={"person_add"}
            text={"Add Vendor"}
          />
          <BaseButton
            To="importVendorPrice"
            style={{ marginLeft: "20px" }}
            variant="contained"
            endIcon={"upload_file"}
            text={"Import Vendor Price Spreadsheet"}
          />
          <BaseButton
            To="recalcVendorPrice"
            style={{ marginLeft: "20px" }}
            endIcon={"currency_exchange"}
            text={"Recalculate Pricing"}
          />
        </div>
      </div>

      <BaseDataGrid
        loading={loading}
        columns={vendorColumns}
        rowCount={vendors?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rows={vendors?.items || 0}
        error={hasError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}

const VendorOptions = ({ index, id, name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        id={"menu-button-" + index}
        aria-controls={open ? "menu-" + index : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className="menuButton"
        to={"/"}
        onClick={handleClick}>
        <span className="material-icons">more_vert</span>
      </Link>
      <Menu
        id={"edit-menu" + index}
        MenuListProps={{
          "aria-labelledby": "menu-button-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem disableRipple>
          <Link
            to={`./VendorProducts?id=${id}`}
            state={{ vendorName: name }}
            style={{ textDecoration: "none", color: "black" }}>
            Products
          </Link>{" "}
        </MenuItem>
        <MenuItem disableRipple>
          <Link
            to={`./BulkProductEntry?id=${id}`}
            state={{ vendorName: name }}
            style={{ textDecoration: "none", color: "black" }}>
            Bulk Product Entry
          </Link>{" "}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            handleClose();
          }}
          className="menuDeleteButton"
          disableRipple>
          Delete
        </MenuItem>
      </Menu>
      {openModal === true ? (
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteVendor"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
