import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";

import { useGetDocumentListQuery } from "../../../../../store/apis/DocumentApi";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";

function ManageDocuments() {
  const {
    data: documentList,
    isError: hasError,
    isLoading: loading,
  } = useGetDocumentListQuery();

  const isError = hasError;
  const isLoading = loading;

  const DocumentDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteDocument"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  const DocumentEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`/admin/userMenu/managedocuments/AddManageDocument?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Document",
              data: documentList.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const DocumentColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 400,
    },
    {
      field: "documentTypeId",
      headerName: "Document Type",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return params.row?.documentTypeId === 1
          ? "Program Training"
          : params.row?.documentTypeId === 2
          ? "Menu Guide Book"
          : params.row?.documentTypeId === 3
          ? "Forms & Information Sheets"
          : "";
      },
    },
    {
      field: "groupName",
      headerName: "Document Group",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <DocumentEdit index={params.row.id} />
            <DocumentDelete
              id={params.row.id}
              name={params.row["name"] || ""}
            />
            <DocumentOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row.name}
              downloadURL={params.row.url}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box paddingY={"10px"}>
        <BaseButton
          sx={{ marginLeft: "0px !important" }}
          text={"Create Document"}
          endIcon={"post_add"}
          To={`AddManageDocument`}
        />
      </Box>

      <BaseDataGrid
        rows={documentList}
        columns={DocumentColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "57dvh" }}
      />
    </>
  );
}

const DocumentOptions = ({ index, id, name, downloadURL }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        id={"menu-button-" + index}
        aria-controls={open ? "menu-" + index : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className="menuButton"
        to={"/"}
        onClick={handleClick}>
        <span className="material-icons">more_vert</span>
      </Link>
      <Menu
        id={"edit-menu" + index}
        MenuListProps={{
          "aria-labelledby": "menu-button-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem disableRipple>
          <Link
            to={downloadURL}
            download={id + name}
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}>
            Download
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ManageDocuments;
