import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetThickenedLiquidQuery } from "../../../../../store/apis/LiquidConsistencyApis";
import { FormControlLabel, IconButton, Button } from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";

export default function LiquidConsistency() {
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: liquid,
    isError: hasError,
    isLoading: loading,
  } = useGetThickenedLiquidQuery(debouncedValue);

  const isError = hasError;
  const isLoading = loading;

  const EditLiquid = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addliquid?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit liquid",
              data: liquid.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const DeleteLiquid = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteThickenedLiquid"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  const LiquidColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditLiquid index={params.row.id} />
            <DeleteLiquid id={params.row.id} name={params.row["name"] || ""} />
            {/* <Link>
              <span className="material-icons">more_vert</span>
            </Link> */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
          />
          <Button
            component={Link}
            to={`addliquid`}
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">post_add</span>}>
            Add New
          </Button>
        </div>
      </div>

      <BaseDataGrid
        rows={liquid}
        columns={LiquidColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
