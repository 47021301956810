import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { MealCardRoute } from "./routesChild/MealCardRoute";
import { ConsultantRoute } from "./routesChild/ConsultantRoute";
import { TableSideRoutes } from "./routesChild/TableSideRoute";
import { chatAIConvo } from "./routesChild/ChatAIRoute";
import { ClientPortalRoutes } from "./routesChild/ClientPortalRoute";
import { PlateFulRoutes } from "./routesChild/PlateFulRoute";
import { AdminRoute } from "./routesChild/AdminRoute";

// Home Module Imports
import Home from "../components/home/Home";
// About Module Imports
import About from "../components/about/About";
// Faq Modules Imports
import Faq from "../components/faq/Faq";
// Contact Us Imports
import ContactUs from "../components/contactUs/ContactUs";

import FrontPageCorpDocument from "../components/frontPageDocument/FrontPageCorpDocument";
import FrontPageDocument from "../components/frontPageDocument/FrontPageDocument";

// Custom Imports
import MyCredentials from "../components/header/MyCredentials";
import Base from "../components/base/Base";

// Error / Notfound / forbidden Imports
import ErrorPage from "../components/shared/errorPage/ErrorPage";
import NotFound from "../components/shared/notFound/NotFound";
import Forbidden from "../components/shared/forbidden/Forbidden";

const routesArray = [
  // Define the routes array as provided earlier
  {
    path: "/",
    element: <Base />,
    errorElement: <ErrorPage />,
    children: [
      { path: "forbidden", element: <Forbidden /> },
      { path: "*", element: <NotFound /> },
      { path: "", element: <Home /> },
      { path: "home", element: <Home /> },
      { path: "about", element: <About /> },
      { path: "mycredentials", element: <MyCredentials /> },
      { path: "faq", element: <Faq /> },
      { path: "contactus", element: <ContactUs /> },
      { path: "home/frontpagedocument", element: <FrontPageDocument /> },
      { path: "home/frontPageCorpDocument", element: <FrontPageCorpDocument /> },
      // ClientPortal Routes
      ClientPortalRoutes,
      //admin routing with children
      AdminRoute,
      //plateful recipe  start
      PlateFulRoutes.PlateFulMain,
      PlateFulRoutes.PlateFulShare,
      PlateFulRoutes.PlateFulShareMenu,
      PlateFulRoutes.PlateFulRecipesAdd,
      PlateFulRoutes.PlateFulRecipesList,
      // /* plateful start menus routes  *
      PlateFulRoutes.PlateFulMenus,
      PlateFulRoutes.PlateFulAddMenus,
      PlateFulRoutes.PlateFulMenuCopy,
      PlateFulRoutes.PlateFulMenuCorporate,
      PlateFulRoutes.PlateFulMenusDays,
      PlateFulRoutes.PlateFulMenusChangeHistory,
      PlateFulRoutes.PlateFulMenuMissingVendor,
      PlateFulRoutes.PlateFulMenusPrint,
      PlateFulRoutes.PlateFulMenuRecipeReprint,
      PlateFulRoutes.PlateFulMenuOrderTransmission,
      PlateFulRoutes.PlateFulMenuIrderTransmissionNewOrder,
      PlateFulRoutes.PlateFulMenuPlanner,
      PlateFulRoutes.PlateFulMenuMissingVendorRoute,
      PlateFulRoutes.PlateFulMenuBulkEntry,
      PlateFulRoutes.PlateFulMenuCustomizePostedMenu,

      // /* plateful menus routes ended  *
      //Consultant route
      ConsultantRoute.consultantRoutes, // Adding the consultant routes object here
      ConsultantRoute.consultantMyRostersRoutes, // Adding the consultant MyRosters routes object here
      ConsultantRoute.consultantDetailsRoutes, // Adding the consultant details routes object here
      ConsultantRoute.consultantCommunityRoutes, // Adding the consultant community routes object here
      ConsultantRoute.consultantEnterpriseRoutes, // Adding the consultant enterprise routes object here
      ConsultantRoute.consultantNonplannedvisitRoutes,

      //Tableside route
      TableSideRoutes.TableSideMainRoute,
      TableSideRoutes.KitchenSideSetup,
      TableSideRoutes.TicketPrintingRoute,
      TableSideRoutes.ReportRoute,
      TableSideRoutes.orderTracking,

      // MealCard Routes
      MealCardRoute.mealCard,
      MealCardRoute.mealCardSettings,
      MealCardRoute.mealCardAddResident,
      MealCardRoute.mealCardAddResidentShortForm,
      MealCardRoute.mealCardResidentChangeHistory,
      MealCardRoute.mealCardResident,
      MealCardRoute.mealCardPrintReport,

      // chatAIConvo Routes
      chatAIConvo,
    ],
  },
];

const convertRoutes = (routes) => {
  return routes.map(({ path, element, errorElement, children }) => (
    <Route path={path} key={path} element={element} errorElement={errorElement}>
      {children && convertRoutes(children)}
    </Route>
  ));
};

const routeElements = convertRoutes(routesArray);

export const router = createBrowserRouter(
  createRoutesFromElements(routeElements)
);
