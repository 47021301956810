import React, { useState } from "react";
import { useGetCorporateMealExtraQuery } from "../../../../../../store/apis/CorporateGroupMealExtraApis";
import { Button } from "@mui/material";

import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import { WarningText } from "../../../../../shared/WarningText";

export default function MealExtrasSelection() {
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const currentId = searchParams.get("id");

  const {
    data: corporateGroupsMealExtras,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
  } = useGetCorporateMealExtraQuery(
    {
      corporateGroupId: currentId,
      searchText: debouncedSearchValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const corporateGroupColumns = [
    {
      field: "mealExtrasName",
      headerName: "MealExtra",
      flex: 4,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`AddMealExtrasSelection?corporateId=${currentId}&&id=${params.row.id}`}
              data={corporateGroupsMealExtras}
              title="View Corporate Group"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.mealExtrasName}
              apiPath="deleteCorporateMealExtra"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <WarningText
      margin={"5px"}
      text={`**Add a Meal Add-On (food, beverage or supplement) to a resident’s Mealcard to capture preferences, standing orders, nutrition interventions, etc. 
      Don't see something on our list? You can add custom House Meal Add-Ons within your community's MealCard Settings.`}
      width="80%"
      />
      <div className="actionsRow">
        <div className="leftActions">
          <Button
            component={Link}
            to={"/admin/communities/corporateGroups"}
            color={"primary"}
            className="baseButton"
            variant="contained"
            startIcon={
              <span className="material-icons">keyboard_arrow_left</span>
            }>
            {"Back to Corporate Group  List"}
          </Button>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedSearchValue}
            setSearchParams={setSearchParams}
            isId={true}
            Id={currentId}
          />
          <Button
            component={Link}
            to={`AddMealExtrasSelection?corporateId=${currentId}`}
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">fastfood</span>}>
            Add Meal Add-On
          </Button>
        </div>
      </div>
      <BaseDataGrid
        rows={corporateGroupsMealExtras}
        columns={corporateGroupColumns}
        error={corporateGroupsError}
        loading={corporateGroupsLoading}
        autoHeight={false}
        height={{ md: "50vh", xl: "57vh" }}
      />
    </>
  );
}
