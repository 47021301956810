import { Button } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/BaseButton.md)
 */
export const BackButton = ({
  To,
  text,
  onClick,
  disabled,
  sx,
  iconDisabled,
  rest,
}) => {
  const navigate = useNavigate();
  const backNavigation = () => navigate(-1);
  return (
    <Button
      {...rest}
      component={Link}
      to={To}
      color={"primary"}
      disabled={disabled || false}
      className="baseButton"
      sx={{
        marginLeft: "0px !important",
        ...sx,
      }}
      variant="contained"
      startIcon={
        iconDisabled ? null : (
          <span className="material-icons">keyboard_arrow_left</span>
        )
      }
      onClick={onClick ? onClick : !To ? backNavigation : null}>
      {text || "Back"}
    </Button>
  );
};
