import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DMStyles } from "../../../../../../styles/Styles";

import { KeysButton, typeAbbreviations } from "../../constants";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import {
  useGetVisitsByCorporateGroupQuery,
  useGenerateVisitReportsMutation,
  useGenerateVisitReportMutation,
} from "../../../../../../store/apis/ConsultantApis";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import { Link, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { SCREEN_CONSTANTS } from "components/shared/constants";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { theme } from "styles/theme";
import { size } from "lodash";

export const EnterpriseProfile = () => {
  const [loadingId, setLoadingId] = useState(null);

  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const corporateId = searchParams.get("corporateId");
  const facilityCorporateGroup = searchParams.get("facilityCorporateGroup");

  const {
    data: currentFacility,
    isError: hasError,
    isLoading: loading,
  } = useGetVisitsByCorporateGroupQuery(corporateId);

  const [
    GenerateReports,
    {
      data: report,
      isError: reportError,
      isSuccess: isSuccess,
      isLoading: reportLoading,
    },
  ] = useGenerateVisitReportsMutation();

  const [
    GenerateReport,
    {
      data: visitreport,
      isError: visitreportError,
      isSuccess: isvisitSuccess,
      isLoading: visitreportLoading,
    },
  ] = useGenerateVisitReportMutation();

  const EnterpriseData = currentFacility ? currentFacility : [];
  const isError = hasError || reportError || visitreportError;
  const isLoading = loading;

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
    if (isvisitSuccess) {
      DownloadLinkHelper(visitreport?.data);
    }
  }, [report, visitreport]);

  const EnterpriseColumns = [
    {
      field: "facilityName",
      flex: 1,
      headerName: "Community/Date",

      renderCell: (params) => {
        const isDateValid = dayjs(params?.row?.dateOfVisit).isValid();

        return (
          <Box display={"flex"} flexDirection={"column"}>
            <Link
              to={`/Consultant/Community/Profile?facilityId=${facilityId}&facilityName=${params.row.facilityName}`}
              style={{ color: "rgba(42, 83, 126, 1)", marginBottom: "5px" }}>
              {params.row["facilityName"]}
            </Link>
            {isDateValid
              ? dayjs(params?.row?.dateOfVisit).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : "--"}
          </Box>
        );
      },
    },
    // {
    //   field: "dateOfVisit",
    //   flex: 1,
    //   headerName: "Dates",
    //   valueGetter: (params) => {
    //     return params.value ? dayjs(params.value).toDate() : "";
    //   },
    //   valueFormatter: (params) => {
    //     const isDateValid = dayjs(params?.value).isValid();
    //     return isDateValid
    //       ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
    //       : "--";
    //   },
    // },

    {
      field: "dietitianName",
      flex: 1,
      headerName: "Consultant",
    },
    {
      field: "visitTypes",
      flex: 2,
      headerName: "Reports",
      renderCell: (params) => {
        return size(params?.row?.visitTypes) < 1 ? (
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Typography textAlign={"center"}>No Reports Available</Typography>
          </Box>
        ) : (
          <Grid container spacing={0.3}>
            {params?.row?.visitTypes.map((item) => {
              const bgColor = KeysButton.find(
                (color) => color.name === item.name
              );
              return (
                <Grid
                  key={item?.id}
                  item
                  sx={{ cursor: "pointer", textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "2px 10px",
                      borderRadius: "5px",
                      marginBottom: "5px",
                      backgroundColor: bgColor?.backgroundColor,
                      color: DMStyles.White,
                      marginRight: "2px",
                    }}
                    onClick={() => {
                      setLoadingId(`report-${item?.id}`);
                      GenerateReport({ id: item?.id });
                    }}>
                    {visitreportLoading &&
                    loadingId === `report-${item?.id}` ? (
                      <CircularProgress
                        color="white"
                        sx={{ marginRight: "5px" }}
                        size={15}
                      />
                    ) : null}

                    <Typography sx={{ fontSize: "12px" }}>
                      {typeAbbreviations[item.typeId] || ""}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        const isDisabled = params?.row?.visitTypes?.length < 1;
        return (
          <>
            {!isDisabled ? (
              <Tooltip title={isDisabled ? "No Reports Available" : ""}>
                <Box
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Box width="5%">
                    <BaseSubmitButton
                      disabled={params?.row?.visitTypes?.length < 1}
                      text=""
                      isSubmitting={
                        reportLoading && loadingId == `reports-${params.row.id}`
                          ? true
                          : false
                      }
                      iconButton={true}
                      sx={{
                        borderRadius: DMStyles.BorderRadius,
                      }}
                      style={{
                        backgroundColor:
                          size(params?.row?.visitTypes) > 0
                            ? DMStyles.Teal
                            : DMStyles.Gray,
                        padding: "5px 10px",
                      }}
                      startIcon={
                        <span
                          className="material-icons"
                          style={
                            size(params?.row?.visitTypes) > 0
                              ? {
                                  marginRight: 0,
                                  color: "white",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                }
                              : {
                                  marginRight: 0,
                                  color: DMStyles.Gray,
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                }
                          }>
                          print
                        </span>
                      }
                      onClick={() => {
                        setLoadingId(`reports-${params.row.id}`);
                        GenerateReports({ id: params.row.id });
                      }}
                    />
                  </Box>
                </Box>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <Box padding="10px" id="enterprise-profile">
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        p={"16px"}
        color={DMStyles.White}
        bgcolor={theme.palette.primary.main}>
        <Typography variant="h6" fontWeight={500}>
          Corporate Profile
        </Typography>

        {!facilityCorporateGroup ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography fontWeight={700} variant="h5">
            {facilityCorporateGroup}
          </Typography>
        )}
      </Box>

      <Box marginY={"10px"}>
        <Typography
          id={`copy-to-corporate-group-modal-title`}
          x
          variant="h6"
          component="h2"
          className="modalTitle">
          Past Visits
        </Typography>
      </Box>

      <Box height={"60vh"}>
        <BaseDataGrid
          rows={EnterpriseData || []}
          columns={EnterpriseColumns}
          loading={isLoading}
          error={isError}
          getRowId={(row) => row.id}
          height={"100%"}
          getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
        />
      </Box>
    </Box>
  );
};
