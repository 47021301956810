import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/BaseButton.md)
 */
export const BaseButton = ({
  colors,
  variant,
  To,
  state,
  endIcon,
  startIcon,
  text,
  onClick,
  isSubmitting,
  target,
  fontColor,
  border,
  disabled,
  zIndex,
  marginLeft,
  sx,
  loadingColor,

  rest,
}) => {
  return (
    <Button
      {...rest}
      state={state}
      component={Link}
      to={To}
      target={target ?? ""}
      rel={target ? "noopener noreferrer" : ""}
      color={colors || "primary"}
      disabled={disabled || false}
      className="baseButton"
      sx={{
        zIndex: zIndex || "",
        color: fontColor,
        border,
        cursor: "pointer",
        marginLeft,
        // Fix for Link Components in BaseButton having incorrect hover colors
        ...sx,
      }}
      variant={variant || "contained"}
      startIcon={
        startIcon ? <span className="material-icons">{startIcon}</span> : null
      }
      endIcon={
        isSubmitting ? (
          <CircularProgress
            color={loadingColor || (variant ? "warning" : "white")}
            size={18}
          />
        ) : endIcon ? (
          <span className="material-icons">{endIcon}</span>
        ) : null
      }
      onClick={onClick}>
      {text}
    </Button>
  );
};
