import React, { useState } from "react";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import { useGetRecipeDietListQuery } from "../../../../../store/apis/PlateFulRecipeDietApis";
import { FormControlLabel, IconButton, Card, Grid } from "@mui/material";

import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { WarningText } from "../../../../shared/WarningText";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";

import "../../PlateFul.css";
import { BaseButton } from "components/shared/BaseButton";

export default function DietRecipe() {
  const [searchParams] = useSearchParams();
  const currentLocation = useLocation();
  const canEdit = currentLocation.state?.canEdit;
  const recipeId = searchParams.get("recipeId");
  const facilityId = useSelector(selectFacilityId);

  const {
    data: diet,
    isError: hasError,
    isLoading: loading,
  } = useGetRecipeDietListQuery({ recipeId: recipeId, facilityId: facilityId });

  const isError = hasError;
  const isLoading = loading;

  const DeleteDiet = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteRecipeDiet"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      </>
    );
  };

  const EditDiet = ({ RecipeData }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addRDiet?recipeId=${recipeId}`}
            className="editIcon"
            state={{
              title: "Edit Recipe Diet",
              data: RecipeData,
            }}>
            <span className="material-icons">edit</span>
          </Link>
        }
      />
    );
  };

  const dietColumns = [
    {
      field: "dietName",
      headerName: "Diet",
      flex: 1,
      renderCell: (params) => {
        return params?.row?.facilityDietName !== null
          ? params?.row?.facilityDietName
          : params?.row?.dietName;
      },
    },
    {
      field: "alternateRecipeName",
      headerName: "Alternate Recipe",
      flex: 1,
      renderCell: (params) => {
        return params?.row?.alternateRecipeId !== null ? (
          <>
            <Link
              target={"_blank"}
              to={`/plateFul/Recipes/RecipeDetail?recipeId=${params?.row?.alternateRecipeId}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params?.row?.alternateRecipeName}
            </Link>
            &nbsp;-&nbsp;{params?.row?.alternateRecipeDescription}
          </>
        ) : (
          "None"
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return canEdit === true ? (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditDiet RecipeData={params.row} />
            <DeleteDiet
              id={params.row.id}
              name={params.row["dietName"] || ""}
            />
          </div>
        ) : null;
      },
    },
  ];

  return (
    <>
      <Card>
        <Grid container spacing={1} padding={2}>
          {canEdit === true ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <WarningText
                  text={`You only need to add an entry here if a particular diet requires a
                          different recipe. An entry next to a diet that says "None"
                          means that this recipe won't be available for that diet, and no
                          alternate will be provided.`}
                  width="100%"
                  sx={{
                    margin: "0px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <BaseButton
                  text="Add New"
                  To={`addRDiet?recipeId=${recipeId}`}
                  endIcon={<span className="material-icons">post_add</span>}
                  marginLeft="0px"
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <BaseDataGrid
              autoHeight={false}
              rows={diet}
              columns={dietColumns}
              loading={isLoading}
              error={isError}
              rowHeight={55}
              height={{ md: "48vh", xl: "52vh" }}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
