import React from "react";
import {
  useResidentFoodTypesQuery,
  useGetResidentAllergyQuery,
} from "../../../../store/apis/ResidentApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { EditButton } from "../../../shared/EditButton";
import DeleteButton from "../../../shared/DeleteButton";
import { BaseButton } from "components/shared/BaseButton";

export default function ResidentDislikes() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentDislikes,
    isLoading,
    isError,
  } = useResidentFoodTypesQuery(
    { id: residentId, like: false },
    { skip: !residentId }
  );

  const {
    data: Allergies,
    isLoading: isAllergyLoading,
    isAllergyError,
  } = useGetResidentAllergyQuery(residentId, { skip: !residentId });

  const residentAllergies = Allergies;

  const dislikeColumns = [
    {
      field: "foodTypeName",
      minWidth: 50,
      flex: 2,
      headerName: "Dislike / Intolerance",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "description",
      minWidth: 50,
      flex: 2,
      headerName: "Description",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addresidentdislike?dislikeId=${params.row.id}&id=${residentId}`}
              data={residentDislikes}
              title="add Resident Dislike"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.foodTypeName}
              apiPath="deleteResidentFoodType"
            />
          </div>
        );
      },
    },
  ];

  const allergyColumns = [
    {
      field: "allergyFoodName",
      minWidth: 50,
      flex: 2,
      headerName: "Food Allergy",
    },
    {
      field: "description",
      minWidth: 50,
      flex: 2,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              // To={`addresidentAllergy?id=${residentId}`}
              To={`/mealcard/resident/residentAllergy/addresidentAllergy?id=${residentId}`}
              data={Allergies}
              title="addresidentAllergy"
            />

            <DeleteButton
              index={params.row.id}
              entityName={params.row?.allergyFoodName}
              apiPath="deleteResidentAllergy"
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Grid container spacing={1} marginTop={0.5}>
        <Grid item sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1}>
            <Grid item sm={6} md={5} lg={4} xl={4}>
              <BaseButton
                text="Add Allergy"
                To={`/MealCard/resident/residentAllergy/addresidentAllergy?id=${residentId}`}
                state={{
                  name:
                    Allergies?.resident?.firstName +
                    " " +
                    Allergies?.resident?.lastName,
                }}
                endIcon={<span className="material-icons">post_add</span>}
                marginLeft="0px !important"
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <BaseDataGrid
                rows={residentAllergies}
                columns={allergyColumns}
                error={isAllergyError}
                loading={isAllergyLoading}
                autoHeight={false}
                height={{
                  xs: "50vh",
                  sm: "65vh",
                  md: "55vh",
                  lg: "61vh",
                  xl: "67vh",
                }}
                sx={{
                  marginY: "0px !important",
                  paddingY: "0px !important",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1}>
            <Grid item sm={6} md={5} lg={4} xl={4}>
              <BaseButton
                text="Add Dislike"
                To={`addresidentdislike?id=${residentId}`}
                endIcon={<span className="material-icons">post_add</span>}
                marginLeft="0px !important"
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <BaseDataGrid
                rows={residentDislikes}
                columns={dislikeColumns}
                error={isError}
                loading={isLoading}
                autoHeight={false}
                height={{
                  xs: "50vh",
                  sm: "65vh",
                  md: "55vh",
                  lg: "61vh",
                  xl: "67vh",
                }}
                sx={{
                  marginY: "0px !important",
                  paddingY: "0px !important",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
