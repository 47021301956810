import React, { useState, useEffect } from "react";
import { useGetCorporateDocumentsQuery } from "../../../../../../store/apis/CorporateGroupDocumentApis";
import { Button, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";

export default function CorporateDocument() {
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const currentId = searchParams.get("id");

  const [documentContainer, setDocumentContainer] = useState(null);

  const {
    data: corporateGroups,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
    isSuccess: corporateGroupsSuccess,
  } = useGetCorporateDocumentsQuery(
    { corporateGroupId: currentId, searchText: debouncedSearchValue || "" },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (corporateGroupsSuccess && corporateGroups) {
      setDocumentContainer(corporateGroups?.containerName);
    }
  }, [corporateGroupsSuccess]);

  const corporateGroupColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "groupName",
      minWidth: 100,
      flex: 1,
      headerName: "Document Group",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "sortOrder",
      minWidth: 100,
      flex: 1,
      headerName: "Sort Order",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "url",
      minWidth: 100,
      flex: 1,
      headerName: "URL",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Link
            target={"_blank"}
            to={params.row?.url}
            style={{ textDecoration: "none", color: "red" }}>
            {"document"}
          </Link>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addDocument?corporateId=${currentId}&&id=${params.row.id}`}
              data={corporateGroups?.documents}
              title="View Corporate Group"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.name}
              apiPath="deleteCorporateDocuments"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <Button
            component={Link}
            to={"/admin/communities/corporateGroups"}
            color={"primary"}
            className="baseButton"
            variant="contained"
            startIcon={
              <span className="material-icons">keyboard_arrow_left</span>
            }>
            {"Back to Corporate Group  List"}
          </Button>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedSearchValue}
            setSearchParams={setSearchParams}
            isId={true}
            Id={currentId}
          />
          {documentContainer ? (
            <Button
              component={Link}
              to={`addDocument?corporateId=${currentId}`}
              color={"primary"}
              className="baseButton"
              variant="contained"
              state={{ container: documentContainer }}
              endIcon={<span className="material-icons">assignment</span>}>
              Add Document
            </Button>
          ) : (
            <Typography color={"error"} variant="caption">
              Please contact the Administrator to setup the document container
              for this corporate group.
            </Typography>
          )}
        </div>
      </div>

      <BaseDataGrid
        rows={corporateGroups?.documents}
        columns={corporateGroupColumns}
        loading={corporateGroupsLoading}
        error={corporateGroupsError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
