import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DMStyles } from "../../../../../../styles/Styles";
// import { KeysButton, typeAbbreviations } from "../../constants";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import {
  useGetVisitsByFacilityQuery,
  useGenerateVisitReportsMutation,
  useGenerateVisitReportMutation,
} from "../../../../../../store/apis/ConsultantApis";
import { useSearchParams } from "react-router-dom";
import { REPORT_TYPES, SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { size } from "lodash";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { theme } from "styles/theme";

export const CommunityProfile = () => {
  const [loadingId, setLoadingId] = useState(null);
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const facilityName = searchParams.get("facilityName");

  const {
    data: currentFacility,
    isError: hasError,
    isLoading: loading,
  } = useGetVisitsByFacilityQuery(facilityId);

  const [
    GenerateReports,
    {
      data: report,
      isError: reportError,
      isSuccess: isSuccess,
      isLoading: reportLoading,
    },
  ] = useGenerateVisitReportsMutation();

  const [
    GenerateReport,
    {
      data: visitreport,
      isError: visitreportError,
      isSuccess: isvisitSuccess,
      isLoading: visitreportLoading,
    },
  ] = useGenerateVisitReportMutation();

  const CommunityData = currentFacility ? currentFacility : [];
  const isError = hasError || reportError || visitreportError;
  const isLoading = loading;

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
    if (isvisitSuccess) {
      DownloadLinkHelper(visitreport?.data);
    }
  }, [report, visitreport]);

  const rostersColumns = [
    {
      field: "dateOfVisit",
      flex: 1,
      headerName: "Dates",
      valueGetter: (params) => {
        return params.value ? dayjs(params.value).toDate() : "";
      },
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "dietitianName",
      flex: 1,
      headerName: "Consultant",
    },
    {
      field: "visitTypes",
      flex: 2,
      headerName: "Reports",
      renderCell: (params) => {
        return size(params?.row?.visitTypes) < 1 ? (
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Typography textAlign={"center"}>No Reports Available</Typography>
          </Box>
        ) : (
          <Grid container spacing={0.3}>
            {params?.row?.visitTypes.map((item) => {
              const type = REPORT_TYPES.find(
                (color) => color.id === item.typeId
              );
              console.log(item, type);
              return (
                <React.Fragment key={item?.id}>
                  <Chip
                    size="small"
                    color={type.color}
                    icon={
                      visitreportLoading &&
                      loadingId === `report-${item?.id}` ? (
                        <CircularProgress
                          color="white"
                          sx={{ marginRight: "5px" }}
                          size={15}
                          style={{ color: "white !important" }}
                        />
                      ) : null
                    }
                    sx={{
                      marginRight: "8px",
                      padding: "8px 16px 8px 16px",
                      borderRadius: "6px",
                      marginY: "4px",
                      fontWeight: "600",
                    }}
                    label={type?.text}
                    onClick={() => {
                      setLoadingId(`report-${item?.id}`);
                      GenerateReport({ id: item?.id });
                    }}></Chip>
                </React.Fragment>
              );
            })}
          </Grid>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        const isDisabled = size(params?.row?.visitTypes) < 1;

        return (
          <>
            {!isDisabled ? (
              <Tooltip title={"Print All"}>
                <Box
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Box width="5%">
                    <BaseSubmitButton
                      text=""
                      isSubmitting={
                        reportLoading && loadingId == `reports-${params.row.id}`
                          ? true
                          : false
                      }
                      iconButton={true}
                      sx={{
                        borderRadius: "5px",
                      }}
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        padding: "5px 10px",
                      }}
                      startIcon={
                        <span
                          className="material-icons"
                          style={{
                            marginRight: 0,
                            color: "white",
                            fontSize: "18px",
                          }}>
                          print
                        </span>
                      }
                      onClick={() => {
                        setLoadingId(`reports-${params.row.id}`);
                        GenerateReports({ id: params.row.id });
                      }}
                    />
                  </Box>
                </Box>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box margin="10px">
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          p={"16px"}
          color={DMStyles.White}
          bgcolor={theme.palette.primary.main}>
          <Typography variant="h6" fontWeight={500}>
            Community Profile
          </Typography>

          {!facilityName ? (
            <Skeleton variant="text" width={200} height={40} />
          ) : (
            <Typography fontWeight={700} variant="h5">
              {facilityName}
            </Typography>
          )}
        </Box>

        <Box marginY={"10px"}>
          <Typography
            id={`copy-to-corporate-group-modal-title`}
            variant="h6"
            component="h2"
            className="modalTitle">
            Past Visits
          </Typography>
        </Box>

        <Box height={"60vh"}>
          <BaseDataGrid
            rows={CommunityData || []}
            columns={rostersColumns}
            loading={isLoading}
            error={isError}
            getRowId={(row) => row.id}
            height={"100%"}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};
