import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import {
  useGetIngredientListQuery,
  useGetPagedIngredientsQuery,
} from "../../../../../store/apis/IngredientAPIs";
import {
  FormControlLabel,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSnackbar } from "notistack";
import { BaseButton } from "components/shared/BaseButton";

export default function Ingredients() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [isRecipe, setIsRecipe] = useState(
    searchParams.get("isrecipe") || false
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: ingredients,
    isError: hasError,
    isLoading: loading,
  } = useGetPagedIngredientsQuery({
    searchTerm: debouncedValue || "",
    isRecipe: isRecipe,
    skip: paginationModel.page * paginationModel.pageSize,
    take: paginationModel.pageSize,
  });

  const ingredientsData = ingredients?.items || [];

  const isError = hasError;
  const isLoading = loading;

  const [fetchReport, setFetchReport] = useState(false);
  const {
    data: reportData,
    isFetching: reportFetching,
    isSuccess: reportSuccess,
  } = useGetIngredientListQuery(undefined, {
    skip: !fetchReport,
  });

  useEffect(() => {
    if (reportSuccess) {
      DownloadLinkHelper(reportData?.data);
      enqueueSnackbar(`${reportData?.messages[0]}`, {
        variant: "success",
      });
    }
  }, [reportData?.data]);

  const IngredientEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addIngredient?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Ingredient",
              data: ingredientsData.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const IngredientOptions = ({ index, id, name }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);

    const handleClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Link
          id={"menu-button-" + index}
          aria-controls={open ? "menu-" + index : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          className="menuButton"
          to={"/"}
          onClick={handleClick}>
          <span className="material-icons">more_vert</span>
        </Link>
        <Menu
          id={"edit-menu" + index}
          MenuListProps={{
            "aria-labelledby": "menu-button-" + index,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <MenuItem disableRipple>
            <Link
              to={`./UnitConversion?id=${id}&name=${name}`}
              style={{ textDecoration: "none", color: "black" }}>
              Unit Conversion
            </Link>{" "}
          </MenuItem>
          <MenuItem disableRipple>
            <Link
              to={`./VendorIngredient?id=${id}&name=${name}`}
              style={{ textDecoration: "none", color: "black" }}>
              Vendors
            </Link>{" "}
          </MenuItem>
          <MenuItem disableRipple>
            <Link
              to={`./IngredientAllergy?id=${id}&name=${name}`}
              style={{ textDecoration: "none", color: "black" }}>
              Allergies
            </Link>{" "}
          </MenuItem>
          <MenuItem disableRipple>
            <Link
              to={`./IngredientUsage?id=${id}&name=${name}`}
              style={{ textDecoration: "none", color: "black" }}>
              Recipe Usage
            </Link>{" "}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
              handleClose();
            }}
            className="menuDeleteButton"
            disableRipple>
            Delete
          </MenuItem>
        </Menu>
        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deleteIngredient"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const IngredientColumns = [
    {
      field: "name",
      headerName: "Ingredient Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <IngredientEdit index={params.row.id} />
            <IngredientOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row["name"] || ""}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            setPaginationModel={setPaginationModel}
          />
          <BaseButton
            text="Add Ingredient"
            To="addIngredient"
            endIcon={<span className="material-icons">add_shopping_cart</span>}
          />
          <BaseButton
            text="Show Pureed Recipe Ingredients"
            startIcon={
              isRecipe ? (
                <span className="material-icons">check</span>
              ) : (
                <span className="material-icons">close</span>
              )
            }
            onClick={() => {
              setIsRecipe(!isRecipe);
              setSearchParams({
                search: debouncedValue,
                isrecipe: !isRecipe,
              });
            }}
          />
        </div>
        <Button
          id="report-button"
          className="baseButton"
          color={"error"}
          disableElevation
          onClick={() => {
            if (reportSuccess) {
              DownloadLinkHelper(reportData?.data);
              enqueueSnackbar(`${reportData?.messages[0]}`, {
                variant: "success",
              });
            }
            setFetchReport(true);
          }}
          variant={"contained"}
          startIcon={
            reportFetching && <CircularProgress color="warning" size={"1rem"} />
          }>
          Report
        </Button>
      </div>

      <BaseDataGrid
        rows={ingredientsData}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        rowCount={ingredients?.totalCount || 0}
        paginationModel={paginationModel}
        columns={IngredientColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
