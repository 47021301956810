import { Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { useResidentMealExtrasQuery } from "../../../../store/apis/ResidentApis";
import { EditButton } from "../../../shared/EditButton";
import DeleteButton from "../../../shared/DeleteButton";
import { WarningText } from "../../../shared/WarningText";
import { BaseButton } from "components/shared/BaseButton";

export default function ResidentMealExtras() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentMealExtras,
    isLoading,
    isError,
  } = useResidentMealExtrasQuery(residentId, { skip: !residentId });

  function columnBoolean(param) {
    return (
      <>
        <Typography variant="p" width={40}>
          {param ? "Yes" : "No"}
        </Typography>
        {param ? (
          <Tooltip title="Yes">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons tealColor">
              verified_user
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="No">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons redColor">
              gpp_bad
            </span>
          </Tooltip>
        )}
      </>
    );
  }
  const mealExtrasColumns = [
    {
      field: "mealExtraName",
      minWidth: 55,
      flex: 3,
      headerName: "Meal Add-On",
    },
    {
      field: "description",
      minWidth: 55,
      flex: 3,
      headerName: "Description",
    },
    {
      field: "nutritionIntervention",
      minWidth: 25,
      flex: 2,
      headerName: "Nutrition Intervention?",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return columnBoolean(params?.row?.nutritionIntervention);
      },
    },
    {
      field: "daysOfTheWeek",
      minWidth: 55,
      flex: 2,
      headerName: "Days of The Week",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "mealName",
      minWidth: 50,
      flex: 1,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "amount",
      minWidth: 50,
      flex: 1,
      headerName: "Amount",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params?.row?.quantity} {params?.row?.unitName}
        </>
      ),
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addresidentMealExtras?mealExtrasId=${params.row.id}&id=${residentId}`}
              data={residentMealExtras}
              title="Resident Meal Add-Ons"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.mealExtraName}
              apiPath="deleteResidentMealExtras"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={0.5}>
        <Grid item sm={12} md={4} lg={3} xl={3} marginTop={1}>
          <BaseButton
            sx={{
              marginLeft: "0px !important",
              marginY: "0px !important",
            }}
            text={"Add Meal Add-On"}
            endIcon={"post_add"}
            To={`addresidentMealExtras?id=${residentId}`}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12} marginTop={1}>
          <WarningText
            text={`**Add a Meal Add-On (food, beverage or supplement) to a resident’s Mealcard to capture preferences, standing orders, nutrition interventions, etc. 
      Don't see something on our list? You can add custom House Meal Add-Ons within your community's MealCard Settings.`}
            width="100%"
            color="primary.main"
            sx={{ marginLeft: "0px !important", marginY: "0px !important" }}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={residentMealExtras}
            columns={mealExtrasColumns}
            error={isError}
            loading={isLoading}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "55vh",
              lg: "52vh",
              xl: "55vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
