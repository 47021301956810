import React, { useEffect, useState } from "react";
import "./Admin.css";
import Grid from "@mui/material/Grid";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AdminCard } from "./AdminCard";
import { Box, Container } from "@mui/material";
import { AdminElements } from "./admin.constants";
import BaseContent from "../shared/baseContent/BaseContent";

export default function Admin() {
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const [adminItems, setAdminItems] = useState(AdminElements);

  useEffect(() => {
    if (currentLocation.pathname == "/admin") {
      navigate(
        adminItems?.find((x) => x.IsActive)?.Children[0]?.Link ||
          adminItems?.[0]?.Children[0]?.Link
      );
    }

    setAdminItems(
      AdminElements.map((res) => {
        if (
          res.Children.some((x) => currentLocation.pathname.includes(x.Link))
        ) {
          res.Children.map((res2) => {
            if (currentLocation.pathname.includes(res2.Link)) {
              res2.IsActive = true;
            } else res2.IsActive = false;
            return res2;
          });
          res.IsActive = true;
        } else {
          res.IsActive = false;
          res.Children.map((res2) => {
            res2.IsActive = false;
            return res2;
          });
        }
        return res;
      })
    );
  }, [currentLocation?.pathname]);

  return (
    <BaseContent
      headerText="Administration"
      disableBack={true}
      transparent={true}>
      <Container
        id="admin-container"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "0px !important",
        }}
        maxWidth={false}>
        <Grid container spacing={3}>
          {adminItems.map((data, index) => {
            return <AdminCard key={index} cardProperties={data} />;
          })}
        </Grid>
        <Box
          id="admin-content"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}>
          <Outlet />
        </Box>
      </Container>
    </BaseContent>
  );
}
