import React, { useEffect, useState } from "react";
import { useGetCorporateGroupPagedQuery } from "../../../../../store/apis/CorporateGroupApis";
import {
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../shared/EditButton";
import DeleteButton from "../../../../shared/DeleteButton";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSelector } from "react-redux";

export default function CorporateGroups() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const {
    data: corporateGroupsPaged,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
  } = useGetCorporateGroupPagedQuery({
    searchTerm: debouncedSearchValue,
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const corporateGroups = corporateGroupsPaged?.items || [];

  const corporateGroupColumns = [
    {
      field: "name",
      headerName: "Corporate Group Name",
      flex: 3,
      minWidth: 100,
    },
    {
      field: "numberOfFacilities",
      headerName: "# of Communities",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        if (params.row["numberOfFacilities"] === 0) {
          return params.row["numberOfFacilities"];
        } else
          return (
            <Link
              to={`/admin/communities/communitySetup?corporateGroupId=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params.row["numberOfFacilities"]}
            </Link>
          );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addCorporateGroups?id=${params.row.id}`}
              data={corporateGroups}
              title="View Corporate Group"
            />
            <DeleteButton
              index={params.row.id}
              hintText={
                params.row["numberOfFacilities"] > 0
                  ? "Cannot delete Corporate Group with Communities"
                  : ""
              }
              entityName={params.row?.name}
              apiPath="deleteCorporateGroup"
              disabled={params.row["numberOfFacilities"] > 0}
            />

            <OptionsButton
              index={params.row.id}
              name={params.row.name}
              hasConsulting={params.row.hasConsulting}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedSearchValue}
            setSearchParams={setSearchParams}
          />
          <Button
            component={Link}
            to="addCorporateGroups"
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">person_add</span>}>
            Add Corporate Group
          </Button>
        </div>
      </div>
      <BaseDataGrid
        rows={corporateGroups}
        rowCount={corporateGroupsPaged?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={corporateGroupColumns}
        error={corporateGroupsError}
        loading={corporateGroupsLoading}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}

function OptionsButton({ index, name, hasConsulting }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const featureMealExtra = useSelector((state) => state.featureInfo.mealExtra);
  const [mapFlag, setMapFlag] = useState(true);
  useEffect(() => {
    let setActive = featureMealExtra?.enabled == "true";
    if (setActive) {
      setMapFlag(true);
    } else if (!setActive) {
      setMapFlag(false);
    }
  }, [featureMealExtra]);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FormControlLabel
        sx={{ marginLeft: "0px" }}
        control={
          <IconButton
            aria-label="Corporate Group Options"
            color="warning"
            onClick={handleClick}
            disableRipple>
            <span className="material-icons">more_vert</span>
          </IconButton>
        }
      />
      <Menu
        id={"edit-menu" + index}
        MenuListProps={{
          "aria-labelledby": "menu-button-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem disableRipple>
          <Link
            to={`menuExpiration?id=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            Extend Expiration
          </Link>
        </MenuItem>
        <MenuItem disableRipple>
          <Link
            to={`automaticSnack?id=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            Automatic Snacks
          </Link>
        </MenuItem>
        <MenuItem disableRipple>
          <Link
            to={`beverageSelection?id=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            Beverage Selection
          </Link>
        </MenuItem>
        {mapFlag ? (
          <MenuItem disableRipple>
            <Link
              to={`mealExtrasSelection?id=${index}`}
              style={{ textDecoration: "none", color: "black" }}>
              Meal Add-Ons Selection
            </Link>
          </MenuItem>
        ) : null}
        <MenuItem disableRipple>
          <Link
            to={`superUser?id=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            Super users
          </Link>
        </MenuItem>
        <MenuItem disableRipple>
          <Link
            to={`document?id=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            Documents
          </Link>
        </MenuItem>
        <MenuItem disableRipple>
          <Link
            to={`exportPBJ?id=${index}&name=${name}`}
            style={{ textDecoration: "none", color: "black" }}>
            PBJ Exports
          </Link>
        </MenuItem>
        {hasConsulting ? (
          <MenuItem disableRipple>
            <Link
              to={`consultantSchedule?id=${index}&name=${name}`}
              style={{ textDecoration: "none", color: "black" }}>
              Consultant Schedule
            </Link>
          </MenuItem>
        ) : undefined}
      </Menu>
    </>
  );
}
