import { Grid } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useFacilityConsultingTypesQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import { BaseButton } from "components/shared/BaseButton";

export default function CommunityConsulting() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 3,
  });

  const {
    data: consultingTypes,
    isLoading,
    isError,
  } = useFacilityConsultingTypesQuery(id, { skip: !id });

  const consultingTypeColumns = [
    {
      field: "consultingTypeName",
      headerName: "Consulting Type",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "rate",
      headerName: "Rate",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      flex: 1,
      minWidth: 100,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      field: "rateType",
      headerName: "Rate Type",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addcommunityconsulting?id=${id}&consultingTypeId=${params.row.id}`}
              data={consultingTypes}
              facilityId={id}
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.consultingTypeName}
              apiPath="deleteFacilityConsultingType"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid marginBottom={"8px"}>
        <BaseButton
          marginLeft={"0px !important"}
          text={"Add Consulting Type"}
          endIcon={"playlist_add"}
          To={`addcommunityconsulting?id=${id}`}
        />
      </Grid>
      <BaseDataGrid
        rows={consultingTypes}
        columns={consultingTypeColumns}
        error={isError}
        loading={isLoading}
        autoHeight={true}
      />
    </>
  );
}
