import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneratedForm from "../../../../shared/generatedForm/GeneratedForm";
import {
  ConsultantFields,
  TextFields,
} from "../consultantDetails/consultantDetails/formConstants";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import { isEqual, isNull, isUndefined } from "lodash";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { useForm } from "react-hook-form";
import { useGetFacilityByIdQuery } from "../../../../../store/apis/FacilityApis";
import {
  useAddLogRecordMutation,
  useGetLoggedRecordQuery,
  useGetVisitDetailsQuery,
  useGetVisitPlanEditDTOQuery,
  useUpdateFacilitiesMutation,
} from "../../../../../store/apis/ConsultantApis";
import { setErrorsForProperties } from "../../../../shared/utils/helpers";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditButton } from "../../../../shared/EditButton";
import DeleteButton from "../../../../shared/DeleteButton";
import dayjs from "dayjs";
import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import { setVisitFacilityId } from "store/slices/consultantSlice/Facility";
import { DMStyles } from "styles/Styles";

export const VisitDetailsForm = ({
  control,
  setValue,
  reset,
  watch,
  errors,
  isEdit,
  isFutureNA,
}) => {
  // stats collection and constants
  const { enqueueSnackbar } = useSnackbar();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editId, setEditId] = useState();
  const [edit, setEdit] = useState(false);
  const [queries, setQueries] = useState([
    {
      name: "consultingType",
      query: {},
      skip: true,
    },
  ]);
  const [addLogHiddenList, setAddLogHiddenList] = useState([]);
  const [hiddenList, setHiddenList] = useState([]);
  const VISIT_PLAN_ID_QUERY = "visitPlanId";
  const [searchParams] = useSearchParams();
  const currentPlanId = searchParams.get(VISIT_PLAN_ID_QUERY);
  const visitFacilityId = useSelector(
    (state) => state.facilityId.visitFacilityId
  );
  const dispatch = useDispatch();
  // this is used for add log APi

  const addLogMethods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructing of useform
  const {
    control: addLogControl,
    trigger: addLogTrigger,
    handleSubmit: addLogHandleSubmit,
    reset: addLogReset,
    watch: addLogWatch,
    setError: setAddError,
    formState: { errors: addLogError },
  } = addLogMethods;

  // variables to store data of inputs, used somewhere for checks
  const rateType = addLogWatch("consultingType");
  const dateOfVisit = watch("dateOfVisit");
  const communityId = watch("facilityId");
  const watchTimeIn = addLogWatch("timeIn");
  const watchTimeOut = addLogWatch("timeOut");

  // Triggering the validation on value change
  useEffect(() => {
    if (watchTimeIn && watchTimeOut) {
      addLogTrigger("timeIn");
      addLogTrigger("timeOut");
    }
  }, [watchTimeIn, watchTimeOut]);

  // Api section
  const { data: currentFacility } = useGetFacilityByIdQuery(communityId, {
    skip: !communityId,
  });

  const {
    data: loggedData,
    isLoading,
    isError,
    isSuccess: isLoggedSuccess,
  } = useGetLoggedRecordQuery(currentPlanId, {
    refetchOnMountOrArgChange: true,
  });
  // APi for Logged Data to show
  const {
    data: isVisitData,
    isLoading: isEditLoading,
    // isError: hasError,
  } = useGetVisitDetailsQuery(
    currentPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: preData,
    isLoading: isPreDataLoading,
    // isError: hasError,
  } = useGetVisitPlanEditDTOQuery(
    currentPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );

  // Mutation
  // Api to add log record into list
  const [
    addLog,
    { isLoading: isAddLogLoading, isSuccess: isAddSuccess, error: addError },
  ] = useAddLogRecordMutation();

  // This api is used to update the community/facilidyId against VisitId and PlanID
  const [updateFacility] = useUpdateFacilitiesMutation();

  const isGetError = isError && !isLoggedSuccess;
  // UseEffect Section
  // its used to Hide input in GenerateForm list on the basis of milage
  useEffect(() => {
    isEqual(rateType, 1)
      ? setAddLogHiddenList([{ name: "timeIn" }, { name: "timeOut" }])
      : setAddLogHiddenList([{ name: "mileage" }]);
  }, [rateType, edit, editId]);
  // used to show the existing value in order to edit
  useEffect(() => {
    if (isEdit && !isEditLoading) {
      reset(isVisitData);
      setDefaultOptions([
        ...defaultOptions,
        {
          name: "facilityId",
          option: [
            {
              id: isVisitData?.facilityId,
              label: isVisitData?.community,
            },
          ],
        },
      ]);
    }
  }, [isVisitData]);
  useEffect(() => {
    if (!isEdit && !isPreDataLoading) {
      reset(preData);
      setDefaultOptions([
        {
          name: "facilityId",
          option: [
            {
              id: preData?.facilityId,
              label: preData?.facilityName,
            },
          ],
        },
      ]);
      setDefaultValues([
        {
          name: "scheduled",
          value: preData?.scheduled,
        },
      ]);
      setValue("dateOfVisit", dayjs(preData?.planDate).format("YYYY-MM-DD"));
    }
  }, [preData]);
  // its used to Hide input in GenerateForm list on the basis of milage
  useEffect(() => {
    isFutureNA
      ? setHiddenList([{ name: "nextConsult" }])
      : setHiddenList([{ name: "nextVisitNotes" }]);
  }, [isFutureNA]);
  useEffect(() => {
    if (isAddSuccess && !isAddLogLoading) {
      enqueueSnackbar(` Logs ${edit ? "Updated" : "Added"} Successfully`, {
        variant: "success",
      });
      addLogReset({
        timeIn: "",
        timeOut: "",
      });
      setEdit(false);
    }
  }, [isAddSuccess, isAddLogLoading]);

  useEffect(() => {
    if (
      !isUndefined(communityId) &&
      visitFacilityId?.facilityId !== communityId
    ) {
      updateFacility({
        facilityId: communityId,
        planId: currentPlanId,
        visitId: isVisitData?.id,
      });
      dispatch(
        setVisitFacilityId({
          facilityId: communityId,
          communityName: currentFacility?.name,
        })
      );
    }
  }, [communityId, currentFacility]);

  useEffect(() => {
    if (edit) {
      const [dataperId] = loggedData.filter((data) => data.id === editId);
      if (dataperId) {
        // const parsedTime = dayjs("11:00 PM", { format: "hh:mm A" });
        // const formattedTime = parsedTime.format("hh:mm aa");
        const newObject = {
          ...dataperId,
          mileage: dataperId.quantity,
          selectedConsultingTypeRateType:
            dataperId?.consultingTypeId == 1 ? "1" : "0",
          consultingType: dataperId.consultingTypeId,
          timeIn:
            dataperId.timeIn === " "
              ? dayjs("11:00 PM", "hh:mm A").format("HH:mm")
              : dayjs(dataperId.timeIn, "h:mm A").format("HH:mm"),
          timeOut:
            dataperId.timeOut === " "
              ? dayjs("12:00 PM", "hh:mm A").format("HH:mm")
              : dayjs(dataperId.timeOut, "h:mm A").format("HH:mm"),
        };
        addLogReset(newObject);
      }
    }
  }, [edit, editId]);
  // used to get the consulting Type data on the basis of FacilityId
  useEffect(() => {
    if (visitFacilityId?.facilityId) {
      setQueries([
        ...queries.filter((x) => x.name != "consultingType"),
        {
          name: "consultingType",
          query: visitFacilityId?.facilityId,
        },
      ]);
    }
  }, [visitFacilityId]);
  // used to show error on the basis of inputField
  useEffect(() => {
    if (addError) {
      setErrorsForProperties({
        setError: setAddError,
        responseError: addError,
      });
    }
  }, [addError]);
  // function used to add log
  const onSubmit = (data) => {
    const newData = {
      visitPlanId: currentPlanId,
      visitHoursId: edit ? editId : 0,
      selectedConsultingType: `${data?.consultingType}`,
      selectedConsultingTypeRateType: rateType == 1 ? "1" : "0",
      timeIn: data?.timeIn
        ? dayjs(data?.timeIn, "HH:mm").format("hh:mm A")
        : "10:00 PM",
      timeOut: data?.timeOut
        ? dayjs(data?.timeOut, "HH:mm").format("hh:mm A")
        : "11:00 PM",
      mileage: data?.mileage || 0,
      note: data?.notes || "",
      planDate: new Date(dateOfVisit),
    };
    addLog(newData);
  };

  // column for dataGrid
  const viewReportColumns = [
    {
      field: "consultingTypeName",
      minWidth: 200,
      flex: 1,
      headerName: "Type",
    },
    {
      field: "timeIn",
      minWidth: 100,
      flex: 1,
      headerName: "Time In",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.timeIn === " " ? "--" : params.row.timeIn;
      },
    },
    {
      field: "timeOut",
      minWidth: 100,
      flex: 1,
      headerName: "Time Out",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.timeOut === " " ? "--" : params.row.timeOut;
      },
    },
    {
      field: "quantity",
      minWidth: 100,
      flex: 1,
      headerName: "Mileage",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return isNull(params.row.quantity) ? "--" : params.row.quantity;
      },
    },
    {
      field: "notes",
      minWidth: 200,
      flex: 1,
      headerName: "Notes",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
      renderCell: (params) => {
        return params.row.notes === ""
          ? "--"
          : params.row.notes?.length > 30
          ? params.row.notes?.slice(0, 30) + "..."
          : params.row.notes;
      },
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              onClick={(e) => {
                e.preventDefault();
                setEditId(params.row?.id);
                setEdit(true);
                // editAddLog(params.row?.id);
              }}
              index={params.row.id}
            />
            <DeleteButton
              sx={{ marginLeft: "10px !important" }}
              index={params.row.id}
              entityName={params.row?.consultingTypeName}
              apiPath="deleteVisitHours"
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Grid container spacing={1.5} sx={{ width: "40%" }}>
        <GeneratedForm
          list={ConsultantFields}
          control={control}
          defaultOptions={defaultOptions}
          defaultValues={defaultValues}
          setValue={setValue}
          errors={errors}
          hiddenList={hiddenList}
        />
      </Grid>
      <Box
        border="1px solid #CCCCCC"
        width={"100%"}
        padding={"10px"}
        borderRadius={"5px"}
        marginY={"10px"}>
        <Grid container spacing={1}>
          <GeneratedForm
            list={TextFields}
            hiddenList={addLogHiddenList}
            control={addLogControl}
            errors={addLogError}
            queries={queries}
          />
        </Grid>

        <Grid
          container
          marginTop={"-40px"}
          display={"flex"}
          justifyContent={"flex-end"}>
          <Grid item>
            <BaseSubmitButton
              text={edit ? "Update" : "Add"}
              variant="outline"
              sx={{
                border: `1px solid ${DMStyles.Teal} `,
                color: DMStyles.Teal,
              }}
              isSubmitting={isAddLogLoading}
              onClick={addLogHandleSubmit(onSubmit)}
            />{" "}
          </Grid>
        </Grid>
      </Box>
      <Box width={"100%"}>
        {!isUndefined(loggedData) ? (
          <BaseDataGrid
            rows={isGetError ? [] : loggedData || []}
            columns={viewReportColumns}
            loading={isLoading}
            error={isError}
            height={"100%"}
            // getRowHeight={() => "auto"}
            getRowId={(row) => row.id}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        ) : null}
      </Box>
      <ValidationErrorSummary errors={errors} />

      {openDeleteModal ? (
        <DeleteModal
          To={"/Consultant/MyRosters"}
          id={currentPlanId}
          apiPath={"deleteVisitPlane"}
          entityName={"This Visit"}
          open={openDeleteModal}
          close={() => {
            setOpenDeleteModal(false);
          }}
        />
      ) : null}
    </>
  );
};
