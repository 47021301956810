export const API_ENDPOINTS = {
  ADAPTIVE_EQUIPMENTS: "AdaptiveEquipments",
  ADMINISTRATION: "Administration",
  ALIS_MANAGEMENTS: "ALISManagements",
  ALLERGIES: "AllergyFoods",
  ALLERGY_FOODS: "AllergyFoods",
  APPLICATION_CONFIGURATION: "ApplicationConfiguration",
  BASE_MENUS: "BaseMenus",
  BEVERAGES: "Beverages",
  BULK_VENDOR_PRODUCTS: "BulkVendorProducts",
  CLIENT_FACILITIES: "ClientFacilities",
  COPYRECIPES: "recipes/CopyRecipe",
  CORPORATE_GROUPS: "CorporateGroups",
  CORPORATE_GROUP_BEVERAGES: "CorporateGroupBeverages",
  CORPORATE_GROUP_CATEGORIES: "CorporateGroupCategories",
  CORPORATE_GROUP_DOCUMENTS: "CorporateGroupDocuments",
  CORPORATE_GROUP_FOOD_TYPES: "CorporateGroupFoodTypes",
  CORPORATE_GROUP_MEAL_EXTRAS: "CorporateGroupMealExtras",
  CORPORATE_GROUP_USER: "CorporateGroupUsers",
  DIET: "Diets",
  DIETS: "Diets",
  DIETITIANS: "Dietitians",
  DIETITIAN_ADDITIONAL_REGIONS: "DietitianAdditionalRegions",
  DIETITIAN_DOCUMENTS: "DietitianDocuments",
  DIETITIAN_LICENCES: "DietitianLicense",
  DIETITIAN_REGIONS: "DietitianRegions",
  DIETITIAN_REPORTS: "DietitianReports",
  DIETITIAN_TYPES: "DietitianTypes",
  DIETITIAN_USERS: "DietitianUser",
  DINING_AREAS: "DiningAreas",
  DINING_TABLES: "DiningTables",
  DOCUMENTS: "Document",
  FACILITIES: "Facilities",
  FACILITY_ADAPTIVE_EQUIPMENTS: "FacilityAdaptiveEquipments",
  FACILITY_CARE_TYPES: "FacilityCareTypes",
  FACILITY_COMMUNICATIONS: "FacilityCommunications",
  FACILITY_CONSULTINGS: "FacilityConsultings",
  FACILITY_CONTRACTS: "FacilityContracts",
  FACILITY_CUSTOM_MENUS: "FacilityCustomMenu",
  FACILITY_DIETS: "FacilityDiets",
  FACILITY_DIETITIANS: "FacilityDietitian",
  FACILITY_EHRS: "FacilityEHRs",
  FACILITY_EHR_DIET_MAPPINGS: "FacilityEHRDietMapping",
  FACILITY_EHR_LIQUID_MAPPINGS: "FacilityEHRLiquidMapping",
  FACILITY_MEAL_EXTRAS: "FacilityMealExtras",
  FACILITY_TABLESIDES: "FacilityTableSide",
  FACILITY_THICKENED_LIQUIDS: "FacilityThickenedLiquids",
  FINISHED_REPORTS: "FinishedReports",
  FACILITY_USERS: "FacilityUsers",
  FAQS: "FAQs",
  FOOD_TYPES: "FoodTypes",
  INGREDIENTS: "Ingredients",
  INGREDIENT_ALLERGIES: "IngredientAllergy",
  INGREDIENT_CATEGORIES: "IngredientCategories",
  INGREDIENT_CONVERSIONS: "IngredientConversions",
  LIQUID_CONSISTENCIES: "ThickenedLiquids",
  MATRIX_MANAGEMENTS: "MatrixManagements",
  MEAL_BY_ORDERS: "MealByOrders",
  MEAL_EXTRAS: "MealExtras",
  MENUS: "Menus",
  MENUS_MANAGER: "MenuManager",
  MENU_PRINT_STYLES: "MenuPrintStyles",
  NUTRITIONAL_CARES: "NutritionalCares",
  ORDER_TRANSMISSIONS: "OrderTransmissions",
  PACKING_SLIPS: "PackingSlips",
  PAN_SIZES: "PanSizes",
  PCC_MANAGEMENTS: "PCCManagements",
  PRODUCTS: "Products",
  PURCHASING_GROUPS: "PurchasingGroups",
  RECIPES: "Recipes",
  RECIPE_CATEGORIES: "RecipeCategories",
  RECIPE_DIETS: "recipediets",
  RECIPE_FOOD_TYPES: "RecipeFoodTypes",
  RECIPE_INGREDIENTS: "RecipeIngredients",
  RECIPE_MENU_USAGES: "RecipeMenuUsage",
  RECIPE_NUTRIENT_ANALYSIS: "RecipeNutrientAnalysis",
  RECIPE_SHARING_GROUPS: "RecipeSharingGroups",
  RECIPE_TAGS: "RecipeTags",
  RECIPE_VENDOR_COSTS: "RecipeVendors",
  REGIONS: "Regions",
  RESIDENTS: "Residents",
  RESIDENT_ADAPTIVE_EQUIPMENTS: "ResidentAdaptiveEquipments",
  RESIDENT_ALLERGIES: "ResidentAllergy",
  RESIDENT_BEVERAGES: "ResidentBeverage",
  RESIDENT_CHANGES: "ResidentChanges",
  RESIDENT_FOOD_TYPES: "ResidentFoodType",
  RESIDENT_MEAL_EXTRAS: "ResidentMealExtras",
  RESIDENT_ALLERGIES_DISLIKES: "ResidentAllergyDislikes",
  RESIDENT_NOTES: "ResidentNotes",
  RESIDENT_SNACKS: "ResidentSnacks",
  RESIDENT_THICKENED_LIQUIDS: "ResidentThickenedLiquid",
  RESIDENT_WEIGHT: "ResidentWeights",
  RESIDENT_WEIGHT_BULK_ENTRY: "ResidentWeightBulkEntry",
  REPORTS: "Reports",
  ROLES: "Roles",
  SEASONS: "Seasons",
  SNACKS: "Snacks",
  STANDARD_TEXTS: "StandardTexts",
  SUB_RECIPES: "SubRecipes",
  SYSTEM_MESSAGES: "SystemMessages",
  TABLESIDES: "Tableside",
  TABLESIDE_AUTOMATIONS: "TableSideAutomation",
  TABLESIDE_HISTORIES: "TableSideHistories",
  TABLESIDE_SETUP: "TableSideSetup",
  TABLESIDE_TICKETS: "TableSideTicket",
  TAGS: "Tags",
  TEXTURES: "Textures",
  THICKENED_LIQUIDS: "ThickenedLiquids",
  TIME_ZONES: "TimeZone",
  TRAY_CARD_REPORTS: "TrayCardReport",
  UNITS: "Units",
  UTILITIES: "Utilities",
  USERS: "UserLogin",
  USER_CREDENTIALS: "UserCredentials",
  USER_FACILITIES: "UserFacilities",
  USER_ROLES: "UserRole",
  VENDORS: "Vendors",
  VENDOR_IMPORT_DATA: "VendorImportData",
  VENDOR_INGREDIENTS: "VendorIngredients",
  VENDOR_PRODUCTS: "VendorProduct",
  VISIT_PLANS: "VisitPlans",
  VISIT_REPORTS: "VisitReports",
  WEIGHT_ALERTS: "WeightAlerts",
};
