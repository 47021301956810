import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
// Hooks Imports
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import { Link, useNavigate } from "react-router-dom";
//  Custom Component Imports
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import { MyCalendar } from "./shared/MyCalendar";
import { ButtonWithCard } from "./shared/ButtonWithCard";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";

import { setMonth } from "store/slices/consultantSlice/Month";
import { setVisitFacilityId } from "store/slices/consultantSlice/Facility";
// Constants Imports
import {
  ButtonConstants,
  featureData,
  featureDataWithRole,
  userAllowedWithRoles,
} from "./constants";
import { DMStyles } from "../../../../styles/Styles";
// Mutation Imports
import {
  useDietitianNotSubmittedMutation,
  useDietScheduleReportMutation,
  useDietWeeklyReportMutation,
  useMissingConsultantMutation,
  useNotSubmittedReportMutation,
  useRDListMutation,
  useRedFlagsReportMutation,
} from "../../../../store/apis/ConsultantApis";
// Query Api Imports
import {
  useGetDietitianListsQuery,
  useGetDietitianVisitPlanQuery,
} from "../../../../store/apis/DietitianAPis";
import { setDietitionID } from "../../../../store/slices/consultantSlice/Dietition";
// Lodash Imports
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import dayjs from "dayjs";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";
import useMsalAccount from "utils/useMsalAccount";
import { theme } from "styles/theme";

export const MyRosters = () => {
  // States Declaration
  const [feature, setFeature] = useState("");
  const [event, setEvent] = useState(false);
  const [calendarKey, setCalendarKey] = useState(0);
  const [calendarDate, setCalendarDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [orderedByDate, setOrderedByDate] = useState([]);
  // Hooks Declaration
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // Destructing of useForm
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  // UseForm Input Fields Values
  const date = watch("date");
  const dietitianInputId = watch("dietitianId");
  const Month = dayjs(date).format("MM");
  const Year = dayjs(date).format("YYYY");
  // Local Store values
  const { userId: dietitianId, roles: userRoles } = useMsalAccount();
  const selectedDietitionId = useSelector(
    (state) => state.dietitionId.dietition
  );
  const selectedMonth = useSelector((state) => state.month.month);
  const isRoleAllowed = userRoles.some((roleToCheck) =>
    userAllowedWithRoles.includes(roleToCheck)
  );
  const reportsList = isRoleAllowed ? featureDataWithRole : featureData;

  //Get Api Section
  const {
    data: currentFacility,
    isError,
    isLoading,
  } = useGetDietitianVisitPlanQuery(
    {
      dietitionId: dietitianInputId,
      month: Month,
      year: Year,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: DietionData } = useGetDietitianListsQuery();

  // Structuring data for UI
  const planVisitData = currentFacility
    ?.map((item) => {
      const { planList, ...rest } = item;
      return planList?.map((plan) => ({
        ...rest,
        ...plan,
        startDate: dayjs(plan.planDate).format("YYYY-MM-DD"),
      }));
    })
    .flat();
  // func set DietitionId from local if there is any
  const setDietitionId = () => {
    if (isNull(selectedDietitionId)) {
      setValue("dietitianId", dietitianId);
      dispatch(setDietitionID(dietitianId));
    } else {
      dispatch(setDietitionID(dietitianInputId));
    }
  };
  // UseEffect Section
  useEffect(() => {
    isEqual(feature, 0)
      ? navigate(
          `/Consultant/MyRosters/submitVisit?dietitianId=${dietitianInputId}`
        )
      : null;
  }, [feature]);

  useEffect(() => {
    !isNil(selectedDietitionId)
      ? setValue("dietitianId", selectedDietitionId)
      : null;
    setValue(
      "date",
      isNil(date) && isNil(selectedMonth)
        ? dayjs(new Date()).format("YYYY-MM")
        : selectedMonth
    );
  }, []);
  useEffect(() => {
    const selectedDate = dayjs(date).format("YYYY-MM-DD");
    dispatch(setMonth(selectedDate));
    setCalendarDate(selectedDate);
    setCalendarKey((prevKey) => prevKey + 1);
  }, [date]);

  useEffect(() => {
    dietitianInputId !== selectedDietitionId || isNil(dietitianInputId)
      ? setDietitionId()
      : null;
  }, [dietitianInputId]);

  useEffect(() => {
    if (currentFacility) {
      setOrderedByDate(
        currentFacility?.map((item) => ({
          ...item,
          planList: item.planList
            ?.slice()
            .sort((a, b) => new Date(a.planDate) - new Date(b.planDate)),
        }))
      );
    }
  }, [currentFacility]);

  // Mutation Section
  const [
    DietitianScheduleReport,
    {
      data: scheduleReport,
      isLoading: scheduleReportLoading,
      isSuccess: scheduleReportSuccess,
    },
  ] = useDietScheduleReportMutation();
  const [
    GenerateWeeklyReport,
    { data: report, isLoading: generateLoading, isSuccess: addSuccess },
  ] = useDietWeeklyReportMutation();
  const [
    NotSubmittedReport,
    {
      data: notSubmittedReport,
      isLoading: notSubmittedLoading,
      isSuccess: notSubmittedSuccess,
    },
  ] = useNotSubmittedReportMutation();
  const [
    RedFlagsReport,
    {
      data: redFlagsReport,
      isLoading: redFlagLoading,
      isSuccess: redFlagsReportSuccess,
    },
  ] = useRedFlagsReportMutation();
  const [
    RDList,
    { data: rdList, isLoading: rdLoading, isSuccess: rdListSuccess },
  ] = useRDListMutation();
  const [
    MissingConsultant,
    {
      data: missingConsultant,
      isLoading: missingLoading,
      isSuccess: missingConsultantSuccess,
    },
  ] = useMissingConsultantMutation();
  const [
    DietitianNotSubmitted,
    {
      data: dietitianNotSubmitted,
      isLoading: dietitionNotSubmittedLoading,
      isSuccess: dietitianNotSubmittedSuccess,
    },
  ] = useDietitianNotSubmittedMutation();

  const loading =
    scheduleReportLoading ||
    generateLoading ||
    notSubmittedLoading ||
    redFlagLoading ||
    rdLoading ||
    missingLoading ||
    dietitionNotSubmittedLoading;

  // console.log("🚀 ~ MyRosters ~ loading:", loading);
  const isSuccess =
    scheduleReportSuccess ||
    addSuccess ||
    notSubmittedSuccess ||
    redFlagsReportSuccess ||
    rdListSuccess ||
    missingConsultantSuccess ||
    dietitianNotSubmittedSuccess;

  // Function for Submitting Schedule Report
  const onSubmitScheduleReport = async (all) => {
    const date = watch("date");
    DietitianScheduleReport({
      dietitianId: dietitianId,
      month: dayjs(date).format("MMMM YYYY"),
      allDietitians: all,
    });
  };

  // Function for Submitting Weekly Report
  const onSubmitWeeklyReport = async () => {
    const date = watch("date");
    GenerateWeeklyReport({
      dietitianId: dietitianId,
      month: dayjs(date).format("MMMM YYYY"),
    });
  };

  // const isSuccess = addSuccess;
  const handleReport = (id) => {
    switch (id) {
      case 1:
        onSubmitScheduleReport(false);
        break;
      case 2:
        onSubmitWeeklyReport();
        break;
      case 3:
        RDList();
        break;
      case 4:
        NotSubmittedReport();
        break;
      case 5:
        RedFlagsReport();
        break;
      case 6: {
        const date = watch("date");
        MissingConsultant({
          month: dayjs(date).format("MMMM YYYY"),
        });
        break;
      }
      case 7:
        DietitianNotSubmitted();
        break;
      case 8:
        onSubmitScheduleReport(true);
        break;
    }
  };

  useEffect(() => {
    if (isSuccess) {
      switch (feature) {
        case 1:
        case 8:
          scheduleReport?.data
            ? DownloadLinkHelper(scheduleReport?.data)
            : null;
          break;
        case 2:
          report?.data ? DownloadLinkHelper(report?.data) : null;
          break;
        case 3:
          rdList?.data ? DownloadLinkHelper(rdList?.data) : null;
          break;
        case 4:
          notSubmittedReport?.data
            ? DownloadLinkHelper(notSubmittedReport?.data)
            : null;
          break;
        case 5:
          redFlagsReport?.data
            ? DownloadLinkHelper(redFlagsReport?.data)
            : null;
          break;
        case 6:
          missingConsultant?.data
            ? DownloadLinkHelper(missingConsultant?.data)
            : null;
          break;
        case 7:
          dietitianNotSubmitted?.data
            ? DownloadLinkHelper(dietitianNotSubmitted?.data)
            : null;
          break;
      }
    }
  }, [
    scheduleReport?.data,
    report?.data,
    rdList?.data,
    notSubmittedReport?.data,
    redFlagsReport?.data,
    missingConsultant?.data,
    dietitianNotSubmitted?.data,
  ]);

  // function for frequency mapping
  const mapFrequency = (frequency) => {
    const frequencyMap = {
      W: "Weekly",
      M: "Monthly",
      Q: "Quarterly",
      A: "Annual",
      U: "Undefined",
    };
    return frequencyMap[frequency] || "";
  };

  // Column for DataGrid

  const rostersColumns = [
    {
      field: "facilityName",
      flex: 2,
      headerName: "Community",
      renderCell: (params) => {
        return (
          <Link
            to={`/Consultant/Community/Information?facilityId=${params.row.facilityId}&facilityName=${params.row.facilityName}`}
            style={{ color: "rgba(42, 83, 126, 1)" }}>
            {params.row["facilityName"]}
          </Link>
        );
      },
    },
    {
      field: "facilityCorporateGroup",
      flex: 1.5,
      headerName: "Corporate Group",
      renderCell: (params) => {
        return params.row.facilityCorporateGroupId &&
          params.row.facilityCorporateGroupId !== 85 ? (
          <Link
            to={`/Consultant/Enterprise/Visit?facilityId=${params.row.facilityId}&corporateId=${params.row.facilityCorporateGroupId}&facilityCorporateGroup=${params.row.facilityCorporateGroup}`}
            style={{ color: "rgba(42, 83, 126, 1)" }}>
            {params.row["facilityCorporateGroup"]}
          </Link>
        ) : (
          <>{params.row["facilityCorporateGroup"]}</>
        );
      },
    },
    {
      field: "contractedHours",
      flex: 1.5,
      headerName: "Contracted Hours",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "contractMinimumPer",
      flex: 1,
      headerName: "Frequency",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        const frequency = mapFrequency(params.row.contractMinimumPer);
        return frequency;
      },
    },
    {
      field: "planDate",
      flex: 3,
      headerName: "Reports",

      renderCell: (params) => {
        const planDates = params.row.planList;
        const facilityId = params.row.facilityId;
        return (
          <Grid container spacing={0.3}>
            {planDates.map((data, index) => {
              const bgColor = ButtonConstants.find(
                (color) => color.id == data.visitStatus
              );
              return (
                <Grid
                  item
                  key={index}
                  sx={{ cursor: "pointer", textAlign: "center" }}>
                  <Box
                    onClick={() => {
                      dispatch(
                        setVisitFacilityId({
                          facilityId: facilityId,
                          communityName: params.row["facilityName"],
                        })
                      );
                      if (!data?.visitId) {
                        navigate(
                          `/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${data.visitPlanId}`,
                          {
                            state: {
                              communityName: params.row.facilityName,
                              dataOfConsultant: data.planDate,
                            },
                          }
                        );
                      } else {
                        navigate(
                          `/Consultant/MyRosters/VisitDetails?visitPlanId=${data?.visitPlanId}`
                        );
                      }
                    }}>
                    <Typography
                      sx={{
                        backgroundColor: bgColor?.backgroundColor,
                        paddingY: "2px",
                        fontWeight: "bold",
                        paddingX: "10px",
                        color: DMStyles.Black,
                        borderRadius: "5px",
                        fontSize: "13px",
                      }}>
                      {dayjs(data.planDate).format("MMM DD")}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box width="5%">
              <ButtonWithCard
                id={params.row.id}
                dietitianId={params.row.dietitianId}
                facilityId={params.row.facilityId}
                visitId={params.row.visitId}
              />
            </Box>
            {/* <OptionsButton
              index={params.row.id}
              facilityId={params.row.facilityId}
              facilityName={params.row.facilityName}
              facilityCorporateGroupId={params.row.facilityCorporateGroupId}
              facilityCorporateGroup={params.row.facilityCorporateGroup}
            /> */}
          </div>
        );
      },
    },
  ];

  return (
    <Box padding="8px">
      <Box
        display="flex"
        flexDirection={{ md: "row", xs: "column" }}
        justifyContent="space-between"
        alignItems="center"
        width="100%">
        <Box
          width={{ lg: "40%", xs: "100%" }}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
          display="flex"
          alignItems={"center"}>
          <BaseDatePicker
            control={control}
            sx={{
              marginTop: "10px",
              marginRight: { lg: "8px", xs: "0px" },
              width: isRoleAllowed ? "100%" : "60%",
            }}
            label={"Date"}
            name={"date"}
            errors={errors}
            size="small"
            openTo="month"
            dateFormat="MMMM YYYY"
          />
          {isRoleAllowed ? (
            <BaseSelect
              sx={{
                marginTop: "5px",
              }}
              name="dietitianId"
              id="dietitianId"
              label="Consultant "
              size="small"
              control={control}
              errors={errors}
              options={DietionData || []}
              disableClearable={true}
            />
          ) : null}
        </Box>
        {/* <Box
          display="flex"
          width={{ lg: "35%", xs: "100%" }}
          justifyContent={"right"}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
          alignItems="center">
          <BaseButton
            sx={{
              marginLeft: "0px !important",
              marginTop: "10px",
            }}
            text={"Schedule Visit"}
          />
        </Box> */}
        <Box
          display="flex"
          flexDirection="row"
          width={{ lg: "30%", xs: "100%" }}
          justifyContent={!event ? "space-between" : "right"}
          // justifyContent={"right"}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
          alignItems="center">
          <Box width={"50%"} marginRight={{ lg: "8px", xs: "0px" }}>
            {!event ? (
              <>
                <BaseButton
                  sx={{
                    width: "100%",
                    marginLeft: "0px !important",
                    // marginTop: "10px",
                  }}
                  text="Calender View "
                  endIcon="insert_invitation"
                  colors="warning"
                  onClick={() => setEvent(true)}
                />
              </>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              width: "50%",
              borderRadius: "5px",
              backgroundColor: theme.palette.pacificBlue.dark,
              color: "white",
              height: "33px",
              lineHeight: "19px !important",
              fontWeight: "bold",
              outline: "none",
              border: "none !important",
            }}>
            <Select
              id="feature-select"
              value={feature}
              // className="baseButton"
              style={{
                width: loading ? "80%" : "100%",
                color: "white",
                fontWeight: "bold",
                outline: "none",
                border: "none !important",
              }}
              sx={{
                // marginTop: "10px",

                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
                ".MuiOutlinedInput-notchedOutline": { border: "0px" },
              }}
              onChange={(event) => {
                const selectedId = event.target.value;
                setFeature(selectedId);
                handleReport(selectedId); // Call handleReport here
              }}
              size="small"
              displayEmpty>
              {!feature && (
                <MenuItem value="" disabled>
                  Reports
                </MenuItem>
              )}

              {/* <MenuItem value="">Reports</MenuItem> */}
              {reportsList?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {loading ? <CircularProgress size={20} color="white" /> : null}
          </Box>

          {/* <BaseButton
            sx={{
              width: "100%",
              marginLeft: "0px !important",
              marginTop: "10px",
            }}
            text={"Schedule Visit"}
          /> */}
        </Box>
      </Box>
      {!event ? (
        <>
          <Box
            // border="1px solid #CCCCCC"
            marginTop="10px"
            // borderRadius="10px"
            // padding="10px"
            display="flex"
            flexDirection="column">
            <Box
              flexWrap={"wrap"}
              display="flex"
              flexDirection="row"
              alignItems="center">
              {ButtonConstants.map((data) => (
                <Box
                  marginRight={"10px"}
                  key={data.id}
                  display={"flex"}
                  alignItems={"center"}>
                  <Box
                    sx={{ backgroundColor: data.backgroundColor }}
                    width={"12px"}
                    height={"12px"}
                  />
                  <Typography
                    key={data.id}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginLeft: "5px",
                    }}>
                    {data.text}
                  </Typography>
                </Box>
              ))}
            </Box>
            {/* <Box marginTop="10px" display="flex" alignItems="center">
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                Frequency
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "2px",
                }}>
                W = Weekly, M = Monthly, Q = Quarterly, A = Annual, U =
                Undefined
              </Typography>
            </Box> */}
          </Box>
          <Box marginTop="20px" height={"60vh"}>
            <BaseDataGrid
              rows={orderedByDate || []}
              columns={rostersColumns}
              loading={isLoading}
              error={isError}
              width={"100%"}
              getRowHeight={() => "auto"}
              getRowId={(row) =>
                row.facilityId + "-" + row.facilityCorporateGroupId
              }
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <BackButton
            onClick={() => setEvent(false)}
            sx={{ marginTop: "5px" }}
          />

          <MyCalendar
            dietitionId={dietitianInputId}
            events={planVisitData}
            calendarDate={calendarDate}
            calendarKey={calendarKey}
          />
        </>
      )}
    </Box>
  );
};

// function OptionsButton({
//   index,
//   facilityId,
//   facilityName,
//   facilityCorporateGroupId,
//   facilityCorporateGroup,
// }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);

//   const handleClick = (event) => {
//     event.preventDefault();
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <>
//       <FormControlLabel
//         sx={{ margin: "0px" }}
//         control={
//           <IconButton
//             sx={{ margin: "0px", padding: "0px" }}
//             aria-label="EHR Options"
//             color="warning"
//             onClick={handleClick}
//             disableRipple>
//             <span className="material-icons">more_vert</span>
//           </IconButton>
//         }
//       />
//       <Menu
//         id={"edit-menu" + index}
//         MenuListProps={{
//           "aria-labelledby": "menu-button-" + index,
//         }}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}>
//         <MenuItem disableRipple>
//           <Link
//             to={`/Consultant/Community/Profile?facilityId=${facilityId}&facilityName=${facilityName}`}
//             style={{ textDecoration: "none", color: "black" }}>
//             View Community Profile
//           </Link>
//         </MenuItem>
//         <MenuItem disableRipple>
//           <Link
//             to={`/Consultant/Enterprise/Visit?corporateId=${facilityCorporateGroupId}&facilityCorporateGroup=${facilityCorporateGroup}`}
//             style={{ textDecoration: "none", color: "black" }}>
//             View Corporate Group
//           </Link>
//         </MenuItem>
//       </Menu>
//     </>
//   );
// }
