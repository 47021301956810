import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetBeverageQuery } from "../../../../../store/apis/BeverageApis";
import { FormControlLabel, IconButton, Button } from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";

export default function Beverages() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: beveragesPagedData,
    isError: hasError,
    isLoading: loading,
  } = useGetBeverageQuery({
    searchTerm: debouncedValue || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const beverages = beveragesPagedData?.items || [];

  const isError = hasError;
  const isLoading = loading;

  const EditBeverage = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addbeverages?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Beverages",
              data: beverages.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const DeleteBeverage = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteBeverage"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  const BeverageColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "defaultUnitName",
      headerName: "Default Unit",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "isSupplement",
      headerName: "Supplement",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.isSupplement} />;
      },
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditBeverage index={params.row.id} />
            <DeleteBeverage
              id={params.row.id}
              name={params.row["name"] || ""}
            />
            {/* <Link>
              <span className="material-icons">more_vert</span>
            </Link> */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
          />
          <Button
            component={Link}
            to={`addbeverages`}
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">post_add</span>}>
            Add New
          </Button>
        </div>
      </div>

      <BaseDataGrid
        rows={beverages}
        rowCount={beveragesPagedData?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={BeverageColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
