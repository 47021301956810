import React from "react";
import { useGetResidentAdaptiveEquipmentListQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../shared/EditButton";
import DeleteButton from "../../../shared/DeleteButton";
import { BaseButton } from "components/shared/BaseButton";

export default function AdaptiveEquipment() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentAdaptiveEquipments,
    isLoading,
    isError,
  } = useGetResidentAdaptiveEquipmentListQuery(residentId, {
    skip: !residentId,
  });

  const adaptiveEquipmentsColumns = [
    {
      field: "name",
      minWidth: 200,
      flex: 2,
      headerName: "Adaptive Equipment",
    },
    {
      field: "description",
      minWidth: 100,
      flex: 2,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "breakfastQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Breakfast",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "lunchQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Lunch",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "supperQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Dinner",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "snackQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Snack",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addAdaptiveEquipment?id=${residentId}&aeid=${params.row.id}`}
              data={residentAdaptiveEquipments}
              title="adaptiveEquipment"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.name}
              apiPath="deleteResidentAdaptiveEquipment"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} md={4} lg={3} xl={3} marginTop={1}>
          <BaseButton
            To={`addAdaptiveEquipment?id=${residentId}`}
            endIcon={"post_add"}
            text={"Add Adaptive Equipment"}
            marginLeft="0px !important"
            sx={{
              marginY: "0px !important",
            }}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={residentAdaptiveEquipments}
            columns={adaptiveEquipmentsColumns}
            error={isError}
            loading={isLoading}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "55vh",
              lg: "62vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
