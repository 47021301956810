import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import {
  CustomErrorOverlay,
  CustomNoRowsOverlay,
  SkeletonLoadingOverlay,
} from "../../admin/admin.overlays";
import "./BaseDataGrid.css";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseDataGrid.md)
 */
export default function BaseDataGrid({
  rows,
  loading,
  columns,
  error,
  defaultPageSize,
  width,
  height,
  errorMessage,
  useContainer = true,
  EmptyRowMessage,
  containerStyles,
  density = "compact",
  // autoHeight = true,
  ...rest
}) {
  function CustomErrorMessageOverlay() {
    return <CustomErrorOverlay errorMessage={errorMessage} />;
  }
  columns?.forEach((col) => {
    col && typeof col === "object" ? (col.description = col?.headerName) : null;
  });

  function CustomNoRowsOverlayMsg() {
    return <CustomNoRowsOverlay msg={EmptyRowMessage} />;
  }

  const dataGridProps = {
    disableRowSelectionOnClick: true,
    rows: rows || [],
    // autoHeight,
    className: loading ? "loadingDataGrid" : "",
    components: {
      NoRowsOverlay: errorMessage
        ? CustomErrorMessageOverlay
        : CustomNoRowsOverlayMsg,
      LoadingOverlay: SkeletonLoadingOverlay,
      ErrorOverlay: CustomErrorOverlay,
    },
    initialState: {
      pagination: {
        paginationModel: {
          pageSize: defaultPageSize || 100,
          page: 0,
        },
      },
    },
    border: 0,
    loading,
    columns: columns || [],
    headerHeight: 66,
    rowHeight: 66,
    disableSelectionOnClick: true,
    pageSizeOptions: [10, 25, 50, 100],
    pagination: true,
    cellClassName: "dataCells",
    error: error || undefined,
    density: density,
    ...rest,
  };

  return (
    <>
      {useContainer ? (
        <Box
          display={"block"}
          flexGrow={1}
          height={height || "90%"}
          width={width || "100%"}
          overflow={"auto"}
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: rest?.headerColor,
            },
          }}
          {...containerStyles}>
          <DataGrid {...dataGridProps} />
        </Box>
      ) : (
        <DataGrid {...dataGridProps} />
      )}
    </>
  );
}
