import React, { useState, useEffect } from "react";
import "./ReserveList.css";
import {
  IconButton,
  FormControlLabel,
  Typography,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  Modal,
  Fade,
  Grid,
  Box,
} from "@mui/material";
import { useSearchParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import {
  useGetReservedListQuery,
  useDeleteReservedMutation,
  useMoveResidentToActiveMutation,
  useGetResidentProfileReportQuery,
} from "../../../store/apis/ResidentApis";

import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../shared/baseSearch/BaseSearch";
import { DownloadLinkHelper } from "../../shared/utils/helpers";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { BaseButton } from "components/shared/BaseButton";

export default function ReservedList() {
  const facilityId = useSelector(selectFacilityId);
  const [DataArray, setDataArray] = useState([]);
  const [DataSelectArray, setDataSelectArray] = useState([]);
  const [ischeckBox, setCheckox] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [residentId, setResidentId] = useState(0);
  const [fetchReport, setFetchReport] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const SEARCH_PARAM = "search";

  const {
    data: reservedList,
    isFetching: loading,
    isError: hasError,
  } = useGetReservedListQuery(
    {
      facilityId: facilityId,
      searchText: debouncedSearchValue,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [deleteResident, { isSuccess: deleteSuccess }] =
    useDeleteReservedMutation();

  const [moveToActive, { isSuccess: moveSuccess, isLoading: moveLoading }] =
    useMoveResidentToActiveMutation();

  const { data: profileReport, isSuccess: reportSuccess } =
    useGetResidentProfileReportQuery(residentId, { skip: !fetchReport });

  const success = deleteSuccess;

  const onSubmit = (Ids) => {
    deleteResident(Ids);
    setOpenDeleteModal(false);
    if (success) {
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    const temp = [];
    reservedList?.length > 0 &&
      reservedList?.map((item, index) => {
        temp?.push({
          id: item.id,
          lastName: item.lastName,
          firstName: item.firstName,
          dateOfBirth: item.dateOfBirth,
          archivedDate: item.archivedDate,
          selected: false,
          index: index,
        });
      });
    setDataArray(temp);
  }, [reservedList]);

  const updateftn = (data) => {
    const newArr = [...DataArray];
    newArr[data.index].selected = !data.selected;
    setDataArray(newArr);
    if (data.selected) {
      setDataSelectArray([...DataSelectArray, newArr[data.index].id]);
    } else {
      let deleteSelect = DataSelectArray.filter(function (el) {
        return newArr[data.index].id != el;
      });
      setDataSelectArray(deleteSelect);
    }
  };

  useEffect(() => {
    if (moveSuccess && !moveLoading) {
      // console.log("moveSuccess");
    }
  }, [moveSuccess, moveLoading]);

  useEffect(() => {
    if (reportSuccess && fetchReport) {
      DownloadLinkHelper(profileReport);
      setFetchReport(false);
    }
  }, [profileReport]);

  const moveSelectedToActive = () => {
    if (DataSelectArray.length > 0) {
      moveToActive(DataSelectArray);
    }
  };

  const CheckBoxUI = ({ params }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={params.row?.selected}
            onClick={() => {
              updateftn(params.row);
            }}
          />
        }
        label={params.row.lastName}
      />
    );
  };

  const residentsColumns = [
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <CheckBoxUI params={params} />
          </div>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      flex: 1,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).toDate() : "",
      valueFormatter: (params) => {
        const isDateValid = dayjs(params.value).isValid();
        return isDateValid
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "archivedDate",
      headerName: "Archived Date",
      flex: 1,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).toDate() : "",
      valueFormatter: (params) => {
        const isDateValid = dayjs(params.value).isValid();
        return isDateValid
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <DeleteButton index={params.row.index} data={params.row} />
            <OptionsButton index={params.row.id} name={params.row.firstName} />
          </div>
        );
      },
    },
  ];

  function DeleteButton({ data }) {
    return (
      <FormControlLabel
        sx={{ marginLeft: "6px", marginRight: "0px" }}
        control={
          <IconButton
            aria-label="Remove User"
            color="error"
            onClick={() => {
              setOpenDeleteModal(true);
              setCheckox(false);
              setDeleteData(data);
            }}
            disableRipple>
            <span className="material-icons">delete</span>
          </IconButton>
        }
      />
    );
  }

  function DeleteSelectedButton({ open, close }) {
    return (
      <Modal open={open} onClose={close}>
        <Fade in={open}>
          <div className={"deleteModal"}>
            <div className="modalContent">
              <Typography variant="h6" component="h2" className="modalTitle">
                {`Are you sure you want to delete the ${
                  ischeckBox == true
                    ? "selected"
                    : deleteData.firstName + " " + deleteData.lastName
                }?`}
              </Typography>
            </div>
            <div className="deleteActions">
              <Button
                className="baseButton"
                variant="contained"
                onClick={close}
                color={"warning"}>
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: "10px" }}
                className="baseButton"
                variant="contained"
                onClick={() => {
                  if (ischeckBox == true) onSubmit(DataSelectArray);
                  else onSubmit([deleteData?.id]);
                }}
                color={"error"}>
                Delete
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }

  function OptionsButton({ index }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMoveToActive = () => {
      var data = [];
      data.push(index);

      moveToActive(data);
    };

    const handleProfileReport = () => {
      // console.log("handleProfileReport", index);
      setResidentId(index);
      setFetchReport(true);
    };

    return (
      <>
        <FormControlLabel
          sx={{ marginLeft: "0px" }}
          control={
            <IconButton
              aria-label="Options"
              color="warning"
              onClick={handleClick}
              disableRipple>
              <span className="material-icons">more_vert</span>
            </IconButton>
          }
        />
        <Menu
          id={"edit-menu" + index}
          MenuListProps={{
            "aria-labelledby": "menu-button-" + index,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <MenuItem disableRipple>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              onClick={handleMoveToActive}>
              Move to Active
            </Link>
          </MenuItem>
          <MenuItem disableRipple>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              onClick={handleProfileReport}>
              Profile Report
            </Link>
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={0.5} marginTop={1} paddingRight={1}>
        <Grid item sm={6} md={8} lg={8} xl={8}>
          <Typography variant="h6" component="h2">
            Reserved List
          </Typography>
        </Grid>
        <Grid item sm={3} md={4} lg={4} xl={4} sx={{ float: "right" }}>
          <Box display="flex">
            <BaseButton
              text="Delete Selected"
              colors="error"
              disabled={DataSelectArray?.length === 0}
              onClick={() => {
                if (DataSelectArray?.length > 0) {
                  setOpenDeleteModal(true);
                  setCheckox(true);
                }
              }}
            />
            <BaseButton
              colors="warning"
              text="Move Selected to Active"
              onClick={() => moveSelectedToActive()}
            />
          </Box>
        </Grid>
        <Grid item sm={8} md={6} lg={6} xl={6}>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedSearchValue}
            setSearchParams={setSearchParams}
          />
        </Grid>

        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={DataArray.length > 0 ? DataArray : []}
            columns={residentsColumns}
            loading={loading}
            error={hasError}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "55vh",
              lg: "56vh",
              xl: "59vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
        {openDeleteModal && (
          <DeleteSelectedButton
            open={openDeleteModal}
            close={() => {
              setOpenDeleteModal(false);
            }}
          />
        )}
      </Grid>
    </>
  );
}
