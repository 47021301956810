import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useGetCorporateGroupCategoriesQuery } from "../../../../../../store/apis/CorporateGroupApis";
import { Box, Button, Typography } from "@mui/material";
import DeleteModal from "../../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function CorporateGroupCategories() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("id");

  const [categoryName, setCategoryName] = useState("");

  const {
    data: corporateGroupCategories,
    isLoading: isLoading,
    isError: isError,
  } = useGetCorporateGroupCategoriesQuery(categoryId, {
    refetchOnMountOrArgChange: true,
    skip: !categoryId,
  });

  useEffect(() => {
    if (corporateGroupCategories && corporateGroupCategories.length > 0) {
      setCategoryName(corporateGroupCategories[0].categoryName);
    }
  }, [corporateGroupCategories]);

  const CorpGroupCatDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <Box display={"flex"} justifyContent={"center"} spacing={2}>
          <Button onClick={() => setOpenModal(true)}>
            <span className="material-icons redColor">delete</span>
          </Button>
        </Box>
        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deleteCorporateGroupCategory"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const CorpGroupCategoryColumns = [
    {
      field: "corporateGroupName",
      headerName: "Corporate Group",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <CorpGroupCatDelete
              id={params.row.id}
              name={params.row["corporateGroupName"]}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseButton
            text="Back To Recipe Categories"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
            sx={{ marginBottom: "10px" }}
          />
          <Button
            sx={{ marginLeft: "20px", marginBottom: "25px" }}
            component={Link}
            to={`addCorporateGroup?id=${categoryId}`}
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">post_add</span>}>
            Add Corporate Group
          </Button>
        </div>
      </div>
      <Typography
        color="primary"
        variant="h5"
        className="userFacilityTitle"
        style={{ marginBottom: "10px", width: "70%" }}>
        Corporate Groups with access to {categoryName} Category
      </Typography>
      <BaseDataGrid
        rows={corporateGroupCategories}
        columns={CorpGroupCategoryColumns}
        loading={isLoading}
        error={isError}
        width={"70%"}
      />
    </>
  );
}
