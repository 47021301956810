import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Typography, Grid } from "@mui/material";

import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import CensusForm from "./stepperForms/Census";
import CategoryForm from "./stepperForms/CategoryForm";
import DayForm from "./stepperForms/DayForm";
import Diet from "./stepperForms/Diet";
import SnackMeal from "./stepperForms/SnackMeal";
import Report from "./stepperForms/Report";

import {
  useGetDietlistPrintQuery,
  usePrintItemReportMutation,
  useGetAllergenslistPrintQuery,
  useGetTempPostedMenuListQuery,
  useGetTempOtherPostedMenuListQuery,
  useGetMenuCensusQuery,
} from "../../../../store/apis/MenuApis";

import { useMenuDaySelectQuery } from "../../../../store/apis/PrintReportApis";
import { useGetFacilityIDDSIFlagQuery } from "../../../../store/apis/FacilityApis";

import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import BaseContent from "../../../shared/baseContent/BaseContent";
// import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import { ReportDetails } from "./constants/reports.constants";
// import Categories from "./stepperForms/Categories";

export default function PrintItem() {
  const [rowModesModel, setRowModesModel] = useState({});

  const [activeStep, setActiveStep] = useState(0);
  const facilityId = useSelector(selectFacilityId);
  const [searchParams] = useSearchParams();
  const Id = searchParams.get("id");

  const location = useLocation();

  const title = location?.state?.title;
  const [dietrows, setDietRows] = useState([]);
  const [postedMenurows, setPostedMenuRows] = useState([]);
  const [otherPostedMenurows, setOtherPostedMenuRows] = useState([]);
  const [allergenRow, setAllergenRow] = useState([]);
  const [allergenIds, setAllergenIds] = useState([]);

  const stepsMeal = [
    "Category",
    "DayForm",
    "Census",
    "Diet",
    "SnackMeal",
    "Report",
  ];

  const {
    handleSubmit,
    control,
    trigger,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const watchReportId = watch("reportId");

  const reportDetails = ReportDetails.find((x) => x.value == watchReportId);
  const [
    GenerateReport,
    {
      data: report,
      // isError: addError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      // error: addErrorRes,
      reset: resetServerRequest,
    },
  ] = usePrintItemReportMutation();

  const { data: diets, isFetching: isFetchingDiets } = useGetDietlistPrintQuery(
    {
      menuId: Id,
      facilityId,
    },
    {
      skip:
        !watchReportId ||
        !facilityId ||
        !Id ||
        (watchReportId != 12 &&
          watchReportId != 8 &&
          watchReportId != 6 &&
          watchReportId != 5 &&
          watchReportId != 10 &&
          watchReportId != 3 &&
          watchReportId != 1 &&
          watchReportId != 2 &&
          watchReportId != 4),
    }
  );

  const { data: postedMenu, isFetching: isFetchingPostedMenu } =
    useGetTempPostedMenuListQuery(
      {
        menuId: Id,
        startDay: watch("startingDay") ? watch("startingDay") : 1,
        endDay: watch("endingDay") ? watch("endingDay") : 1,
        includeOther: watch("includeMenuOtherItems")
          ? watch("includeMenuOtherItems")
          : false,
      },
      {
        skip: !Id || !watchReportId || watchReportId != 7,
      }
    );

  const { data: postedOtherMenu, isFetching: isFetchingPostedOtherMenu } =
    useGetTempOtherPostedMenuListQuery(Id, {
      skip: !Id || !watchReportId || watchReportId != 13,
    });

  const { data: allergens, isFetching: isFetchingallergy } =
    useGetAllergenslistPrintQuery({
      skip: !watchReportId || watchReportId != 12,
    });

  useEffect(() => {
    const temp = [];
    allergens?.items.length > 0 &&
      allergens?.items?.map((item) => {
        temp?.push({
          id: item.id,
          name: item.name,
          islint: false,
          sortOrder: item.sortOrder,
        });
      });
    setAllergenRow(temp);
    setPostedMenuRows(postedMenu);
    setOtherPostedMenuRows(postedOtherMenu);
    if (watchReportId == 10)
      setDietRows(
        diets?.map((row) => {
          return {
            ...row,
            isInList: row.census > 0 ? true : false,
          };
        })
      );
    else setDietRows(diets);
  }, [diets, postedMenu, allergens, postedOtherMenu, watchReportId]);

  const { data: menuCensus } = useGetMenuCensusQuery(
    { facilityId },
    {
      skip: !facilityId,
    }
  );

  const { data: menuDays } = useMenuDaySelectQuery(
    { menuId: Id },
    { skip: !Id }
  );

  const { data: IDDSIFlag } = useGetFacilityIDDSIFlagQuery(facilityId, {
    skip: !facilityId,
  });

  // const isError = addError;
  const isSuccess = addSuccess;
  const isSubmitting = addLoading;

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const processRowUpdate = (newRow) => {
    let updatedRow, updatedData, updateCondition;

    let setPostedMenuRows2 = setPostedMenuRows;

    switch (Number(watchReportId)) {
      case 7:
        updatedRow = [...postedMenurows];
        updateCondition = (item) => item.id === newRow?.id;
        setPostedMenuRows2 = (data) => setPostedMenuRows(data);
        break;
      case 13:
        updatedRow = [...otherPostedMenurows];
        updateCondition = (item) => item.category === newRow?.category;
        setPostedMenuRows2 = (data) => setOtherPostedMenuRows(data);
        break;
      case 12:
        if (newRow.islint) {
          setAllergenIds([...allergenIds, newRow.id]);
        } else {
          setAllergenIds(allergenIds.filter((id) => newRow.id !== id));
        }
        setAllergenRow([
          ...allergenRow.map((item) =>
            item.id === newRow?.id ? { ...item, islint: newRow.islint } : item
          ),
        ]);

        break;
      default:
        setDietRows(
          dietrows.map((row) =>
            row.dietId === newRow.dietId ? { ...newRow } : row
          )
        );
        return { ...newRow };
    }

    if (updatedRow && updateCondition) {
      updatedData = updatedRow.map((item) =>
        updateCondition(item) ? { ...newRow } : item
      );
      setPostedMenuRows2(updatedData);
    }
    return { ...newRow };
  };

  const onSubmit = async (data) => {
    // Validation Error for MenuAllergen Type (12)
    if (
      parseInt(data?.reportId) == 12 &&
      (!allergenIds || allergenIds.length == 0)
    ) {
      setError("selectedAllergyFoodIds", {
        type: "manual",
        message: "Please select at least one allergen",
      });
      return;
    }

    GenerateReport({
      ...reportDetails?.defaults,
      ...data,
      firstDate: data?.firstDate ? data?.firstDate : new Date(),
      lastDate: data?.lastDate ? data?.lastDate : new Date(),
      reportId: parseInt(data?.reportId),
      menuId: Id,
      shoppingListGrouping: data?.shoppingListGrouping
        ? parseInt(data?.shoppingListGrouping)
        : 0,
      startingDay: data?.startingDay ? parseInt(data?.startingDay) : 0,
      endingDay: data?.endingDay ? parseInt(data?.endingDay) : 0,
      dietQty1: data?.dietQty1 ? data?.dietQty1 : 0,
      dietQty2: data?.dietQty2 ? data?.dietQty2 : 0,
      dietQty3: data?.dietQty3 ? data?.dietQty3 : 0,
      selectedRecipes: postedMenurows,
      selectedRecipeCategories: otherPostedMenurows,
      selectedAllergyFoodIds: allergenIds,

      reportDateOptionId: data?.reportDateOptionId
        ? parseInt(data?.reportDateOptionId)
        : 0,
      byDayMealId: data?.byDayMealId ? parseInt(data?.byDayMealId) : 0,
      layoutsForSelectionSheetId: data?.layoutsForSelectionSheetId
        ? parseInt(data?.layoutsForSelectionSheetId)
        : 0,
      startDate: data?.startDate ? new Date(data?.startDate) : new Date(),
      startMenuDay: data?.startMenuDay ? parseInt(data?.startMenuDay) : 0,
      formatTypeId: data?.formatTypeId ? parseInt(data?.formatTypeId) : 0,
      postedMenuReportId:
        watchReportId == 13
          ? otherPostedMenurows?.length > 0 && otherPostedMenurows[0].reportId
          : watchReportId == 7
          ? postedMenurows?.length > 0 && postedMenurows[0].reportId
          : "",
      printMealId: data?.printMealId ? parseInt(data?.printMealId) : 0,
      selectedMenuDietIds: dietrows,
    });
  };

  const handleNext = async () => {
    const isStepValid = await trigger();

    if (!isStepValid) return;

    if (
      !(parseInt(watchReportId) == 12) ||
      (allergenIds && allergenIds.length == 0)
    )
      clearErrors("selectedAllergyFoodIds");

    let currentStep = activeStep + 1;

    for (let i = currentStep; i < stepsMeal.length; i++) {
      if (reportDetails?.forms?.[stepsMeal[currentStep]]) {
        break;
      }
      if (!reportDetails?.forms?.[stepsMeal[i]]) {
        currentStep++;
      }
    }

    if (currentStep === 0) {
      resetServerRequest();
      clearErrors();
    }

    if (activeStep === 3) {
      setTimeout(() => {
        setActiveStep(() => currentStep);
      }, 600);
      return;
    }
    setActiveStep(() => currentStep);
  };

  const handleBack = () => {
    if (
      !(parseInt(watchReportId) === 12) ||
      (allergenIds && allergenIds.length === 0)
    ) {
      clearErrors("selectedAllergyFoodIds");
    }

    if (activeStep <= 0) navigate(-1);

    let currentStep = activeStep - 1;

    for (let i = currentStep; i > 0; i--) {
      if (reportDetails?.forms?.[stepsMeal[currentStep]]) {
        break;
      }
      if (!reportDetails?.forms?.[stepsMeal[i]]) {
        currentStep--;
      }
    }

    if (currentStep === 0) {
      resetServerRequest();
      clearErrors();
    }

    if (activeStep === 3) {
      setTimeout(() => {
        setActiveStep(() => currentStep);
      }, 600);
      return;
    }
    setActiveStep(() => currentStep);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <CategoryForm errors={errors} control={control} />;
      case 1:
        return (
          <DayForm
            list={reportDetails?.forms?.DayForm}
            errors={errors}
            watch={watch}
            control={control}
            iddsiFlag={IDDSIFlag}
            setValue={setValue}
            trigger={trigger}
            dirtyFields={dirtyFields}
          />
        );
      case 2:
        return (
          <CensusForm
            list={reportDetails?.forms?.Census}
            errors={errors}
            control={control}
            menuCensus={menuCensus}
            watch={watch}
          />
        );
      case 3:
        return (
          <Diet
            errors={errors}
            control={control}
            watch={watch}
            menuId={Id}
            diets={diets}
            isFetchingDiets={isFetchingDiets}
            isFetchingallergy={isFetchingallergy}
            allergens={allergens}
            isFetchingPostedMenu={isFetchingPostedMenu}
            postedMenu={postedMenu}
            postedMenurows={postedMenurows}
            setPostedMenuRows={setPostedMenuRows}
            isFetchingPostedOtherMenu={isFetchingPostedOtherMenu}
            postedOtherMenu={postedOtherMenu}
            setDietRows={setDietRows}
            dietrows={dietrows}
            otherPostedMenurows={otherPostedMenurows}
            setOtherPostedMenuRows={setOtherPostedMenuRows}
            allergenRow={allergenRow}
            setAllergenRow={setAllergenRow}
            allergenIds={allergenIds}
            setAllergenIds={setAllergenIds}
            processRowUpdate={processRowUpdate}
            rowModesModel={rowModesModel}
            setRowModesModel={setRowModesModel}
          />
        );
      case 4:
        return (
          <SnackMeal
            watch={watch}
            list={reportDetails?.forms?.SnackMeal}
            errors={errors}
            control={control}
            menuId={Id}
            setValue={setValue}
          />
        );
      case 5: 
        return (
          <Report
            list={reportDetails?.forms?.Report}
            errors={errors}
            control={control}
          />
        );
      // case watchReportId == 10 ? 5 : null:
      //   if (watchReportId == 10) {
      //     return <Categories errors={errors} control={control} />;
      //   }
      //   break;
      // case watchReportId == 10 ? 6 : 5:
      //   return (
      //     <Report
      //       list={reportDetails?.forms?.Report}
      //       errors={errors}
      //       control={control}
      //     />
      //   );
      default:
        return "Unknown step";
    }
  };

  const stepControls = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 4,
        }}>
        <Button
          variant="contained"
          color="primary"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
          startIcon={
            <span className="material-icons">keyboard_arrow_left</span>
          }>
          Previous
        </Button>
        {/* {watchReportId == 10 ? (
          activeStep == stepsMeal.length ? (
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isSubmitting}
              text={"Submit"}
            />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              endIcon={
                <span className="material-icons">keyboard_arrow_right</span>
              }>
              Next
            </Button>
          )
        ) :  */}
        {activeStep == stepsMeal.length - 1 ? (
          <BaseSubmitButton
            onClick={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            text={"Submit"}
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            endIcon={
              <span className="material-icons">keyboard_arrow_right</span>
            }>
            Next
          </Button>
        )}
      </Box>
    );
  };

  return (
    <BaseContent
      headerText={`Print Menu - ${reportDetails?.label}`}
      backLink={"/plateFul/Menus"}
      backText={"Back to Menu List"}
      disableHeight={true}>
      <Box height={{ md: "80vh", xl: "85vh" }}>
        <Typography variant="h5" className="print-heading">
          Menu: {title}
        </Typography>

        <Typography variant="p" className="print-heading print-title-box">
          {(() => {
            switch (activeStep) {
              case 0:
                return `Select the item you want to view/print, then click Next`;
              case 1:
                return `Enter the first and last days of the menu cycle to print and click 'Next'. There are ${menuDays?.length} days in this menu.`;
              case 2:
                return "Enter the census/quantities to use,  then click ‘Next’.";
              case 3:
                return "Select the diets you want to include, then click 'Next'.";
              case 4:
                return "Select desired options, then click 'Next'.";
              // case watchReportId == 10 ? 5 : null:
              //   return "Customize which categories and extra information you want on your production sheet, then click 'Next'.";
              // case watchReportId == 10 ? 6 : 5:
              case 5:
                return "Select the output format for the report, then click 'Submit'";
              default:
                return "Select the output format for the report, then click 'Submit'.";
            }
          })()}
        </Typography>

        <FormProvider sx={{ marginTop: 40 }}>
          <form>
            {stepControls()}
            <Grid container spacing={1} paddingX={"20px"}>
              {getStepContent()}
            </Grid>

            {/* {(isError || errors) && (
              <ValidationErrorSummary
                errors={addErrorRes}
                formErrors={errors}></ValidationErrorSummary>
            )} */}
          </form>
        </FormProvider>
      </Box>
    </BaseContent>
  );
}
