import { React } from "react";
import { Grid, styled } from "@mui/material";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { ResidentInformationFields } from "./forms.constants";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function MetricsForm({
  isEdit,
  register,
  facilityLoading,
  control,
  errors,
  canEdit,
}) {
  const { formTextFields2, formTextFields3, formSelectProps2 } =
    ResidentInformationFields;

  const usualBodyWeightSource = [
    {
      id: "1",
      label: "Per Resident",
    },
    {
      id: "2",
      label: "Per Family",
    },
    {
      id: "3",
      label: "Other",
    },
  ];

  const StyledBaseInput = styled(BaseInput)`
    .MuiInputBase-root {
      background-color: ${({ ...props }) =>
        (props.checkEMREdit ? canEdit : true) ? undefined : "lightgrey"};
    }
  `;
  return (
    <Grid container spacing={2}>
      {isEdit && (
        <input
          hidden
          {...register("id", { required: REQUIRED_ERROR("Id") })}></input>
      )}
      {formTextFields3.map((data, index) => {
        const props = data?.props;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item xs={5}></Grid>;
          default:
            return (
              <Grid key={index} className="baseButton" item xs={5}>
                <StyledBaseInput
                  control={control}
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  errors={errors}
                  disabled={props.checkEMREdit ? !canEdit : false}
                />
              </Grid>
            );
        }
      })}

      <Grid item xs={5} className="baseButton">
        <BaseSelect
          control={control}
          options={usualBodyWeightSource}
          errors={errors}
          loading={facilityLoading}
          validationProps={formSelectProps2?.validationProps}
          label={formSelectProps2?.props?.label}
          name={formSelectProps2?.props?.name}
          id={formSelectProps2?.props?.id}
        />
      </Grid>

      {formTextFields2.map((data, index) => {
        const props = data?.props;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item xs={5}></Grid>;
          default:
            return (
              <Grid key={index} className="baseButton" item xs={5}>
                <BaseInput
                  control={control}
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  errors={errors}
                />
              </Grid>
            );
        }
      })}

      <Grid item xs={8} className="baseButton">
        <BaseCheckBox
          name="isWeeklyWeightResident"
          id="isWeeklyWeightResident"
          label="Is Weekly Weight Resident"
          control={control}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}
