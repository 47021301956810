import React from "react";
import { useGetResidentAllergyQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../shared/EditButton";
import DeleteButton from "../../../shared/DeleteButton";
import { BaseButton } from "components/shared/BaseButton";

export default function ResidentAllergy() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: Allergies,
    isLoading,
    isError,
  } = useGetResidentAllergyQuery(residentId, { skip: !residentId });

  const residentAllergies = Allergies;

  const allergyColumns = [
    {
      field: "allergyFoodName",
      minWidth: 50,
      flex: 1,
      headerName: "Food Allergy",
    },
    {
      field: "description",
      minWidth: 50,
      flex: 2,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 0.5,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addresidentAllergy?id=${residentId}`}
              data={Allergies}
              title="addresidentAllergy"
            />

            <DeleteButton
              index={params.row.id}
              entityName={params.row?.allergyFoodName}
              apiPath="deleteResidentAllergy"
            />
          </div>
        );
      },
    },
  ];

  return (
    <Box sx={{ padding: "5px", marginTop: "10px" }}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={"8px"}>
        <BaseButton
          text={"Add Allergy"}
          endIcon={"post_add"}
          To={`addresidentAllergy?id=${residentId}`}
          state={{
            name:
              Allergies?.resident?.firstName +
              " " +
              Allergies?.resident?.lastName,
          }}
          sx={{ marginLeft: "0px !important" }}
        />
      </Grid>

      <BaseDataGrid
        rows={residentAllergies}
        columns={allergyColumns}
        error={isError}
        loading={isLoading}
        autoHeight={false}
        height={{ md: "55vh", xl: "60vh" }}
      />
    </Box>
  );
}
