import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetFacilityTableSideQuery } from "../../../../../../store/apis/FacilityApis";
import { BaseButton } from "components/shared/BaseButton";
import { EditButton } from "components/shared/EditButton";

export default function CommunityTableSide() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data: tableSide } = useGetFacilityTableSideQuery(id, { skip: !id });

  const renderTableRow = (value, icon) => {
    return (
      <Grid className="tablesideRow">
        <Typography variant="p">{value}</Typography>
        {icon ? (
          <span
            style={{ marginLeft: "8px" }}
            className="material-icons tealColor">
            verified_user
          </span>
        ) : (
          <span
            style={{ marginLeft: "8px" }}
            className="material-icons redColor">
            gpp_bad
          </span>
        )}
      </Grid>
    );
  };

  return (
    <>
      <Grid paddingBottom={2}>
        <Grid
          display={"flex"}
          justifyContent={{ xs: "space-between", sm: "start" }}
          alignItems={"center"}>
          <BaseButton
            text={"Automatic Start Setup"}
            endIcon={"edit"}
            To={`automaticsetup?id=${id}`}
            colors={"error"}
            marginLeft={"0px !important"}
          />
          <EditButton To={`edit?id=${id}`} state={{ data: tableSide }} />
        </Grid>
      </Grid>

      <div>
        {renderTableRow(
          "Declining Balance",
          tableSide?.tableSideDecliningBalance
        )}
        {renderTableRow("Direct Printing", tableSide?.tablesideDirectPrint)}
        {renderTableRow(
          "Items Selected by Default",
          tableSide?.tablesideAllItemsSelectedByDefault
        )}
        {renderTableRow(
          "Hide portion on ticket",
          tableSide?.tablesideTicketHidePortion
        )}
        {renderTableRow(
          "Print MealCard Notes",
          tableSide?.tablesidePrintMealCardNotes
        )}
        {renderTableRow(
          "Print Signature Line on Receipt",
          tableSide?.tableSidePrintSignatureLine
        )}
        {renderTableRow(
          "Show Total Price on Receipt",
          tableSide?.enableTotalTicketPrice
        )}
        {renderTableRow(
          "Use OrderPrinter app",
          tableSide?.tablesideTicketUseOrderPrinter
        )}
      </div>
    </>
  );
}
