import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetFoodTypesQuery } from "../../../../../store/apis/FoodTypesApis";
import {
  Button,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";

export default function FoodTypes() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: foodTypesPagedData,
    isError: isError,
    isLoading: isLoading,
  } = useGetFoodTypesQuery({
    searchTerm: debouncedValue || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const foodTypes = foodTypesPagedData?.items || [];

  const EditFoodType = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addFoodType?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Food Types",
              data: foodTypes.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const DeleteFoodType = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteFoodType"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  function columnBoolean(param) {
    return (
      <>
        <Typography variant="p" width={40}>
          {param ? "Yes" : "No"}
        </Typography>
        {param ? (
          <Tooltip title="Yes">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons tealColor">
              verified_user
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="No">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons redColor">
              gpp_bad
            </span>
          </Tooltip>
        )}
      </>
    );
  }

  const foodTypesColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "isSnack",
      headerName: "Snack/Supplement",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return columnBoolean(params?.row?.isSnack);
      },
    },
    {
      field: "defaultUnitName",
      headerName: "Default Unit",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "isDislike",
      headerName: "Dislike",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return columnBoolean(params?.row?.isDislike);
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditFoodType index={params.row.id} />
            <DeleteFoodType
              id={params.row.id}
              name={params.row["name"] || ""}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
          />
          <Button
            component={Link}
            to={"addFoodType"}
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">post_add</span>}>
            Add New Food Type
          </Button>
        </div>
      </div>

      <BaseDataGrid
        rows={foodTypes}
        rowCount={foodTypesPagedData?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={foodTypesColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
