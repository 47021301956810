import { Button, FormControlLabel, IconButton } from "@mui/material";
import React, { useState } from "react";
import DeleteModal from "./deleteModal/DeleteModal";
import { useGetFoodTypeByIdQuery } from "../../store/apis/FoodTypesApis";
import { theme } from "styles/theme";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/DeleteButton.md)
 */
const DeleteButton = ({
  index,
  entityName,
  apiPath,
  text,
  variant,
  apiId,
  additionalText,
  FacilityId,
  disabled,
  hintText,
  To,
  sx,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const foodTypeName = useGetFoodTypeByIdQuery(apiId, { skip: !apiId }).data
    ?.name;

  return (
    <>
      <FormControlLabel
        title={hintText}
        sx={{ marginLeft: "6px", marginRight: "0px", ...sx }}
        control={
          text ? (
            <Button
              variant={variant || "outlined"}
              color="error"
              disabled={disabled}
              sx={{
                padding: "8px",
                textTransform: "none",
                borderRadius: "13px",
              }}
              onClick={() => {
                setOpenDeleteModal(true);
              }}
              endIcon={
                text == "Archive Recipe" ? null : (
                  <span className="material-icons">delete</span>
                )
              }>
              {text}
            </Button>
          ) : (
            <IconButton
              sx={{
                backgroundColor: disabled
                  ? theme.palette.gray.darkGray
                  : theme.palette.error.main,
                borderRadius: "5px",
                color: "white",
                padding: "5px",
                cursor: disabled ? "default" : "pointer",
                "&:hover": {
                  backgroundColor: disabled
                    ? theme.palette.gray.darkGray
                    : theme.palette.error.dark, // Change background color on hover
                  color: "white", // Ensure text/icon color remains white
                },
              }}
              aria-label={`Delete ${entityName}`}
              // disabled={disabled}
              color="error"
              onClick={() => {
                !disabled ? setOpenDeleteModal(true) : null;
              }}
              disableRipple>
              <span className="material-icons" style={{ color: "white" }}>
                delete
              </span>
            </IconButton>
          )
        }
      />

      {openDeleteModal && (
        <DeleteModal
          To={To}
          FacilityId={FacilityId}
          id={index}
          apiPath={apiPath}
          entityName={entityName ? entityName : foodTypeName}
          additionalText={additionalText}
          open={openDeleteModal}
          close={() => {
            setOpenDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default DeleteButton;
