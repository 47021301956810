import React from "react";
import { Tooltip, Typography } from "@mui/material";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/YesNoDisplay.md)
 */
export default function YesNoDisplay({ value }) {
  const text = value ? "Yes" : "No";
  return (
    <>
      <Typography variant="p" width={20}>
        {text}
      </Typography>
      <Tooltip title={text}>
        <span
          style={{ marginLeft: "8px" }}
          className={`material-icons ${value ? "tealColor" : "redColor"} `}>
          {value ? "verified_user" : "gpp_bad"}
        </span>
      </Tooltip>
    </>
  );
}
