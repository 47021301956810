import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BaseButton } from "../BaseButton";
import { Divider } from "@mui/material";

// Modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 1,
};

function BlockerModal({ open, onClose, text, onStay, onLeave }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBlock="10px">
          <Box>
            <span className="material-icons deleteIcon">report</span>
          </Box>{" "}
          <Box>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="error">
              {text}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" padding="10px" justifyContent="end">
          <BaseButton text="Stay" colors="warning" onClick={onStay} />
          <BaseButton text="Leave" colors="error" onClick={onLeave} />
        </Box>
      </Box>
    </Modal>
  );
}

export default BlockerModal;
