export const OptionsList = [
  {
    id: 1,
    optionName: "My Rosters",
    Link: "/Consultant/MyRosters",
    // isActive: false,
  },
  {
    id: 2,
    optionName: "Resources",
    Link: "/Consultant/Resources",
    // isActive: false,
  },
];
