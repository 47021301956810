import { Button } from "@mui/material";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import React, { useState } from "react";

export const RemoveButton = ({ name, id, apiPath }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setOpenModal(true);
        }}
        variant={"contained"}
        startIcon={<span className="material-icons">close</span>}
        color={"error"}
      />
      {openModal && (
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={apiPath}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};
