import React, { useEffect } from "react";
import {
  useGetDiningAreaByIdQuery,
  useAddDiningAreaMutation,
  useUpdateDiningAreaMutation,
  useGetDiningAreaListQuery,
} from "../../../../store/apis/ResidentApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Box, Grid, Typography, Card } from "@mui/material";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { useSnackbar } from "notistack";

import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../admin/admin.overlays";
import {
  MAX_LENGTH_VALIDATION,
  POSITIVE_INTEGER_NUMBER,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export default function AddDiningArea() {
  const facilityName = useSelector((state) => state.userInfo.facilityName);
  const { enqueueSnackbar } = useSnackbar();

  const [addDiningArea, { isError: addError, isLoading: addLoading }] =
    useAddDiningAreaMutation();

  const [updateDiningArea, { isError: updateError, isLoading: updateLoading }] =
    useUpdateDiningAreaMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;

  const [searchParams] = useSearchParams();
  const editId = searchParams.get("id");
  const facilityId = searchParams.get("facilityId");

  const {
    data: diningAreaList,
    isFetching: loading,
    // isError: hasError,
  } = useGetDiningAreaListQuery(
    {
      facilityId,
    },
    { refetchOnMountOrArgChange: true, skip: !facilityId }
  );

  const isEdit = !!editId;

  const {
    data: diningArea,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetDiningAreaByIdQuery(editId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching && loading;

  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && diningArea) {
      reset(diningArea);
    }
  }, [diningArea]);

  const onSubmit = (data) => {
    const nameExists = diningAreaList.some((item) => item.name == data.name);
    const sortOrderExists = diningAreaList.some(
      (item) => item.sortOrder == data.sortOrder
    );

    if (nameExists) {
      enqueueSnackbar(
        `Dining Area with the same name is already present in the list.`,
        { variant: "warning" }
      );
    } else if (sortOrderExists) {
      enqueueSnackbar(
        `Dining Area with the same sort order is already present in the list.`,
        { variant: "warning" }
      );
    } else {
      if (isEdit) {
        updateDiningArea(data);
      } else {
        data.facilityId = facilityId;
        addDiningArea(data);
      }
    }

    if (!isError) {
      navigate(-1);
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <Card>
            <Typography variant="h6" component="h2" margin={"20px"}>
              {`Dining Area for ${facilityName}`}
            </Typography>
            <Box margin={"20px"}>
              <FormProvider>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <BaseInput
                        label={"Dining Room Name"}
                        name={"name"}
                        placeholder="Dining Room Name"
                        control={control}
                        errors={errors}
                        validationProps={{
                          required: REQUIRED_ERROR("Dining Room Name"),
                          maxLength: MAX_LENGTH_VALIDATION(
                            "Dining Room Name",
                            50
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} />
                    <Grid item xs={3} />

                    <Grid item xs={5}>
                      <BaseInput
                        label={"Sort Order"}
                        name={"sortOrder"}
                        placeholder="Sort Order"
                        control={control}
                        errors={errors}
                        type={"number"}
                        validationProps={{
                          required: REQUIRED_ERROR("Sort Order"),
                          pattern: POSITIVE_INTEGER_NUMBER(),
                          maxLength: MAX_LENGTH_VALIDATION("Sort Order", 2),
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} />
                    <Grid item xs={3} />

                    <Grid item xs={5}>
                      <BaseCheckBox
                        name="includeInTableside"
                        id="includeInTableside"
                        label="Include in TableSide Program"
                        control={control}
                        errors={errors}
                        defaultValue={true}
                      />
                    </Grid>

                    <Grid item xs={3} />
                    <Grid item xs={3} />
                    <Grid item xs={5}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          pt: 2,
                        }}>
                        <BaseSubmitButton
                          fullWidth
                          onClick={handleSubmit(onSubmit)}
                          isSubmitting={isSubmitting}
                          text={isEdit ? "Submit" : "Create"}
                        />
                      </Box>
                      {isError ? (
                        <Typography color={"error"}>
                          There was an error submitting the form, please try
                          again.
                        </Typography>
                      ) : undefined}
                    </Grid>
                    <Grid item xs={3} />
                  </Grid>
                </form>
              </FormProvider>
            </Box>
          </Card>
        </>
      )}
    </>
  );
}
