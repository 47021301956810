import React, { useEffect, useState } from "react";
import {
  useGetCorporateBeverageByIdQuery,
  useAddCorporateBeverageMutation,
  useUpdateCorporateBeverageMutation,
  useGetCorporateBeverageQuery,
} from "../../../../../../../store/apis/CorporateGroupBeverageApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { useGetBeverageSelectBoxQuery } from "store/apis/BeverageApis";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BaseButton } from "components/shared/BaseButton";
import { useSnackbar } from "notistack";

export default function AddBeverageSelection() {
  const [validationSummary, setValidationSummary] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [
    addBeverage,
    {
      isError: addError,
      isLoading: addLoading,
      isSuccess: addSuccess,
      error: addErrorData,
    },
  ] = useAddCorporateBeverageMutation();

  const [
    updateBeverage,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      error: updateErrorData,
    },
  ] = useUpdateCorporateBeverageMutation();

  const isError = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const errorData = addErrorData || updateErrorData;
  const [searchParams] = useSearchParams();
  const corporateId = searchParams.get("corporateId");
  const BeverageId = searchParams.get("id");

  const isEdit = !!BeverageId;

  const {
    data: beverageData,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetCorporateBeverageByIdQuery(BeverageId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const { data: corporateGroupsBeverages } = useGetCorporateBeverageQuery(
    {
      corporateGroupId: corporateId,
      searchText: "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: selectBeverage, isLoading: loading } =
    useGetBeverageSelectBoxQuery();

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && beverageData) {
      reset(beverageData);
    }
  }, [beverageData]);

  if (isSuccess) {
    navigate(-1);
  }

  const onSubmit = (data) => {
    const isDataTrue = corporateGroupsBeverages?.some(
      (item) => item?.beverageId == data.beverageId
    );
    if (isDataTrue) {
      enqueueSnackbar(`This Beverage is already added, please add another`, {
        variant: "error",
      });
    } else if (!isDataTrue) {
      if (isEdit) {
        updateBeverage(data);
      } else {
        addBeverage({
          ...data,
          corporateGroupId: corporateId,
          beverageName: "",
          corporateGroupName: "",
        });
      }
    }

    if (isError) {
      setErrorsForProperties({
        responseError: errorData?.data,
        setError,
        setValidationSummary: setValidationSummary,
      });
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <Box>
            <BaseButton
              onClick={() => navigate(-1)}
              startIcon={
                <span className="material-icons">keyboard_arrow_left</span>
              }
              text={"Back to beverage list"}
            />
            <Box width={"40vw"} padding={2}>
              <Box marginY={1}>
                <BaseSelect
                  label="Choose Beverage"
                  name="beverageId"
                  id="beverageId"
                  control={control}
                  options={selectBeverage || []}
                  errors={errors}
                  loading={loading}
                  validationProps={{
                    required: REQUIRED_ERROR("Beverage"),
                  }}
                />
              </Box>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
                validationSummary={validationSummary}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
