import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFacilityEHRLiquidMappingQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";

export default function CommunityEHRLiquidMapping() {
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const currentId = searchParams.get("id");

  // const [searchParams] = useSearchParams();

  // const id = searchParams.get("id");

  const {
    data: ehrMapping,
    isLoading,
    isError,
  } = useFacilityEHRLiquidMappingQuery(
    { facilityId: currentId, searchTerm: debouncedSearchValue || "" },
    { skip: !currentId, refetchOnMountOrArgChange: true }
  );

  const eHRMappingColumns = [
    {
      field: "ehrthickenedLiquid",
      headerName: "EHR Liquid Name",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "thickenedLiquidName",
      headerName: "Liquid Consistency Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addcommunityehrliquidmapping?id=${currentId}&mappingId=${params.row.id}`}
              data={ehrMapping}
              title="Community EHR Liquid Mapping"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.ehrthickenedLiquid}
              apiPath="deletefacilityEHRLiquidMapping"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={{ xs: "space-between", sm: "flex-start" }}
        marginBottom={"8px"}>
        <BackButton text={"Back to EHR List"} />
        <BaseButton
          text={"Add EHR Diet Mapping"}
          endIcon={"playlist_add"}
          To={`addcommunityehrliquidmapping?id=${currentId}`}
        />
      </Grid>
      <Grid marginBottom={"8px"}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedSearchValue}
          setSearchParams={setSearchParams}
          isId={true}
          Id={currentId}
        />
      </Grid>

      <BaseDataGrid
        autoHeight={true}
        rows={ehrMapping}
        columns={eHRMappingColumns}
        error={isError}
        loading={isLoading}
        getRowHeight={() => "auto"}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}
