import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  useGetBaseMenusQuery,
  useAddBaseMenuMutation,
} from "../../../../../store/apis/BaseMenuApi";
import { Box, Button, FormControl, Input } from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import styles from "./BaseMenus.module.css";

export default function BaseMenus() {
  const [newBaseMenu, setNewBaseMenu] = useState("");

  const appInsights = useAppInsightsContext();
  const trackNewBaseMenu = useTrackEvent(appInsights, "New Base Menu Added", {
    menu: newBaseMenu,
    title: "Another test",
    number: 5,
  });
  const isAuthenticated = useIsAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [
    addBaseMenu,
    { isError: addError, isSuccess: addSuccess, isLoading: addLoading },
  ] = useAddBaseMenuMutation();
  const {
    data: baseMenus,
    isError: hasError,
    isLoading: loading,
  } = useGetBaseMenusQuery(searchValue);

  const isError = addError || hasError;
  const isSuccess = addSuccess;
  const isLoading = addLoading || loading;

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(`Base Menu ${newBaseMenu} ${"added"} successfully.`, {
        variant: "success",
      });
      setNewBaseMenu("");
    }
  }, [isSuccess]);

  useEffect(() => {
    setSearchParams({ ["search"]: searchValue });
  }, [searchValue]);

  const onSubmit = async (data) => {
    data["name"] = newBaseMenu;
    if (isAuthenticated) {
      await addBaseMenu({ name: newBaseMenu });
      trackNewBaseMenu();
    }
  };

  const BaseMenuDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <Box display={"flex"} justifyContent={"center"} spacing={2}>
          <Button onClick={() => setOpenModal(true)}>
            <span className="material-icons redColor">delete</span>
          </Button>
        </Box>
        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deleteBaseMenu"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const baseMenuColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <BaseMenuDelete id={params.row.id} name={params.row["name"]} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <FormControl>
            <BaseSearch setSearchValue={setSearchValue} value={searchValue} />
          </FormControl>
          <FormControl>
            <Input
              disableUnderline={true}
              type="text"
              className={styles.basicInput}
              value={newBaseMenu}
              placeholder="Enter new BaseMenu name"
              required={true}
              onChange={(event) => {
                setNewBaseMenu(event.target.value);
              }}
            />
          </FormControl>
          <Button
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">add</span>}
            onClick={() => {
              if (newBaseMenu.trim().length > 0) {
                onSubmit({ newBaseMenu });
              } else {
                // display message that Base menu name is required?
              }
            }}>
            Add
          </Button>
        </div>
      </div>

      <BaseDataGrid
        loading={isLoading}
        columns={baseMenuColumns}
        error={isError}
        rows={baseMenus}
      />
    </>
  );
}
