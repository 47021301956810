import { Box } from "@mui/material";
import React from "react";
import { OptionsDetails } from "../shared/OptionsDetails";
import { OptionsList } from "../consultant/headerDetails/headerConstant";
import BaseContent from "../shared/baseContent/BaseContent";

export const Consultant = () => {
  return (
    <BaseContent
      disableBack={true}
      disableHeight={true}
      headerText={"Consultant"}
      transparent={true}>
      <Box marginTop={"-32px"} marginLeft={"-15px"}>
        <OptionsDetails data={OptionsList} />
      </Box>
    </BaseContent>
  );
};
