import { REQUIRED_ERROR } from "./errorMessages";
import { oneToThreeDigitPositiveNumber } from "./ValidationRegex";

export const startingDayValidation = ({ endName }) => ({
  required: REQUIRED_ERROR("Starting Day"),
  pattern: {
    value: oneToThreeDigitPositiveNumber,
    message: "Please enter positive number less than 1000",
  },
  min: { value: 1, message: "Please enter positive number" },
  validate: (value, formValues) =>
    parseInt(value) > parseInt(formValues[endName])
      ? "Starting day should be less than ending day"
      : null,
});

export const endingDayValidation = ({ startName }) => ({
  required: REQUIRED_ERROR("Ending Day"),
  pattern: {
    value: oneToThreeDigitPositiveNumber,
    message: "Please enter positive number less than 1000",
  },
  min: { value: 1, message: "Please enter positive number" },
  validate: (value, formValues) =>
    parseInt(value) < parseInt(formValues[startName])
      ? "Ending day should be greater than starting day"
      : null,
});
