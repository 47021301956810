import React, { useState } from "react"; //{ useEffect }
import "../Ingredient.css";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useGetIngredientAllergiesQuery } from "../../../../../../store/apis/IngredientAPIs";
import DeleteModal from "../../../../../shared/deleteModal/DeleteModal";
import { Box, Typography, Button, Grid } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function IngredientAllergy() {
  const SEARCH_PARAM = "search";
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState("");
  const ingredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name" || "");

  const {
    data: allergyList,
    isError: isError,
    isFetching: isLoading,
  } = useGetIngredientAllergiesQuery(
    {
      ingredientId: ingredientId,
      searchTerm: debouncedValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const navigate = useNavigate();

  const AllergyDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <Box display={"flex"} justifyContent={"center"} spacing={2}>
          <Button onClick={() => setOpenModal(true)}>
            <span className="material-icons redColor">delete</span>
          </Button>
        </Box>
        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deleteIngredientAllergy"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const AllergyListColumns = [
    {
      field: "allergyFoodName",
      headerName: "Allergy",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <AllergyDelete
              id={params.row.id}
              name={params.row["allergyFoodName"]}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <BaseButton
            text="Back To Ingredients"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
            sx={{ marginBottom: "10px" }}
          />
          <Button
            component={Link}
            to={`addIngredientAllergy?ingredientId=${ingredientId}&name=${ingredientName}`}
            color={"primary"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">health_and_safety</span>}
            sx={{ marginBottom: "25px", marginLeft: "20px" }}>
            Add Allergy
          </Button>
        </Grid>
      </Grid>
      <Typography color="primary" variant="h5" className="userFacilityTitle">
        Allergies for {ingredientName}
      </Typography>

      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isNameID={true}
            name={ingredientName}
            Id={ingredientId}
          />
        </div>
      </div>

      <BaseDataGrid
        rows={allergyList}
        columns={AllergyListColumns}
        loading={isLoading}
        error={isError}
      />
    </>
  );
}
