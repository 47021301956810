import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Modal,
  Typography,
  Menu,
  MenuItem,
  Card,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Menus.css";
import {
  Link,
  useOutletContext,
  useNavigate,
  useLocation,
} from "react-router-dom";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import {
  useCopyMenuMutation,
  useGetMenuListQuery,
  useSignMenuMutation,
} from "../../../store/apis/MenuApis";
import BaseSearch from "../../shared/baseSearch/BaseSearch";

import BaseInput from "../../shared/baseForm/BaseInput";
import { useSeasonSelectQuery } from "../../../store/apis/PrintReportApis";
import { useGetFacilityByIdQuery } from "../../../store/apis/FacilityApis";

import { Labels, MenuOptionsList, MenuOptionsList2 } from "./Menu.constant";
import { useForm } from "react-hook-form";
import { EditButton } from "../../shared/EditButton";
import YesNoDisplay from "../../shared/YesNoDisplay";
import BaseContent from "../../shared/baseContent/BaseContent";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { Roles, SCREEN_CONSTANTS } from "../../shared/constants";
import PlatefulTabs from "../PlatefulTabs";
import { useSnackbar } from "notistack";
import Close from "@mui/icons-material/Close";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import dayjs from "dayjs";
import useMsalAccount from "utils/useMsalAccount";
import { useLocalStorage } from "../../../utils/useLocalStorage";

export default function Menus() {
  const { state } = useLocation();
  const { roles: userRoles } = useMsalAccount();
  const [searchValue, setSearchValue] = useState(state?.search || "");
  const [debouncedValue, setDebouncedValue] = useState(
    state?.search || Labels.EMPTY_STRING
  );
  const [season, setSeason] = useState(state?.season || Labels.EMPTY_STRING);
  const [localSeason, setLocalSeason] = useLocalStorage("season", season);

  const { enqueueSnackbar } = useSnackbar();

  const [modalToOpenData, setModalToOpenData] = useState("");

  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openSignMenuModal, setOpenSignMenuModal] = useState(false);
  const [openModalId, setOpenModalId] = useState();
  const [openModalName, setOpenModalName] = useState("");
  const [defaultOption, setDefaultOption] = useState({});

  const isAdmin = () => roleCheck(userRoles, [Roles.Admin, Roles.Menu_Team]);
  const isAdvancedUser = () => roleCheck(userRoles, [Roles.Advanced_User]);

  const {
    data: seasonList,
    isFetching: seasonLoading,
    isSuccess: seasonSuccess,
  } = useSeasonSelectQuery({ showArchived: isAdmin() });

  const currentUserData = useOutletContext();
  const navigate = useNavigate();

  const facilityId = useSelector(selectFacilityId);

  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  useEffect(() => {
    if (modalToOpenData) {
      switch (modalToOpenData) {
        case "Copy":
          setOpenCopyModal(true);
          break;
        case "Sign Menu":
          setOpenSignMenuModal(true);
          break;
      }
    }
  }, [modalToOpenData]);

  useEffect(() => {
    if (openSignMenuModal && !currentUserData?.dietitianCredentials) {
      navigate("/mycredentials");
    }
  }, [openSignMenuModal]);

  useEffect(() => {
    if (seasonSuccess && !seasonLoading) {
      const idx = seasonList.findIndex((s) => s.defaultSeason === true);
      const defaultOption =
        idx > -1
          ? { id: seasonList[idx]?.id, label: seasonList[idx]?.label }
          : "";

      setDefaultOption(defaultOption);
      setSeason(localSeason || defaultOption?.id);
    }
  }, [seasonSuccess, seasonLoading]);

  useEffect(() => {
    if (season) {
      setLocalSeason(season);
    }
  }, [season]);

  const {
    data: MenusData,
    isFetching: loading,
    isError: hasError,
    error,
    isSuccess,
    refetch: refetchMenus,
  } = useGetMenuListQuery(
    {
      searchTerm: debouncedValue,
      seasonId: season || defaultOption,
      facilityId: facilityId,
    },
    {
      skip: !season || !facilityId || defaultOption == {},
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (hasError) {
      enqueueSnackbar(
        `${error?.status}: ${
          error?.data ? JSON.stringify(error.data) : "An error occurred."
        }`,
        { variant: "error" }
      );
    } else {
      if (
        MenusData &&
        MenusData?.length < 1 &&
        isSuccess &&
        debouncedValue?.length > 0
      ) {
        enqueueSnackbar(`No menus found for ${debouncedValue}`, {
          variant: "error",
        });
      }
    }
  }, [hasError, MenusData]);

  const menuColumns = [
    {
      field: "menuName",
      minWidth: 100,
      flex: 3,
      headerName: "Name",
      renderCell: (params) => {
        return (
          <div>
            <Typography fontSize={14} fontWeight={600}>
              {params.row.menuName}
            </Typography>
          </div>
        );
      },
    },
    // {
    //   field: "title",
    //   minWidth: 100,
    //   flex: 4,
    //   headerName: "Title",
    // },
    {
      field: "lastEdited",
      minWidth: 100,
      flex: 2,
      headerName: "Last Edited",
      renderCell: (params) => {
        return (
          <div>
            <Typography fontSize={14} fontWeight={600}>
              {params.row.updatedBy}
            </Typography>
            <Typography fontSize={12}>
              {params.row.lastUpdated
                ? dayjs(params.row.lastUpdated).format(
                    SCREEN_CONSTANTS.Date_Format
                  )
                : ""}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "htiMenu",
      maxWidth: 90,
      flex: 1,
      headerName: "Locked",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.htiMenu} />;
      },
    },
    {
      field: "dateSigned",
      maxWidth: 95,
      flex: 2,
      headerName: "Date Signed",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            <Typography fontSize={14} fontWeight={600}>
              {params.row.signedByName}
            </Typography>
            <Typography fontSize={12}>
              {params.row.dateSigned
                ? dayjs(params.row.dateSigned).format(
                    SCREEN_CONSTANTS.Date_Format
                  )
                : ""}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 2,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="menuActionButtons" style={{ cursor: "pointer" }}>
            {(!params.row?.htiMenu && isAdvancedUser()) || isAdmin() ? (
              <>
                {/* <EditButton
                  index={params.row.id}
                  To={`MenuPlanner?menuId=${params.row.id}`}
                  data={MenusData}
                  title="Menu"
                  icon="edit_calendar"
                /> */}
                <Button
                  component={Link}
                  color={"primary"}
                  to={`MenuPlanner?menuId=${params.row.id}`}
                  state={{
                    title: params.row.menuName,
                    data: params.row,
                    search: searchValue,
                    season: season,
                  }}
                  className="menuPlannerButton"
                  variant="contained"
                  endIcon={
                    <span className="material-icons">edit_calendar</span>
                  }>
                  Menu Planner
                </Button>
              </>
            ) : null}

            <EditButton
              index={params.row.id}
              To={`print?id=${params.row.id}`}
              state={{
                search: searchValue,
                season: season,
              }}
              data={MenusData}
              title={params.row.menuName}
              icon="print"
              prefix=""
            />

            {/* <Button
              component={Link}
              color={"warning"}
              to={`print?id=${params.row.id}`}
              state={{
                title: params.row.menuName,
                data: params.row,
              }}
              className="menuPlannerButton"
              variant="contained"
              // endIcon={<span className="material-icons">print</span>}
              >
                <span className="material-icons">print</span>
            </Button> */}

            <MenuOptionsButton
              idName="menuOptionsButton"
              index={params.row.id}
              setModalToOpen={setModalToOpenData}
              setOpenModalId={setOpenModalId}
              setOpenModalName={setOpenModalName}
              name={params.row?.menuName}
              List={MenuOptionsList}
              List2={MenuOptionsList2}
              query="?menuId="
              isHTIMenu={params.row?.htiMenu}
              orderTransmissionEnabled={
                currentFacility?.distributorId > 0 &&
                currentFacility?.distributorSpecificIdentifier?.length > 0
              }
              state={{
                search: searchValue,
                season: season,
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <BaseContent
      disableBack={true}
      disableHeight={true}
      headerText="PlateFul"
      transparent={true}>
      <PlatefulTabs />
      <Card
        sx={{
          paddingLeft: "5px",
          borderTopLeftRadius: "0px !important",
          borderTopRightRadius: "0px !important",
        }}>
        <Box
          paddingY={"8px"}
          height={{ md: "82.4dvh", xl: "84.7dvh" }}
          overflow={"auto"}>
          {isAdmin() ? (
            <Button
              component={Link}
              to="AddMenus"
              color={"primary"}
              sx={{ marginLeft: "0 !important" }}
              className="baseButton"
              variant="contained"
              endIcon={<span className="material-icons">receipt</span>}>
              New Menu
            </Button>
          ) : null}
          <Box
            display={"flex"}
            marginY={"8px"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"start"}>
            <BaseSearch
              sx={{ width: "500px" }}
              placeholder="Search..."
              disableUnderline={true}
              type="text"
              value={searchValue}
              setSearchValue={setSearchValue}
              setDebouncedValue={setDebouncedValue}
            />
            <Select
              style={{ margin: "0px 5px" }}
              id="seasonId"
              value={season}
              className="selectInputCategory"
              onChange={(event) => {
                setSeason(event.target.value);
              }}
              size="small"
              displayEmpty>
              {seasonList?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {openCopyModal && (
            <CopyModal
              open={openCopyModal}
              close={() => {
                setOpenCopyModal(false);
                setModalToOpenData("");
                refetchMenus();
              }}
              id={openModalId}
              menuName={openModalName}
              facilityId={facilityId}
            />
          )}
          {openSignMenuModal && currentUserData?.dietitianCredentials && (
            <SignMenuModal
              open={openSignMenuModal}
              close={() => {
                setOpenSignMenuModal(false);
                setModalToOpenData("");
              }}
              id={openModalId}
              menuName={openModalName}
              currentUserData={currentUserData}
            />
          )}
          <BaseDataGrid
            rows={MenusData}
            // Added Season Loading in this so when the seasons are loading on initial load it shows loading instead of no data.
            loading={loading || seasonLoading}
            columns={menuColumns}
            error={hasError}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "55vh",
              lg: "60vh",
              xl: "65vh",
            }}
            width={"99%"}
            autoHeight={false}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        </Box>
      </Card>
    </BaseContent>
  );
}

function CopyModal({ id, menuName, facilityId, open, close }) {
  const [menuId] = useState(id);
  const [
    copyMenu,
    {
      data: copyMenuData,
      isLoading: copyMenuLoading,
      isSuccess: copyMenuSuccess,
    },
  ] = useCopyMenuMutation({
    refetchOnMountOrArgChange: true,
  });

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    data.id = menuId;
    data.currentFacilityId = facilityId;
    copyMenu(data);
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}>
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box display="flex" justifyContent="flex-end">
            <Box padding="10px">
              <Close size="small" cursor="pointer" onClick={close} />
            </Box>
          </Box>
          <Box className="modalContent">
            <p>
              <span className="material-icons modalIcon" color={"warning"}>
                report
              </span>
            </p>
            <Box sx={{ width: "60%" }}>
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h5"
                component="h1"
                sx={{ textAlign: "center" }}
                className="modalTitle">
                Copy Menu
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{ mt: 2, textAlign: "center" }}>
                {copyMenuData ? (
                  <>
                    <Typography
                      id={`copy-to-corporate-group-modal-description-${id}`}
                      sx={{ mt: 2, textAlign: "center" }}>
                      This menu has been Copied
                    </Typography>
                    <Typography
                      id={`copy-to-corporate-group-modal-description-${id}`}
                      sx={{ mt: 2, textAlign: "center" }}>
                      {` The name of the new menu is  ${
                        !copyMenuData?.menuName ? "" : copyMenuData?.menuName
                      }.`}
                    </Typography>
                  </>
                ) : (
                  <BaseInput
                    label={"New Menu Name"}
                    name={"newMenuName"}
                    defaultValue={`Copy of ${menuName}`}
                    control={control}
                    errors={errors}
                  />
                )}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 0.5 }} />
          <Box className="deleteActions" flex={true}>
            <Button
              sx={{ marginLeft: "10px" }}
              className="baseButton"
              variant="contained"
              color={"error"}
              onClick={copyMenuSuccess ? close : handleSubmit(onSubmit)}
              startIcon={
                copyMenuLoading ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }>
              {copyMenuSuccess ? "Ok" : "Copy"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

function SignMenuModal({ id, menuName, open, close, currentUserData }) {
  const [signMenu, { isLoading: signMenuDataLoading }] = useSignMenuMutation();

  const [menuId] = useState(id);

  const onSubmit = () => {
    // console.log(currentUserData.dietitianCredentials);
    signMenu({
      menuId: menuId,
      signedByName: currentUserData.dietitianName,
      signedByCredentials: currentUserData.dietitianCredentials,
    });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}>
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box className="modalContent">
            <p>
              <span className="material-icons modalIcon" color={"warning"}>
                report
              </span>
            </p>
            <Box sx={{ width: "60%" }}>
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h5"
                component="h1"
                sx={{ textAlign: "center" }}
                className="modalTitle">
                {`Signing Menu ${menuName}`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  mt: 2,
                  textAlign: "left",
                  color: "var(--tealColor)",
                  fontWeight: "bold",
                }}>
                The menu will be signed with the following credentials:
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  mt: 2,
                  textAlign: "left",
                }}>
                {`Signed By Name: ${currentUserData.dietitianName}`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  mt: 2,
                  textAlign: "left",
                }}>
                {`Credentials: ${currentUserData.dietitianCredentials}`}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 0.5 }} />
          <Box className="deleteActions" flex={true}>
            <Button
              sx={{ marginLeft: "10px" }}
              className="baseButton"
              variant="contained"
              color={"warning"}
              onClick={() => {
                onSubmit();
                close();
              }}
              startIcon={
                signMenuDataLoading ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }>
              Sign Menu
            </Button>
            <Button
              sx={{ marginLeft: "10px" }}
              className="baseButton"
              variant="contained"
              color={"error"}
              onClick={close}>
              No
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

function roleCheck(userRoles, roles) {
  const isInRole = userRoles.some((roleToCheck) => roles.includes(roleToCheck));
  return isInRole;
}

export const MenuOptionsButton = ({
  index,
  name,
  setModalToOpen,
  setOpenModalName,
  setOpenModalId,
  List,
  List2,
  query,
  idName,
  isHTIMenu,
  orderTransmissionEnabled,
  state,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const { roles: userRoles } = useMsalAccount();

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // menu permissions
  const isAdmin = () => roleCheck(userRoles, [Roles.Admin, Roles.Menu_Team]);
  const isAdvancedUser = () => roleCheck(userRoles, [Roles.Advanced_User]);
  const isMenuSigningUser = () => roleCheck(userRoles, [Roles.Menu_Signing]);
  const isShareMenuUser = () => roleCheck(userRoles, [Roles.Menu_Sharing]);

  const notHTIAndAdvancedUser = () => !isHTIMenu && isAdvancedUser();

  const canShareMenu = () =>
    (!isHTIMenu && (isAdvancedUser() || isShareMenuUser())) || isAdmin();
  const canSignMenu = () => isMenuSigningUser() && isHTIMenu == isAdmin();

  return (
    <>
      <Link
        id={`${idName}-` + index}
        aria-controls={open ? "menu-" + index : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className="plateMenuButton"
        to={"/"}
        onClick={handleClick}>
        <span style={{ fontSize: "25px" }} className="material-icons">
          more_vert
        </span>
      </Link>

      <Menu
        id={idName + index}
        MenuListProps={{
          "aria-labelledby": idName + "-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {notHTIAndAdvancedUser() || isAdmin()
          ? List?.map((options) => (
              <Link
                key={options.id}
                state={{ title: name, ...state }}
                to={options?.Link + query + index}
                style={{ textDecoration: "none", color: "black" }}>
                <MenuItem disableRipple>{options.optionName}</MenuItem>
              </Link>
            ))
          : null}

        {notHTIAndAdvancedUser() ? (
          <Link
            key={14}
            state={{ title: name, ...state }}
            to={"/plateFul/Menus/copyOtherMenuItems" + query + index}
            style={{ textDecoration: "none", color: "black" }}>
            <MenuItem disableRipple>{"Copy Other Menu Items"}</MenuItem>
          </Link>
        ) : null}

        {isAdmin()
          ? List2?.map((options) => (
              <Link
                key={options.id}
                state={{ title: name, ...state }}
                to={options?.Link + query + index}
                style={{ textDecoration: "none", color: "black" }}>
                <MenuItem disableRipple>{options.optionName}</MenuItem>
              </Link>
            ))
          : null}

        {isAdvancedUser() ? (
          <Link
            key={3}
            state={{ title: name, ...state }}
            // to={""}
            onClick={() => {
              setOpenModalName(name);
              setOpenModalId(index);
              setModalToOpen("Copy");
              handleClose();
            }}
            style={{ textDecoration: "none", color: "black" }}>
            <MenuItem disableRipple>{"Copy"}</MenuItem>
          </Link>
        ) : null}

        {isAdvancedUser() || isAdmin() ? (
          <Link
            key={11}
            state={{ title: name, ...state }}
            to={`/plateFul/Menus/CustomizePostedMenu?menuId=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            <MenuItem disableRipple>{"Customize Posted Menu"}</MenuItem>
          </Link>
        ) : null}

        {canShareMenu() ? (
          <Link
            key={6}
            state={{ title: name, ...state }}
            to={`/plateFul/Menus/ShareMenu?menuId=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            <MenuItem disableRipple>{"Share Menu"}</MenuItem>
          </Link>
        ) : null}

        {canSignMenu() ? (
          <Link
            key={8}
            state={{ title: name, ...state }}
            // to={""}
            onClick={() => {
              setOpenModalName(name);
              setOpenModalId(index);
              setModalToOpen("Sign Menu");
              handleClose();
            }}
            style={{ textDecoration: "none", color: "black" }}>
            <MenuItem disableRipple>{"Sign Menu"}</MenuItem>
          </Link>
        ) : null}

        {orderTransmissionEnabled ? (
          <Link
            key={13}
            state={{ title: name, ...state }}
            to={`/plateFul/Menus/orderTransmission?menuId=${index}`}
            style={{ textDecoration: "none", color: "black" }}>
            <MenuItem disableRipple>{"Order Transmission"}</MenuItem>
          </Link>
        ) : null}

        <Link
          key={10}
          state={{ title: name, ...state }}
          to={`/plateFul/Menus/recipeReprint?menuId=${index}`}
          style={{ textDecoration: "none", color: "black" }}>
          <MenuItem disableRipple>{"Recipe Reprint List"}</MenuItem>
        </Link>

        {(!isHTIMenu && isAdvancedUser()) || isAdmin() ? (
          <MenuItem
            onClick={() => {
              setOpenModal(true);
              handleClose();
            }}
            className="menuDeleteButton"
            disableRipple>
            Delete Menu
          </MenuItem>
        ) : null}
      </Menu>
      {openModal === true ? (
        <DeleteModal
          id={index}
          entityName={name}
          apiPath={"deleteMenu"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
