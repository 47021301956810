import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import {
  useGetDiningAreaByIdQuery,
  useGetDiningAreaTableByIdQuery,
  useGetResidentDetailQuery,
  useMoveResidentToReserveMutation,
  useAddResidentPhotoMutation,
  useDeleteResidentPhotoMutation,
} from "../../../store/apis/ResidentApis";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./ResidentDetails.css";
import { useGetFacilityDietsQuery } from "../../../store/apis/DietsApi";
import { ApiFetchImage } from "../../../utils/ApiCall";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { BaseButton } from "components/shared/BaseButton";
import profileImg from "../../../assets/imgs/profile_img.svg";
import mealCardIcon from "../../../assets/imgs/meal_card_check.svg";
import uploadIcon from "../../../assets/imgs/upload_icon.svg";
import deleteIcon from "../../../assets/imgs/delete_icon.svg";

export default function ResidentDetails() {
  const facilityId = useSelector(selectFacilityId);
  const [searchParams] = useSearchParams();
  const [residentId] = useState(searchParams.get("id"));
  const [resident, setResident] = useState({});
  const [decliningBalance, setDecliningBalance] = useState(false);
  const [photoEnabled, setPhotoEnabled] = useState(false);
  const [photo, setPhoto] = useState();

  const navigate = useNavigate();

  const [
    updatePhoto,
    { isSuccess: isPhotoUpdateSuccess, isLoading: isPhotoUpdateLoading },
  ] = useAddResidentPhotoMutation();

  const [
    deletePhoto,
    { isSuccess: isPhotoDeleteSuccess, isLoading: IsPhotoDeleteLoading },
  ] = useDeleteResidentPhotoMutation();

  const isPhotoSuccess = isPhotoUpdateSuccess || isPhotoDeleteSuccess;
  const isPhotoLoading = isPhotoUpdateLoading || IsPhotoDeleteLoading;

  const { data: residentDetails, isError: isError } = useGetResidentDetailQuery(
    {
      residentId: residentId,
      facilityId: facilityId,
    },
    {
      skip: !residentId || !facilityId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    moveToReserved,
    { isSuccess: isMoveToReserveSuccess, isLoading: isMoveLoading },
  ] = useMoveResidentToReserveMutation();

  const { data: facilityDiets } = useGetFacilityDietsQuery(
    {
      facilityId: facilityId,
      isTexture: false,
    },
    {
      skip: !facilityId,
    }
  );

  const { data: facilityTextures } = useGetFacilityDietsQuery(
    {
      facilityId: facilityId,
      isTexture: true,
    },
    {
      skip: !facilityId,
    }
  );

  const { data: breakfastDiningArea } = useGetDiningAreaByIdQuery(
    resident.breakfastDiningAreaId,
    { skip: !resident.breakfastDiningAreaId }
  );

  const { data: lunchDiningArea } = useGetDiningAreaByIdQuery(
    resident.lunchDiningAreaId,
    { skip: !resident.lunchDiningAreaId }
  );

  const { data: supperDiningArea } = useGetDiningAreaByIdQuery(
    resident.supperDiningAreaId,
    { skip: !resident.supperDiningAreaId }
  );

  const { data: breakfastTable } = useGetDiningAreaTableByIdQuery(
    resident.breakfastTableId,
    { skip: !resident.breakfastTableId }
  );

  const { data: lunchTable } = useGetDiningAreaTableByIdQuery(
    resident.lunchTableId,
    { skip: !resident.lunchTableId }
  );

  const { data: supperTable } = useGetDiningAreaTableByIdQuery(
    resident.supperTableId,
    { skip: !resident.supperTableId }
  );

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    if (residentDetails) {
      setResident(residentDetails.resident);
      setDecliningBalance(residentDetails.decliningBalanceEnabled);
      setPhotoEnabled(residentDetails.photoOnTrayCard);
    }
  }, [residentDetails]);

  useEffect(() => {
    if (isError) {
      navigate("/MealCard");
    }
  }, [isError]);

  function MoveResidentToReserved() {
    moveToReserved(residentId);
  }

  useEffect(() => {
    if (isMoveToReserveSuccess && !isMoveLoading) {
      navigate("/MealCard");
    }
  }, [isMoveToReserveSuccess, isMoveLoading]);

  const fetchPhoto = () => {
    ApiFetchImage(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BASE_URL}/Residents/ResidentPhoto?residentId=${residentId}`
    )
      .then((response) => {
        if (response) {
          let reader = new FileReader();

          reader.onload = function () {
            const image = reader.result;

            // console.log(image);

            setPhoto(image);
          };

          reader.readAsDataURL(response);
        }
      })
      .catch((error) => {
        console.error(error);
        setPhoto(null);
      });
  };

  useMemo(() => {
    if (photoEnabled) {
      fetchPhoto();
    }
  }, [photoEnabled]);

  useEffect(() => {
    if (isPhotoSuccess && !isPhotoLoading) {
      fetchPhoto();
    }
  }, [isPhotoSuccess, isPhotoLoading]);

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("residentId", residentId);
      formData.append("file", file);

      updatePhoto(formData);
    }
  };

  const handleDeletePhoto = async () => {
    await deletePhoto(residentId);
    setPhoto(null);
  };

  return (
    <>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={4} mt={2}>
            <BaseButton
              onClick={() => MoveResidentToReserved()}
              text={" Move to Reserve"}
              endIcon={"assignment"}
              sx={{
                backgroundColor: "var(--tealColor)",
              }}
            />
          </Grid>
          <Grid item xs={8} mt={2} container justifyContent="flex-end">
            <BaseButton
              text={"Change History"}
              To={`/MealCard/residentChangeHistory?id=${residentId}`}
              endIcon={"edit_note"}
              sx={{
                backgroundColor: "var(--tealColor)",
              }}
            />
          </Grid>

          <Grid
            container
            mt={2}
            sx={{
              borderRadius: "4px",
              marginLeft: "12px !important",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "var(--tealColor)",
                borderRadius: "4px 4px 0 0",
              }}>
              <Box
                display="flex"
                justifyContent="space-between"
                padding="8px 16px 0px 16px">
                <Box display="flex" gap="0.8rem" padding="8px 16px">
                  <Box
                    component="img"
                    alt="Profile Image"
                    src={photo ? photo : profileImg}
                    sx={{
                      border: "1px solid white",
                      borderRadius: "4px !important",
                      padding: "0.1rem",
                      width: "6.25rem",
                      height: "6.25rem",
                      objectFit: "contain",
                    }}></Box>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" flexDirection="column">
                      <Typography
                        component="span"
                        textTransform="uppercase"
                        fontSize={28}
                        fontWeight={800}
                        sx={{ color: "white !important" }}>
                        {resident.firstName} {resident.lastName}
                      </Typography>
                      <Typography
                        component="span"
                        fontWeight={600}
                        sx={{ color: "white !important" }}>
                        Preferred Name: {resident.nickname}
                      </Typography>
                      <Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "white !important",
                            lineHeight: "24.55px",
                            marginTop: "0.6rem",
                          }}>
                          Print Meal Card:
                          {resident.printTrayCard ? (
                            <Box
                              component="img"
                              alt="Default Profile Image"
                              src={mealCardIcon}></Box>
                          ) : (
                            <span
                              style={{ margin: "5px" }}
                              className="material-icons redColor">
                              gpp_bad
                            </span>
                          )}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="start" paddingTop="0.6rem">
                  <Link
                    to={`/MealCard/addResident?id=${residentId}`}
                    className="newEditButton">
                    <Typography component="span" fontWeight={700}>
                      Edit
                    </Typography>
                  </Link>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                paddingLeft="1.03rem"
                gap="0.2rem">
                {photoEnabled ? (
                  <Button
                    component="label"
                    sx={{
                      height: "30px",
                    }}>
                    <input
                      type="file"
                      accept=".png, .jpg, ,.jpeg, .bmp"
                      hidden
                      onChange={handleFileUpload}
                    />
                    <Tooltip title="Click to upload a resident profile picture">
                      <Box
                        component="img"
                        alt="Default Profile Image"
                        sx={{
                          border: "1px solid white",
                          padding: "0.3rem 0.4rem",
                          borderRadius: "6px",
                        }}
                        src={uploadIcon}></Box>
                    </Tooltip>
                  </Button>
                ) : null}

                {photo ? (
                  <Box
                    component="img"
                    size="small"
                    sx={{
                      border: "1px solid white",
                      padding: "0.3rem 0.5rem",
                      borderRadius: "6px",
                      cursor: "pointer",
                      marginLeft: "-4px !important",
                    }}
                    onClick={handleDeletePhoto}
                    src={deleteIcon}></Box>
                ) : null}
              </Box>

              <Grid item xs={12} mt={2}>
                <Box display="flex" flexDirection="column" padding="0 1rem">
                  <Grid
                    container
                    spacing={1}
                    padding="0 16px 8px 16px"
                    display="flex"
                    justifyContent="space-between">
                    <Grid
                      item
                      xs={6}
                      sm={2.4}
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      flexGrow={1}>
                      <Typography
                        fontWeight={800}
                        sx={{ color: "white !important", fontSize: "20px" }}
                        component="span">
                        Room Number:
                      </Typography>
                      <Typography
                        sx={{ color: "white !important", fontSize: "20px" }}>
                        {resident.roomNumber}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={2.4}
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      flexGrow={1}>
                      <Typography
                        fontWeight={800}
                        sx={{ color: "white !important", fontSize: "20px" }}
                        component="span">
                        Bed:
                      </Typography>
                      <Typography
                        sx={{ color: "white !important", fontSize: "20px" }}>
                        {resident.bed}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={2.4}
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      flexGrow={1}>
                      <Typography
                        fontWeight={800}
                        sx={{ color: "white !important", fontSize: "20px" }}
                        component="span">
                        Wing:
                      </Typography>
                      <Typography
                        sx={{ color: "white !important", fontSize: "20px" }}>
                        {resident.wing}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={2.4}
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      flexGrow={1}>
                      <Typography
                        fontWeight={800}
                        sx={{ color: "white !important", fontSize: "20px" }}
                        component="span">
                        Building:
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ color: "white !important", fontSize: "20px" }}>
                        {resident.building}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={2.4}
                      display="flex"
                      alignItems="center"
                      gap="0.5rem"
                      flexGrow={1}>
                      <Typography
                        fontWeight={800}
                        sx={{ color: "white !important", fontSize: "20px" }}
                        component="span">
                        EHR Identifier:
                      </Typography>
                      <Typography
                        component="span"
                        sx={{ color: "white !important", fontSize: "20px" }}>
                        {resident.ahtpatientId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              display="flex"
              sx={{ padding: "0.5rem 1rem" }}
              justifyContent="space-between">
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  DOB:
                </Typography>
                <Typography>
                  {resident.dateOfBirth
                    ? dayjs(resident.dateOfBirth).format(
                        SCREEN_CONSTANTS.Date_Format
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Isolation:
                </Typography>
                <Typography>{resident.isIsolation ? "Yes" : "No"}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Room Tray:
                </Typography>
                <Typography>{resident.isRoomTray ? "Yes" : "No"}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Unlimited Meals
                </Typography>
                <Typography component="span">
                  {resident.hasUnlimitedMeals ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Care Plan Due Date:
                </Typography>
                <Typography component="span">
                  {resident.carePlanDueDate
                    ? dayjs(resident.carePlanDueDate).format(
                        SCREEN_CONSTANTS.Date_Format
                      )
                    : ""}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              display="flex"
              sx={{ padding: "0rem 1rem 0rem 1rem" }}
              justifyContent="space-between">
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Sex:
                </Typography>
                <Typography>{resident.gender}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Physician:
                </Typography>
                <Typography>{resident.physician}</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Admission Date:
                </Typography>
                <Typography>
                  {resident.admissionDate
                    ? dayjs(resident.admissionDate).format(
                        SCREEN_CONSTANTS.Date_Format
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Assessment Due Date:
                </Typography>
                <Typography component="span">
                  {resident.assessmentDueDate
                    ? dayjs(resident.assessmentDueDate).format(
                        SCREEN_CONSTANTS.Date_Format
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                gap="0.5rem"
                flexGrow={1}>
                <Typography fontWeight={700} component="span">
                  Resident Id:
                </Typography>
                <Typography component="span">
                  {resident.residentIdNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            boxShadow="0px 4px 4px 0px #00000040"
            sx={{
              borderRadius: "4px",
            }}>
            <Box display="flex" flexDirection="column">
              <Box
                backgroundColor="#F8D061"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="8px 16px"
                marginLeft="4px !important"
                sx={{ borderRadius: "4px 4px 0px 0px" }}>
                <span style={{ fontSize: "22px", fontWeight: "700" }}>
                  Diet Information
                </span>
                <Link
                  to={`/MealCard/addResident?id=${residentId}&step=2`}
                  className="newEditButton">
                  <Typography component="span" fontWeight={700}>
                    Edit
                  </Typography>
                </Link>
              </Box>

              <Grid
                container
                spacing={1}
                sx={{ padding: "0.5rem 1rem" }}
                display="flex"
                justifyContent="space-between">
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Diet:
                  </Typography>
                  <Typography>
                    {facilityDiets
                      ? facilityDiets.find(
                          (diet) => diet.dietId === resident.dietId
                        )?.dietName
                      : ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Diet Texture:
                  </Typography>
                  <Typography>
                    {facilityTextures
                      ? facilityTextures.find(
                          (texture) => texture.dietId === resident.dietTextureId
                        )?.dietName
                      : ""}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Diet Other:
                  </Typography>
                  <Typography>{resident.dietOther}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Liquid Consistency:
                  </Typography>
                  <Typography>
                    {residentDetails ? residentDetails.liquidConsistency : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                sx={{ padding: "0rem 1rem 0rem 1rem" }}
                display="flex"
                justifyContent="space-between">
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.7rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    NPO:
                  </Typography>
                  <Typography>{resident.isNpo ? "Yes" : "No"}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.7rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Tube Feeding:
                  </Typography>
                  <Typography>
                    {resident.isTubeFeeding ? "Yes" : "No"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.7rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Fluid Restriction:
                  </Typography>
                  <Typography>
                    {resident.hasFluidRestrict ? "Yes" : "No"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2.4}
                  display="flex"
                  gap="0.7rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Fluid Restriction Quantity:
                  </Typography>
                  <Typography>{resident.restrictQty}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "10px 0px",
            }}>
            <Box
              backgroundColor="#C4E1E2"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderRadius: "4px 4px 0 0",
              }}
              padding="8px 16px">
              <span className="residentTitle" style={{ fontSize: "22px" }}>
                Dining Information
              </span>
              <Link
                to={`/MealCard/addResident?id=${residentId}&step=3`}
                className="newEditButton">
                <Typography component="span" fontWeight={700}>
                  Edit
                </Typography>
              </Link>
            </Box>
            <TableContainer>
              <Table
                size="small"
                sx={{
                  borderCollapse: "collapse",
                  "& .MuiTableCell-root": { border: "none !important" },
                  "& .MuiTableRow-root": {
                    borderBottom: "1px solid #bdbdbd !important",
                  },
                }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontSize: "1.125rem !important" }}>
                      Dining Area
                    </TableCell>
                    <TableCell sx={{ fontSize: "1.125rem !important" }}>
                      Table
                    </TableCell>
                    <TableCell sx={{ fontSize: "1.125rem !important" }}>
                      Seat
                    </TableCell>
                    <TableCell sx={{ fontSize: "1.125rem !important" }}>
                      Fluid Restriction
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  xs={{
                    borderBottom: "3px solid red !important",
                  }}>
                  <TableRow key={"breakfast"}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        backgroundColor: "#F6F6F6",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                      }}>
                      Breakfast
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {breakfastDiningArea?.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {breakfastTable?.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {resident.breakfastSeat}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {resident.breakfastRestrictQty}
                    </TableCell>
                  </TableRow>
                  <TableRow key={"lunch"}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        backgroundColor: "#F6F6F6",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                      }}>
                      Lunch
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {lunchDiningArea?.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {lunchTable?.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {resident.lunchSeat}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {resident.lunchRestrictQty}
                    </TableCell>
                  </TableRow>
                  <TableRow key={"supper"}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        backgroundColor: "#F6F6F6",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                      }}>
                      Dinner
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {supperDiningArea?.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {supperTable?.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {resident.supperSeat}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem !important" }}>
                      {resident.supperRestrictQty}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* This block might be used later */}
          {/* <Grid
            item
            xs={12}
            mt={2}
            boxShadow="0px 4px 4px 0px #00000040"
            sx={{ borderRadius: "0px 0px 4px 4px" }}>
            <Box display="flex" flexDirection="column">
              <Box
                backgroundColor="#C4E1E2"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="8px 16px"
                style={{ borderRadius: "4px 4px 0px 0px" }}>
                <span style={{ fontSize: "24px", fontWeight: "700" }}>
                  Weight Metrics
                </span>
                <Link
                  to={`/MealCard/addResident?id=${residentId}&step=1`}
                  className="newEditButton">
                  <Typography component="span" fontWeight={700}>
                    Edit
                  </Typography>
                </Link>
              </Box>
              <Grid
                container
                spacing={2}
                padding="8px 16px"
                display="flex"
                justifyContent="space-between">
                <Grid
                  item
                  xs={6}
                  sm={3}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Weight:
                  </Typography>
                  <Typography>{resident.weight}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Usual Body Weight:
                  </Typography>
                  <Typography>{resident.usualBodyWeight}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Weight Sourced:
                  </Typography>
                  <Typography>
                    {resident.usualBodyWeightSource == 1
                      ? "Per Resident"
                      : resident.usualBodyWeightSource == 2
                      ? "Per Family"
                      : "Other"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  display="flex"
                  gap="0.5rem"
                  flexGrow={1}>
                  <Typography fontWeight={700} component="span">
                    Height:
                  </Typography>
                  <Typography component="span">
                    {resident.heightFeet} ft. {resident.heightInches} in.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}

          {decliningBalance && (
            <Grid
              item
              xs={12}
              mt={2}
              boxShadow="0px 4px 4px 0px #00000040"
              sx={{ borderRadius: "0px 0px 4px 4px" }}>
              <Box display="flex" flexDirection="column">
                <Box
                  backgroundColor="#C4E1E2"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="8px 16px"
                  style={{ borderRadius: "4px 4px 0px 0px" }}>
                  <span style={{ fontSize: "22px", fontWeight: "700" }}>
                    TableSide Information
                  </span>
                  <Link
                    to={`/MealCard/addResident?id=${residentId}&step=3`}
                    className="newEditButton">
                    <Typography component="span" fontWeight={700}>
                      Edit
                    </Typography>
                  </Link>
                </Box>
                <Grid
                  container
                  spacing={2}
                  padding="8px 16px"
                  display="flex"
                  justifyContent="space-between">
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    display="flex"
                    gap="0.5rem"
                    flexGrow={1}>
                    <Typography fontWeight={700} component="span">
                      Monthly TableSide Allowance:
                    </Typography>
                    <Typography>
                      {currencyFormatter.format(
                        resident.monthlyTableSideBudget
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    display="flex"
                    gap="0.5rem"
                    flexGrow={1}>
                    <Typography fontWeight={700} component="span">
                      Monthly TableSide Balance:
                    </Typography>
                    <Typography>
                      {currencyFormatter.format(resident.tableSideBalance)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    display="flex"
                    gap="0.5rem"
                    flexGrow={1}>
                    <Typography fontWeight={700} component="span">
                      Declining Balance Start Day:
                    </Typography>
                    <Typography>{resident.tableSideBudgetStartDay}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    display="flex"
                    gap="0.5rem"
                    flexGrow={1}></Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
