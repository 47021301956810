import React, { useState } from "react";
import { Typography, Menu, MenuItem, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { Link } from "react-router-dom";

import { useGetDiningAreaListQuery } from "../../../store/apis/ResidentApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../shared/EditButton";
import DeleteButton from "../../shared/DeleteButton";
import { BaseButton } from "components/shared/BaseButton";

export default function DiningArea() {
  const facilityId = useSelector(selectFacilityId);

  const {
    data: diningAreaList,
    isFetching: loading,
    isError: hasError,
  } = useGetDiningAreaListQuery(
    {
      facilityId: facilityId,
    },
    { refetchOnMountOrArgChange: true, skip: !facilityId }
  );

  const diningAreasColumns = [
    {
      field: "name",
      headerName: "Dining Area",
      flex: 1,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`AddDiningArea?id=${params.row.id}&facilityId=${facilityId}`}
              title="dining Area"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row["name"] || ""}
              apiPath="deleteDiningArea"
              additionalText={
                params.row.residentsCount > 0 || params.row.ordersCount > 0
                  ? `This dining area will be removed from ${params.row.residentsCount} residents and ${params.row.ordersCount} orders.`
                  : undefined
              }
            />
            <TableOption
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row.name}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1} paddingRight={1} marginTop={1}>
        <Grid item sm={10} md={10} lg={10} xl={10}>
          <Typography variant="h6" component="h2">
            Dining Areas
          </Typography>
        </Grid>
        <Grid item sm={2} md={2} lg={2} xl={2}>
          <BaseButton
            text="Add Dining Area"
            To={`AddDiningArea?facilityId=${facilityId}`}
            endIcon={<span className="material-icons">post_add</span>}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={diningAreaList}
            columns={diningAreasColumns}
            loading={loading}
            autoHeight={false}
            error={hasError}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "58vh",
              lg: "62.5vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

const TableOption = ({ index, id, name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        id={"menu-button-" + index}
        aria-controls={open ? "menu-" + index : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className="menuButton"
        to={"/"}
        onClick={handleClick}>
        <span className="material-icons">more_vert</span>
      </Link>
      <Menu
        id={"edit-menu" + index}
        MenuListProps={{
          "aria-labelledby": "menu-button-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem disableRipple>
          <Link
            to={`/MealCard/settings/DiningAreaTables?diningAreaId=${index}`}
            download={id + name}
            style={{ textDecoration: "none", color: "black" }}>
            Tables
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};
