import React from "react";
import { FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetDietitianRegionListQuery } from "../../../../../store/apis/DietitianAPis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";

export default function DietitianRegion() {
  const {
    data: dietitianRegions,
    isLoading: isLoading,
    isError: isError,
  } = useGetDietitianRegionListQuery();

  const DietitianRegionColumns = [
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "regionName",
      headerName: "Primary Region",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Regional Manager",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row["regionManagerId"] > 0
          ? `${params.row["regionManagerLastName"]}, ${params.row["regionManagerFirstName"]}`
          : "Not Assigned";
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <DietitianRegionEdit index={params.row.dietitianId} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow"></div>
      <BaseDataGrid
        rows={dietitianRegions}
        loading={isLoading}
        columns={DietitianRegionColumns}
        error={isError}
        autoHeight={false}
        height={{ md: "58vh", xl: "60vh" }}
      />
    </>
  );
}

const DietitianRegionEdit = ({ index }) => {
  return (
    <FormControlLabel
      control={
        <Link to={`dietitianEdit?id=${index}`} className="editIcon">
          <span className="material-icons">edit</span>{" "}
        </Link>
      }
    />
  );
};
