import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

export function AdminCard({ cardProperties }) {
  return (
    <Grid item xs={12} md={4}>
      <Card
        className={cardProperties.IsActive ? "adminCard active" : "adminCard"}
        sx={{ maxWidth: 345 }}>
        <CardContent className="adminCardContent">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="adminCardTitle">
            <span className="material-icons adminCardIcon">
              {cardProperties.Icon}
            </span>{" "}
            {cardProperties.Name}
          </Typography>
        </CardContent>
        <CardActions className="cardActionsRoot">
          {cardProperties.Children.map((data, index) => {
            return (
              <Grid
                key={index}
                item
                xs
                className={
                  data.IsActive ? "active cardActions" : "cardActions"
                }>
                <Link to={data.Link} size="small">
                  {data.Name}
                </Link>
              </Grid>
            );
          })}
        </CardActions>
      </Card>
    </Grid>
  );
}
