import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DIET_INFO_FIELDS } from "./forms/forms.constants";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { useSnackbar } from "notistack";
import {
  useAddDietMutation,
  useUpdateDietMutation,
  useGetDietByIdQuery,
} from "../../../../../../store/apis/DietsApi";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import { BaseButton } from "../../../../../shared/BaseButton";

function AddDiet() {
  const [
    addDiet,
    { isError: addError, isSuccess: addSuccess, isLoading: addLoading },
  ] = useAddDietMutation();
  const [
    updateDiet,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
    },
  ] = useUpdateDietMutation();
  const isError = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentDietId = searchParams.get("id");
  const maxDietRank = searchParams.get("maxrank");

  const isEdit = !!currentDietId;

  const {
    data: currentDiet,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetDietByIdQuery(currentDietId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: {
      dietRank: maxDietRank != 0 ? +maxDietRank + 1 : -1,
    },
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentDiet) {
      reset(currentDiet);
    }
  }, [currentDiet]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Diet: ${currentDiet?.name} ${
          isEdit ? "updated" : "created"
        } successfully.`,
        { variant: "success" }
      );
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateDiet(data);
      return;
    }
    addDiet(data);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseButton
            text="Back To Diets"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />
          <Box padding={"8px"}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                // flexDirection: "column",
              }}>
              <GeneratedForm
                control={control}
                list={DIET_INFO_FIELDS}
                errors={errors}
              />

              <Grid item md={12}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Submit" : "Create"}
                />
              </Grid>
              {isError ? (
                <Typography color={"error"}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default AddDiet;
