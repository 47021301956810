import React from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useFacilityAssignedUsersQuery } from "../../../../../../store/apis/FacilityApis";
import { IconButton, Grid, Typography, Tooltip } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import DeleteButton from "../../../../../shared/DeleteButton";
import { DMStyles } from "../../../../../../styles/Styles";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";
import { b2cPolicies, loginRequest } from "authConfig";
import { useMsal } from "@azure/msal-react";

export default function AssignedUsers() {
  const { instance } = useMsal();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("id");

  const {
    data: users,
    isLoading,
    isError,
  } = useFacilityAssignedUsersQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });
  async function handleLogOnAs(userName) {
    const request = {
      authority: b2cPolicies.authorities.logonAs.authority,
      scopes: loginRequest.scopes,
      extraQueryParameters: {
        targetEmail: userName,
        // eslint-disable-next-line no-undef
        server: process.env.REACT_APP_BASE,
      },
    };

    await instance.loginPopup(request);

    navigate("/home");
  }

  const userColumns = [
    {
      field: "fullName",
      headerName: "User",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Link
            to={`/admin/usermenu/usersetup/adduser?id=${params.row["userId"]}`}
            style={{ textDecoration: "none", color: "blue" }}>
            {`${params.row.firstName} ${params.row.lastName}`}
          </Link>
        );
      },
    },
    {
      field: "lastLogin",
      minWidth: 50,
      flex: 1,
      headerName: "Last Login",
      valueGetter: (params) => dayjs(params.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params.value).isValid();
        return isDateValid
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },

    {
      field: "archived",
      minWidth: 50,
      flex: 1,
      headerName: "Archived",
      renderCell: (params) => {
        return (
          <>
            <Typography variant="body1" component="div">
              {params.row.archived ? "Yes" : "No"}
            </Typography>
            <span
              className="material-icons"
              style={{
                color: params.row.archived ? DMStyles.Teal : DMStyles.Red,
                marginLeft: "10px",
              }}>
              {params.row.archived ? "gpp_good" : "gpp_bad"}
            </span>
          </>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.firstName}
              apiPath="deleteFacilityUser"
            />
            <IconButton
              aria-label={`Log on as ${params.row?.username}`}
              variant="outlined"
              color="primary"
              onClick={() => {
                handleLogOnAs(params.row?.username);
              }}
              disableRipple>
              <Tooltip title={`Log on as ${params.row?.username}`}>
                <span className="material-icons">person_add</span>
              </Tooltip>
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid marginBottom={"8px"}>
        <BaseButton
          marginLeft={"0px !important"}
          text={"Add User"}
          To={`addassigneduser?id=${id}`}
          endIcon={"person_add"}
        />
      </Grid>
      <BaseDataGrid
        rows={users?.users}
        columns={userColumns}
        error={isError}
        loading={isLoading}
        autoHeight={true}
        defaultPageSize={10}
      />
    </>
  );
}
