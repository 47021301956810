import React from "react";
import { useGetResidentTablesideOrdersQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../shared/EditButton";
import DeleteButton from "../../../shared/DeleteButton";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";

export default function ResidentTablesideHistory() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentTablesideOrders,
    isLoading: isLoadingOrders,
    isError: isErrorOrders,
  } = useGetResidentTablesideOrdersQuery(residentId, { skip: !residentId });

  const tableSideColumns = [
    {
      field: "diningDate",
      minWidth: 50,
      flex: 1,
      headerName: "Date",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueGetter: (params) => dayjs(params.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "mealName",
      minWidth: 50,
      flex: 1,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "recipeName",
      minWidth: 50,
      flex: 2,
      headerName: "Item",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "quantity",
      minWidth: 50,
      flex: 2,
      headerName: "Serving",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography variant="body1" component="div">
            {`${params?.row?.quantity} - ${params?.row?.servingSize}`}
          </Typography>
        );
      },
    },
    {
      field: "deleteActions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addTablesideOrders?orderItemId=${params.row.id}&id=${residentId}&orderId=${params?.row?.orderId}&quantity=${params?.row?.quantity}`}
              data={residentTablesideOrders}
              title="add TablesideOrders"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.recipeName}
              apiPath="deleteResidentTablesideOrders"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={9} md={10} lg={10} xl={10} marginTop={1}>
          <Typography variant="h5" fontWeight={"800"}>
            Tableside orders for the last 30 days
          </Typography>
        </Grid>
        <Grid item sm={3} md={2} lg={2} xl={2} marginTop={1}>
          <BaseButton
            To={`addTablesideOrders?id=${residentId}`}
            endIcon={<span className="material-icons">post_add</span>}
            text="Add Order Item"
            marginLeft="0px !important"
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={residentTablesideOrders}
            columns={tableSideColumns}
            error={isErrorOrders}
            loading={isLoadingOrders}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "58vh",
              lg: "61vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
