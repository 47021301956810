import React, { useState } from "react";
import { useGetRecipeCategoryListQuery } from "../../../../../store/apis/RecipeCategoriesApis";
import { FormControlLabel, Grid, Menu, MenuItem } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";

export default function RecipeCategories() {
  const SEARCH_PARAM = "search";

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const {
    data: userPagedData,
    isLoading: isLoading,
    isError: isError,
  } = useGetRecipeCategoryListQuery({
    searchText: debouncedValue || "",
    refetchOnMountOrArgChange: true,
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const recipeCategories = userPagedData?.items || [];

  const CategoryEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addRecipeCategory?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Ingredient",
              data: recipeCategories.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const CategoryOptions = ({ index, id, name }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);

    const handleClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Link
          id={"menu-button-" + index}
          aria-controls={open ? "menu-" + index : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          className="menuButton"
          to={"/"}
          onClick={handleClick}>
          <span className="material-icons">more_vert</span>
        </Link>
        <Menu
          id={"edit-menu" + index}
          MenuListProps={{
            "aria-labelledby": "menu-button-" + index,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <MenuItem disableRipple>
            <Link
              to={`./CorporateGroupCategories?id=${id}`}
              style={{ textDecoration: "none", color: "black" }}>
              Corporate Groups
            </Link>{" "}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
              handleClose();
            }}
            className="menuDeleteButton"
            disableRipple>
            Delete
          </MenuItem>
        </Menu>
        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deleteRecipeCategory"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const CategoryColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "adminOnly",
      headerName: "Admin Only",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.adminOnly} />;
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <CategoryEdit index={params.row.id} />
            <CategoryOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row["name"] || ""}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1} marginTop={0.5} paddingX={1}>
        <Grid item xs={8} sm={10} md={10} lg={10} xl={10}>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
          <BaseButton
            text="Add Recipe Category"
            To="addRecipeCategory"
            endIcon={<span className="material-icons">post_add</span>}
          />{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={recipeCategories}
            rowCount={userPagedData?.totalCount || 0}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            columns={CategoryColumns}
            loading={isLoading}
            error={isError}
            autoHeight={false}
            height={{ md: "50dvh", xl: "55dvh" }}
          />
        </Grid>
      </Grid>
    </>
  );
}
