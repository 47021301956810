import { createTheme } from "@mui/material/styles";
import NunitoSans from "@fontsource/nunito-sans";

export const theme = createTheme({
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
  },
  status: {
    danger: "#FF3F28",
  },
  zIndex: {
    snackbar: 9999,
  },
  palette: {
    // action: {
    //   disabled: "#dcdcdc",
    // },
    primary: {
      main: "#00BCBE",
      contrastText: "#fff",
    },
    error: {
      main: "#FF3F28",
      contrastText: "#fff",
    },
    warning: {
      main: "#FEC92C",
      contrastText: "#fff",
    },
    success: {
      main: "#00BCBE",
      contrastText: "#fff",
    },
    white: {
      main: "#fff",
      contrastText: "#000",
    },
    gray: {
      main: "#dcdcdc",
      contrastText: "#000",
      light: "#E3E3E3",
      dark: "#9A9A9A",
      darkGray: "#575757",
    },
    black: {
      main: "#000",
      contrastText: "#fff",
    },
    orange: {
      main: "#F76618",
      contrastText: "#fff",
      light: "#F88446",
      dark: "#AC4710",
    },
    blue: {
      main: "#548ADF",
      contrastText: "#fff",
      light: "#76A1E5",
      dark: "#3A609C",
    },
    pacificBlue: {
      main: "#00BCBE",
      contrastText: "#fff",
      dark: "#008385",
      light: "#33C9CB",
    },
    maroon: {
      main: "#AB3E68",
      contrastText: "#fff",
      light: "#BB6486",
      dark: "#772B48",
    },
    purple: {
      main: "#6B37A6",
      contrastText: "#fff",
      light: "#885FB7",
      dark: "#4A2674",
    },
    yellow: {
      main: "#F8D061",
    },
    green: {
      main: "#288B51",
      contrastText: "#fff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Raleway'), local('Raleway-Regular'), url(${NunitoSans}) format('woff2');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
    },
  },
});
