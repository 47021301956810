import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import { BackButton } from "components/shared/BackButton";
import { EditButton } from "components/shared/EditButton";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetVisitDetailsQuery } from "store/apis/ConsultantApis";
import { DMStyles } from "styles/Styles";

export default function VisitsDetail() {
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const facilityId = searchParams.get("facilityId");

  const { data: visitData, isLoading } = useGetVisitDetailsQuery(
    visitPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );

  const VisitsDetail = [
    {
      name: "Community",
      value: visitData?.community ? visitData?.community : " ",
    },
    {
      name: "Date of Consult",
      value: visitData?.dateOfVisit
        ? dayjs(visitData?.dateOfVisit).format(SCREEN_CONSTANTS.Date_Format)
        : "N/A",
    },
    { name: "Scheduled Visit", value: visitData?.scheduled ? "Yes" : "No" },
    {
      name: "Future Consult",
      value: visitData?.nextConsult
        ? dayjs(visitData?.nextConsult).format(SCREEN_CONSTANTS.Date_Format)
        : "N/A",
    },
    { name: "Substitute RD", value: visitData?.substituteRD ? "Yes" : "No" },
    {
      name: "Future Consult Notes",
      value: visitData?.nextVisitNotes ? visitData?.nextVisitNotes : "N/A",
    },
    {
      name: "Date finished",
      value: visitData?.dateFinished
        ? dayjs(visitData?.dateFinished).format(SCREEN_CONSTANTS.Date_Format)
        : "N/A",
    },
    {
      name: "Date  submitted",
      value: visitData?.isFinalDate
        ? dayjs(visitData?.isFinalDate).format(SCREEN_CONSTANTS.Date_Format)
        : "N/A",
    },
  ];

  if (isLoading) {
    return (
      <>
        <EditSkeleton />
      </>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <BackButton
          text="Back to Visit list"
          To={`/admin/communities/communitydetail/visits?id=${facilityId}`}
        />

        <EditButton
          To={`/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${visitPlanId}&isEdit=${true}`}
          state={{
            communityName: visitData?.community,
            dataOfConsultant: visitData?.dateOfVisit,
          }}
        />
      </Box>

      <Box width="60%" marginTop="10px">
        {VisitsDetail?.map((visit, index) => (
          <Grid container key={index} spacing={2}>
            <Grid item md={4}>
              <Typography variant="h6" fontSize="15px" fontWeight="bold">
                {" "}
                {visit?.name}{" "}
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography variant="h6" fontSize="15px">
                {visit?.value}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box marginTop="10px">
        <Typography variant="h6" fontWeight="bold">
          More Visits Details
        </Typography>
        <Grid item xs={12} mr={2}>
          <TableContainer>
            <Table sx={{ minWidth: 500 }}>
              <TableHead
                sx={{ backgroundColor: DMStyles.Teal, fontWeight: "700" }}>
                <TableRow>
                  <TableCell align="center">Consulting Type</TableCell>
                  <TableCell align="center">TimeIn</TableCell>
                  <TableCell align="center">Time Out</TableCell>
                  <TableCell align="center">Mileage</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visitData?.visitHours.length > 0
                  ? visitData?.visitHours.map((consult, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {consult?.consultingTypeName}
                        </TableCell>
                        <TableCell align="center">
                          {consult?.timeIn == " " ? "--" : consult?.timeIn}
                        </TableCell>
                        <TableCell align="center">
                          {consult?.timeOut == " " ? "--" : consult?.timeOut}
                        </TableCell>
                        <TableCell align="center">
                          {consult?.quantity ? consult?.quantity : "--"}
                        </TableCell>
                        <TableCell align="center">
                          {consult?.totalHours}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}

                <TableRow>
                  <TableCell
                    colSpan={4}
                    align="right"
                    sx={{ fontWeight: "bold" }}>
                    Total Hours
                  </TableCell>
                  <TableCell align="center">
                    {visitData?.totalBillableHour
                      ? visitData?.totalBillableHour
                      : 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </>
  );
}
