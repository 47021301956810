import React, { useEffect, useState } from "react";
// import { useIsAuthenticated } from "@azure/msal-react";
import { useSearchParams, Link } from "react-router-dom";
// import { useSnackbar } from "notistack";
import { useGetDietsQuery } from "../../../../../store/apis/DietsApi";

import { FormControlLabel, IconButton } from "@mui/material";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";

import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";
// import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

export default function Diets() {
  const SEARCH_PARAM = "search";
  //   const appInsights = useAppInsightsContext();
  //   const isAuthenticated = useIsAuthenticated();
  //   const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [maxRankValue, setMaxRankValue] = useState(0);
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: diets,
    isError: hasError,
    isLoading: loading,
  } = useGetDietsQuery(debouncedValue);

  const isError = hasError;
  const isLoading = loading;

  useEffect(() => {
    // console.log("diets refreshed");

    if (diets?.length > 0) {
      const maxValue = Math.max(...diets.map((x) => x.dietRank));
      // console.log("max rank:", maxValue);

      // keep max diet rank in state
      if (maxValue > maxRankValue) {
        setMaxRankValue(maxValue);
      }
    }
  }, [diets]);

  const DietEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addDiet?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Diet",
              data: diets.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const DietDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteDiet"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  const DietColumns = [
    {
      field: "name",
      headerName: "Diet Name",
      flex: 1,
      minWidth: 500,
    },
    {
      field: "includeInMenu",
      headerName: "Include in Menus",
      flex: 1,
      maxWidth: 140,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.includeInMenu} />;
      },
    },
    {
      field: "isTexture",
      headerName: "Is a Texture",
      flex: 1,
      maxWidth: 140,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.isTexture} />;
      },
    },
    {
      field: "dietRank",
      headerName: "Rank",
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 1,
      maxWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <DietEdit index={params.row.id} />
            <DietDelete id={params.row.id} name={params.row["name"] || ""} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
          />
          <BaseButton
            To={`addDiet?maxrank=${maxRankValue}`}
            endIcon={"post_add"}
            text={"Add Diet"}
          />
        </div>
      </div>

      <BaseDataGrid
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
        rows={diets}
        columns={DietColumns}
        loading={isLoading}
        error={isError}
        getRowHeight={() => "auto"}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}
