import {
  Box,
  Typography,
  FormControlLabel,
  Modal,
  Fade,
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  useGetOrdersQuery,
  useReprintOrderMutation,
} from "../../../store/apis/TableSideApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BaseContent from "../../shared/baseContent/BaseContent";

export default function OrderTracking() {
  const facilityName = useSelector((state) => state.userInfo.facilityName);
  const [orders, setOrders] = useState(null);
  const [error, setError] = useState(null);
  const intervalTimer = useRef(null);
  const [orderCategories] = useState([
    "Entree",
    "Side",
    "Beverage",
    "Dessert",
    "Appetizer",
    "Bread",
    "Condiments",
    "Sandwich Builder",
  ]);

  const {
    data: response,
    isSuccess: isFetchSuccess,
    isFetching: isFetchingOrders,
    isError: hasError,
    error: fetchError,
    refetch: refetchOrders,
  } = useGetOrdersQuery();

  const [
    reprintOrder,
    { isFetching: isReprinting, isSuccess: isReprintSuccess },
  ] = useReprintOrderMutation();

  const handleReprintOrder = (orderId) => {
    reprintOrder(orderId);
  };

  useEffect(() => {
    if (isReprintSuccess && !isReprinting) {
      refetchOrders();
    }
  }, [isReprinting, isReprintSuccess]);

  useEffect(() => {
    intervalTimer.current = setInterval(() => {
      refetchOrders();
    }, 10000);
    return () => {
      clearInterval(intervalTimer.current);
    };
  }, []);

  useEffect(() => {
    if (isFetchSuccess && !isFetchingOrders) {
      if (!hasError && response?.data) {
        setOrders(response?.data);
      }
    }
  }, [isFetchingOrders, isFetchSuccess]);

  useEffect(() => {
    if (hasError) {
      setError(fetchError?.data?.messages[0]);
      clearInterval(intervalTimer.current);
    }
  }, [hasError]);

  const OrderColumns = [
    {
      field: "residentName",
      headerName: "Resident/Guest",
      flex: 2,
      renderCell: (params) => {
        return (
          <ViewOrders
            id={params?.row?.id}
            orderItems={params?.row?.tableSideOrderRecipeItems}
            isCooksTicket={params?.row?.isCooksTicket}
            note={params?.row?.note}
            residentName={params?.row?.residentName}
            guestType={params?.row?.guestType}
            guestName={params?.row?.guest}
          />
        );
      },
    },
    {
      field: "dietAndTexture",
      headerName: "Diet/Texture",
      flex: 2,
      renderCell: (params) => {
        if (params?.row?.isCooksTicket) {
          return "Cooks Ticket";
        } else if (
          params?.row?.dietAndTexture &&
          params?.row?.dietAndTexture.length > 1
        ) {
          return params?.row?.dietAndTexture;
        } else {
          return `N/A`;
        }
      },
    },
    {
      field: "diningAreaName",
      headerName: "Dining Room",
      flex: 1,
    },
    {
      field: "lastUpdated",
      headerName: "Time",
      flex: 2,
      valueFormatter: (params) => {
        if (null === params.value) {
          return "";
        } else {
          return new Date(params.value).toLocaleString();
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <FormControlLabel
              className="editButton"
              control={
                <Link
                  className="editIcon"
                  style={{
                    backgroundColor: "#008385",
                    color: "#FFFFFF",
                    textDecoration: "none",
                  }}
                  onClick={() => handleReprintOrder(params?.row?.id)}
                  state={{
                    title: "Reprint Order",
                  }}>
                  Reprint
                </Link>
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <BaseContent
        headerText={`Order Tracking for ${facilityName}`}
        disableHeight={true}>
        <Box height={{ md: "80vh", xl: "84vh" }}>
          <Box marginTop="5px">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}>
              <div style={{ fontWeight: "600", fontSize: "24px" }}>
                Current Orders (refreshes every 10 seconds)
              </div>
              <Button
                variant="contained"
                size="medium"
                onClick={refetchOrders}
                sx={{
                  marginLeft: "20px",
                  marginBottom: "3px",
                  backgroundColor: "#008385",
                  textTransform: "none",
                  fontWeight: "600",
                }}>
                Refresh
              </Button>
            </div>

            {hasError ? (
              <Typography color={"error"} variant="h5" component="h5">
                {error}
              </Typography>
            ) : undefined}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              alignItems="center"
              padding="30px"
              boxShadow="var(--darkBoxShadow)"
              backgroundColor="#1A4650"
              color="#FFFFFF"
              fontSize="22px">
              <Box>
                <Box display="flex" fontWeight="600">
                  <Typography marginRight="5px" variant="p" component="p">
                    Current Menu:
                  </Typography>
                  <Typography variant="p" component="p">
                    {orders?.tableSideSetup?.menuName}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box display="flex" marginRight="10px">
                  <Typography marginRight="5px" variant="p" component="p">
                    Menu Day:
                  </Typography>
                  <Typography variant="p" component="p">
                    {orders?.tableSideSetup?.day}
                  </Typography>
                </Box>
                <Box display="flex" marginRight="10px">
                  <Typography marginRight="5px" variant="p" component="p">
                    Meal:
                  </Typography>
                  <Typography variant="p" component="p">
                    {orders?.tableSideSetup?.mealName}
                  </Typography>
                </Box>
                <Box display="flex" marginRight="10px">
                  <Typography marginRight="5px" variant="p" component="p">
                    Residents served:
                  </Typography>
                  <Typography variant="p" component="p">
                    {orders?.residentTotal}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography marginRight="5px" variant="p" component="p">
                    Guests served:
                  </Typography>
                  <Typography variant="p" component="p">
                    {orders?.guestTotal}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                display: "flex",
                alignItems: "center",
                height: "50px",
                marginY: "20px",
                paddingLeft: "10px",
                fontWeight: "bold",
                fontSize: "24px",
                backgroundColor: "#C4E1E2",
              }}>
              Current Orders
            </Typography>
            <Grid container>
              {orderCategories.map((category, index) => (
                <Grid
                  item
                  xs={2.9}
                  key={index}
                  sx={{
                    border: "solid 2px black",
                    borderRadius: "12px",
                    margin: "5px",
                    padding: "16px",
                  }}>
                  <Typography
                    width="100%"
                    sx={{ fontSize: "24px", fontWeight: "600" }}>
                    {category}
                  </Typography>
                  {orders?.totals
                    ?.filter((order) => order.categoryName === category)
                    .map((item) => (
                      <Typography
                        key={index}
                        width="100%"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "solid 1px black",
                          paddingY: "8px",
                        }}>
                        <div>{item.recipe}</div>
                        <div>{item.totalCount}</div>
                      </Typography>
                    ))}
                </Grid>
              ))}
            </Grid>

            <BaseDataGrid
              rows={orders?.orders || []}
              columns={OrderColumns}
              error={hasError}
              errorMessage={error}
              loading={isFetchingOrders}
              getRowHeight={() => "auto"}
              sx={{
                marginTop: 3,
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </Box>
        </Box>
      </BaseContent>
    </>
  );
}

const ViewOrders = ({
  id,
  orderItems,
  isCooksTicket,
  note,
  residentName,
  guestType,
  guestName,
}) => {
  const [openModal, setOpenModal] = useState(false);

  let name;

  if (isCooksTicket) {
    name = <>{`${note}`}</>;
  } else if (residentName && residentName.length > 1) {
    name = residentName;
  } else {
    name = <>{`${guestName}`}</>;
  }

  return (
    <>
      {isCooksTicket ? (
        <b>Ticket:&nbsp;</b>
      ) : guestType ? (
        <b>{`${guestType}`}:&nbsp;</b>
      ) : null}
      <FormControlLabel
        sx={{ marginLeft: "5px" }}
        control={
          <Link
            onClick={() => setOpenModal(true)}
            style={{ color: "#2A537E" }}
            state={{
              title: "View Order",
            }}>
            {name}
          </Link>
        }
      />
      <OrderTrackModal
        id={id}
        open={openModal}
        close={() => setOpenModal(false)}
        TableSideOrderRecipe={orderItems}
        Note={note}
        ResidentName={residentName}
        GuestName={guestName}
      />
    </>
  );
};

function OrderTrackModal({
  id,
  open,
  close,
  TableSideOrderRecipe,
  Note,
  ResidentName,
  GuestName,
}) {
  return (
    <Modal
      open={open}
      onClose={close}
      className="formModalBody"
      aria-labelledby={`delete-modal-title-${id}`}
      aria-describedby={`delete-modal-description-${id}`}>
      <Fade in={open}>
        <Box className={"formModal"}>
          <Box className="model-title">
            <Typography variant="h6" component="h2" className="modalTitle">
              Meal Summary
            </Typography>
            <Link onClick={() => close(true)}>
              <span className="modalTitle material-icons">{"close"}</span>
            </Link>
          </Box>
          <Box width={"100%"}>
            <Typography
              id={`order-item-resident-name-${id}`}
              variant="h6"
              component="h2"
              paddingLeft={"20px"}
              className="modalTitle"
              sx={{ fontSize: "28px", fontWeight: "700", marginTop: "15px" }}>
              {ResidentName && ResidentName.length > 1
                ? ResidentName
                : GuestName}
            </Typography>

            {TableSideOrderRecipe?.map((item, index) => {
              return (
                <Typography
                  id={`order-item-${id}-${index}}`}
                  key={index}
                  variant="h6"
                  component="h2"
                  width="93%"
                  className="modalTitle"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "solid 1px black",
                    paddingY: "8px",
                    marginLeft: "20px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}>
                  <div>{item.recipeName}</div>
                  <div>{item.quantity}</div>
                </Typography>
              );
            })}
            <Typography
              id={`order-item-note-${id}`}
              variant="h6"
              component="p"
              paddingLeft="20px"
              marginTop="24px"
              marginBottom="15px"
              className="modalTitle"
              sx={{
                display: "flex",
                alignItems: "baseline",
              }}>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  marginRight: "5px",
                }}>
                Note:
              </span>
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                {Note}
              </span>
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
