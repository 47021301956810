import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Divider,
  Fade,
  CircularProgress,
} from "@mui/material";
import "@fontsource/material-icons";
import "./DeleteModal.css";
import { useSnackbar } from "notistack";
import { BaseApi } from "../../../store/apis/BaseApi";
import { useNavigate } from "react-router-dom";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/DeleteModal.md)
 */
export default function DeleteModal({
  entityName,
  apiPath,
  id,
  open,
  To,
  close,
  additionalText,
  FacilityId,
  afterSuccessFunction,
  isafterSuccessFunction,
}) {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const idArray = Array.isArray(id) ? id : undefined;
  const [currentId, setCurrentId] = useState(Array.isArray(id) ? id[0] : id);
  const [startDelete, { data, isLoading, isSuccess, isError, error }] =
    BaseApi.endpoints[apiPath].useMutation();

  const isLastIdAndSuccess = useCallback(() => {
    return Array.isArray(id)
      ? currentId === id.length - 1 && isSuccess
      : isSuccess;
  }, [id, currentId, isSuccess]);

  const handleDelete = useCallback(() => {
    const deleteParams = FacilityId
      ? { id: Array.isArray(id) ? currentId : id, FacilityId }
      : Array.isArray(id)
      ? currentId
      : id;
    startDelete(deleteParams);
  }, [FacilityId, id, currentId, startDelete]);

  useEffect(() => {
    let timeoutId;

    if (!isLoading && isSuccess) {
      if (idArray) {
        const oldIndex = idArray.findIndex((x) => x === currentId);

        if (oldIndex < idArray.length - 1) {
          setCurrentId(idArray[oldIndex + 1]);
          startDelete(idArray[oldIndex + 1], 7850);
        } else {
          close(true);
        }
      } else {
        const payloadMessage =
          data?.messages && data?.messages[0]
            ? data?.messages[0]
            : `${entityName} deleted successfully.`;

        enqueueSnackbar(payloadMessage, {
          variant: "warning",
        });

        timeoutId = setTimeout(() => {
          close(true);
        }, 5000);

        setTimeRemaining(5);
      }
    }

    if (isError) {
      enqueueSnackbar(
        `${error?.status}: ${
          error?.data ? JSON.stringify(error.data) : "An error occured."
        }`,
        { variant: "error" }
      );
    }

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isSuccess, isLoading, isError]);

  useEffect(() => {
    timeRemaining > 0 &&
      setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
  }, [timeRemaining]);

  useEffect(() => {
    if (!isLoading && isSuccess && To) {
      navigate(`${To}`);
    }
    if (isafterSuccessFunction && isSuccess) {
      afterSuccessFunction();
    }
  });
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`delete-modal-title-${id}`}
      aria-describedby={`delete-modal-description-${id}`}>
      <Fade in={open}>
        <Box
          className={
            isLastIdAndSuccess()
              ? "deleteModal deleteModalSuccess"
              : "deleteModal"
          }>
          {isLastIdAndSuccess() ? (
            <Box className="modalContent">
              <p>
                <span
                  className="material-icons modalIconTeal"
                  color={"success"}>
                  check_circle
                </span>
              </p>
              <Box>
                <Typography
                  id={`delete-modal-title-${id}`}
                  variant="h6"
                  component="h2"
                  className="modalTitle">
                  {`"${entityName}" deleted successfully!`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className="modalContent">
              <p>
                <span className="material-icons deleteIcon">report</span>
              </p>
              <Box>
                <Typography
                  id={`delete-modal-title-${id}`}
                  variant="h6"
                  component="h2"
                  className="modalTitle">
                  {`Are you sure you want to delete "${entityName}"?`}
                </Typography>
                <Typography
                  id={`delete-modal-description-${id}`}
                  sx={{ mt: 2 }}>
                  {`This item will be deleted immediately. You can't undo this action.`}
                </Typography>
                {additionalText ? (
                  <Typography
                    id={`delete-modal-description-${id}`}
                    sx={{ mt: 2 }}>
                    {additionalText}
                  </Typography>
                ) : undefined}
              </Box>
            </Box>
          )}
          <Divider sx={{ my: 0.5 }} />
          {isLastIdAndSuccess() ? (
            <Box className="deleteActions" flex={true}>
              <Button
                className="baseButton"
                variant="contained"
                onClick={() => close(true)}
                color={"success"}>{`Close (${timeRemaining})`}</Button>
            </Box>
          ) : (
            <Box className="deleteActions" flex={true}>
              <Button
                className="baseButton"
                variant="contained"
                onClick={() => close(false)}
                color={"warning"}>
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: "10px" }}
                className="baseButton"
                variant="contained"
                onClick={handleDelete}
                disabled={isLoading}
                startIcon={
                  isLoading ? <CircularProgress color="white" size={18} /> : ""
                }
                color={"error"}>
                Delete
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
