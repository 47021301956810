import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "../../../../shared/AdminTabs.css";
import { CommunityMenuElements } from "./communitymenu.constants";
import { useGetFacilityByIdQuery } from "../../../../../../store/apis/FacilityApis";
import "./CommunityMenu.css";

export default function CommunityMenu() {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [value, setValue] = useState(1);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [menuItems, setMenuItems] = useState(CommunityMenuElements);

  const { data: community, isSuccess: communitySuccess } =
    useGetFacilityByIdQuery(id, { skip: !id });

  useEffect(() => {
    if (
      currentLocation.pathname === "/admin/communities/communitysetup/details"
    ) {
      navigate(menuItems[1].Link);
    }

    let currentLink;
    if (currentLocation) {
      currentLink = menuItems.find((x) =>
        currentLocation?.pathname.includes(x.Link)
      );
      if (!currentLink) {
        currentLink = menuItems[0];
      }
    }
    const currentIndex =
      CommunityMenuElements.indexOf(
        CommunityMenuElements.find(
          (res) =>
            currentLocation.pathname.includes(res.Link) && res.Name !== "Back"
        )
      ) || 1;
    setValue(currentIndex !== -1 ? currentIndex : 1);
    setMenuItems(
      CommunityMenuElements.map((res) => {
        if (currentLocation.pathname.includes(res.Link)) {
          res.IsActive = true;
        } else {
          res.IsActive = false;
        }
        return res;
      })
    );
  }, [currentLocation]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
            }}
            value={value}
            aria-label="User Menu nav tabs ">
            {menuItems.map((data, index) => {
              return (
                <LinkTab
                  key={index}
                  value={index}
                  id={id}
                  label={`${
                    data.Name === "Details" && communitySuccess
                      ? community?.name
                      : data.Name
                  }`}
                  isTitle={data.Name === "Details"}
                  href={`${data.Link}?id=${id}`}
                  link={data.Link}
                  name={community?.name}
                  navigate={navigate}
                />
              );
            })}
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: "8px",
          height: { md: "50dvh", xl: "60dvh" },
          overflowY: "auto",
        }}>
        <Box height={"auto"}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

function LinkTab({ label, id, isTitle, link, name, navigate, ...rest }) {
  const processedName = name?.includes("#")
    ? name?.split("#").slice(0, -1)
    : name;
  const hash = name?.split("#").at(-1);
  return (
    <Tab
      component="a"
      className={
        label === "Back"
          ? "backTab"
          : isTitle
          ? "communityDetailTab"
          : "adminTabs"
      }
      onClick={(event) => {
        event.preventDefault();
        if (label === "Back" && !link) {
          navigate(-1);
          return;
        }
        navigate({
          pathname: link,
          search: `?id=${id}&name=${processedName}`,
          hash,
        });
        return;
      }}
      icon={
        label === "Back" ? (
          <span className="material-icons">{"keyboard_arrow_left"}</span>
        ) : null
      }
      label={label === "Back" ? "Back" : label}
      {...rest}
    />
  );
}
