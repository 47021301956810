import React from "react";
import { useGetResidentNotesQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../shared/EditButton";
import DeleteButton from "../../../shared/DeleteButton";
import YesNoDisplay from "../../../shared/YesNoDisplay";
import { BaseButton } from "components/shared/BaseButton";

export default function ResidentNotes() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentNotes,
    isLoading,
    isError,
  } = useGetResidentNotesQuery(residentId, { skip: !residentId });

  const noteColumns = [
    {
      field: "note",
      minWidth: 50,
      flex: 2,
      headerName: "Note",
    },
    {
      field: "noteType",
      minWidth: 50,
      flex: 1,
      headerName: "Type",
    },
    {
      field: "includeOnTrayCard",
      minWidth: 50,
      flex: 1,
      headerName: "Meal Card",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.includeOnTrayCard} />;
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addresidentNote?noteId=${params.row.id}&id=${residentId}`}
              data={residentNotes}
              title="add ResidentNote"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.noteType}
              apiPath="deleteResidentNote"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} md={4} lg={3} xl={3} marginTop={1}>
          <BaseButton
            text={"Add Note"}
            endIcon={"post_add"}
            To={`addresidentNote?id=${residentId}`}
            marginLeft="0px !important"
            sx={{
              marginY: "0px !important",
            }}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={residentNotes}
            columns={noteColumns}
            error={isError}
            loading={isLoading}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "55vh",
              lg: "62vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
