import React from "react";
import { useGetResidentLiquidQuery } from "../../../../store/apis/ResidentApis";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Grid } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../shared/EditButton";
import DeleteButton from "../../../shared/DeleteButton";

export default function ResidentLiquid() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentThickenedLiquid,
    isLoading,
    isError,
  } = useGetResidentLiquidQuery(residentId, { skip: !residentId });

  const residentLiquidColumns = [
    {
      field: "thickenedLiquidName",
      minWidth: 50,
      flex: 4,
      headerName: "Liquid Consistency",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addresidentLiquid?residentId=${residentId}`}
              data={residentThickenedLiquid}
              title="add Resident Liquid"
            />

            <DeleteButton
              index={params.row.id}
              entityName={params.row?.thickenedLiquidName}
              apiPath="deleteResidentLiquid"
            />
          </div>
        );
      },
    },
  ];

  return (
    <Card sx={{ padding: "10px", minHeight: "80vh" }}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={"8px"}>
        <Button
          component={Link}
          to={`addresidentLiquid?residentId=${residentId}`}
          state={{
            name:
              residentThickenedLiquid?.resident?.firstName +
              " " +
              residentThickenedLiquid?.resident?.lastName,
          }}
          color={"primary"}
          className="baseButton"
          variant="contained"
          endIcon={<span className="material-icons">post_add</span>}>
          Add Liquid Consistency
        </Button>
      </Grid>

      <BaseDataGrid
        rows={residentThickenedLiquid}
        columns={residentLiquidColumns}
        error={isError}
        loading={isLoading}
        autoHeight={false}
        height={{ md: "62vh", xl: "67vh" }}
      />
    </Card>
  );
}
