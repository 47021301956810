import React, { useMemo } from "react";
import { FormControl, InputAdornment, Input } from "@mui/material";
import { debounce } from "lodash";
import styles from "./BaseSearch.module.css";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseSearch.md)
 */
export default function BaseSearch({
  value,
  width,
  setSearchValue,
  setDebouncedValue,
  setSearchParams = () => {},
  SEARCH_PARAM,
  placeholder,
  isArchived,
  corporateGroupId,
  showArchived = false,
  sx,
  isId,
  Id,
  isNameID,
  name,
  isRecipeId,
  isMenuId,
  setPaginationModel,
  ...rest
}) {
  const debounceHandler = useMemo(
    () =>
      debounce((searchTerm) => {
        if (setDebouncedValue) setDebouncedValue(searchTerm);
        if (isArchived || corporateGroupId) {
          setSearchParams(
            isArchived && corporateGroupId
              ? {
                  search: searchTerm,
                  archived: showArchived,
                  corporateGroupId: corporateGroupId,
                }
              : { search: searchTerm, archived: showArchived }
          );
        } else if (isId) {
          setSearchParams({ search: searchTerm, id: Id });
        } else if (isNameID) {
          setSearchParams({ search: searchTerm, id: Id, name });
        } else if (isRecipeId) {
          setSearchParams({ search: searchTerm, recipeId: Id });
        } else if (isMenuId) {
          setSearchParams({ search: searchTerm, menuId: Id });
        } else {
          setSearchParams({
            [SEARCH_PARAM]: searchTerm,
          });
        }
        if (setPaginationModel) {
          setPaginationModel({
            page: 0,
            pageSize: 100,
          });
        }
      }, 300),
    []
  );

  return (
    <FormControl sx={{ width }}>
      <Input
        className={styles.basicInput}
        sx={{
          marginLeft: "0 !important",
          ...sx,
        }}
        value={value}
        disableUnderline={true}
        type="text"
        placeholder={placeholder || "Search..."}
        endAdornment={
          <InputAdornment position="end">
            <span className="material-icons tealColor">search</span>
          </InputAdornment>
        }
        onChange={(event) => {
          const { value } = event.target;

          if (!value.startsWith(" ")) {
            if (value.includes("  ") || event.nativeEvent.data == ". ") {
              return null;
            } else {
              setSearchValue(value);
              debounceHandler(value);
            }
          }
        }}
        {...rest}
      />
    </FormControl>
  );
}
