import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Fields, options, options2 } from "./form.constant";
import GeneratedForm from "../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../shared/baseSubmitButton/BaseSubmitButton";
import { useTablesideReportMutation } from "../../../store/apis/TableSideApis";
import { DownloadLinkHelper } from "../../shared/utils/helpers";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { useGetFacilityByIdQuery } from "../../../store/apis/FacilityApis";
import BaseContent from "../../shared/baseContent/BaseContent";
import dayjs from "dayjs";
import { isNull, isUndefined } from "lodash";

export const Reports = () => {
  const [optionList, setOptionList] = useState();
  const [defaultValues] = useState([]);

  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);

  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  // const defaultFields = {
  //   reportGrouping: 1,
  //   mealId: 1,
  // }

  const [queries] = useState([
    {
      name: "residentId2",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
    {
      name: "diningAreaId",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
  ]);

  const [
    GenerateReport,
    {
      data: report,
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      error: error,
    },
  ] = useTablesideReportMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    // defaultValues: defaultFields,
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = methods;
  const selectedValue = watch("type");
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  useEffect(() => {
    const selectedOptions = Fields?.filter(
      (option) => option.id.toString() === selectedValue
    );
    setOptionList(selectedOptions[0]?.options);
  }, [selectedValue]);

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const onSubmit = (data) => {
    if (
      dayjs(endDate).format("YYYY-MM-DD") <
        dayjs(startDate).format("YYYY-MM-DD") &&
      selectedValue !== 6
    ) {
      setError("startDate", {
        message: "Start Date must be before or equal to End Date",
      });
    }

    if (data.fixedPrice !== undefined && data.fixedPrice === "") {
      data.fixedPrice = null;
    }

    const NewData = {
      ...data,
      mealDate: dayjs(data?.mealDate).format("YYYY-MM-DD"),
      startDate: dayjs(data?.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(data?.endDate).format("YYYY-MM-DD"),
      reportId: selectedValue,
      facilityId: facilityId,
      groupByResident: data?.groupByResident === "2" ? true : false,
      reportGrouping: Number(data?.reportGrouping),
      mealId: Number(data?.mealId),
    };
    if (
      dayjs(endDate).format("YYYY-MM-DD") >=
        dayjs(startDate).format("YYYY-MM-DD") ||
      selectedValue === 6
    ) {
      GenerateReport(NewData);
    }
  };

  useEffect(() => {
    const isTrue =
      !isNull(endDate) &&
      !isNull(startDate) &&
      !isUndefined(endDate) &&
      !isUndefined(startDate);
    if (
      dayjs(endDate).format("YYYY-MM-DD") <
        dayjs(startDate).format("YYYY-MM-DD") &&
      isTrue
    ) {
      setError("startDate", {
        message: "Start Date must be before or equal to End Date",
      });
      setError("endDate", {
        message: "End Date must be after or equal to Start Date",
      });
    } else if (startDate && endDate) {
      clearErrors("startDate");
      clearErrors("endDate");
    }
  }, [startDate, endDate]);
  return (
    <>
      <BaseContent
        headerText={`Reports for ${facilityName}`}
        disableHeight={true}
        cardOverFlow={false}>
        <Box height={{ md: "82vh", xl: "85vh" }} overflow="auto">
          <Box>
            <FormProvider>
              <form>
                <Grid container spacing={4}>
                  <Grid item xs={5}>
                    <Box
                      boxShadow="var(--darkBoxShadow)"
                      borderRadius="10px"
                      padding="8px">
                      <RadioGroup row={false} error={errors["type"]}>
                        {options.map((option, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                              {...register("type")}
                            />
                          );
                        })}
                        {!currentFacility?.tablesideTicketUseOrderPrinter
                          ? options2.map((option, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  value={option.value}
                                  control={<Radio />}
                                  label={option.label}
                                  {...register("type")}
                                />
                              );
                            })
                          : null}
                      </RadioGroup>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      boxShadow="var(--darkBoxShadow)"
                      borderRadius="10px"
                      border="1px solid var(--tealColor)">
                      {selectedValue ? (
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginY: "10px",
                            minHeight: "50px",
                          }}>
                          <GeneratedForm
                            list={optionList || []}
                            control={control}
                            errors={errors}
                            getValue={getValues}
                            setValue={setValue}
                            queries={queries}
                            defaultOptions={defaultValues}
                          />
                        </Grid>
                      ) : (
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          paddingY={"30px"}>
                          <Typography
                            variant="h6"
                            component="h2"
                            color={"var(--grayColor)"}>
                            {`Please select a report`}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={5} />
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        pt: 2,
                      }}>
                      <BaseSubmitButton
                        onClick={handleSubmit(onSubmit)}
                        isSubmitting={isLoading}
                        text={"Print "}
                        endIcon="print"
                        startIcon={
                          isLoading && (
                            <CircularProgress color="warning" size={"1rem"} />
                          )
                        }
                        disabled={selectedValue == null ? true : false}
                      />
                    </Box>
                    {isError && (
                      <Typography color={"error"} marginTop={"3px"}>
                        {error?.data?.messages}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </BaseContent>
    </>
  );
};
