import React, { useState } from "react";
import { Box, Divider, FormControlLabel, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetRegionListQuery } from "../../../../../store/apis/RegionsApis";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";

export default function Region() {
  const {
    data: regions,
    isLoading: isLoading,
    isError: isError,
  } = useGetRegionListQuery();

  const RegionEdit = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <Link
            to={`addRegion?id=${index}`}
            className="editIcon"
            state={{
              title: "Edit Region",
              data: regions.find((row) => row.id === index),
            }}>
            <span className="material-icons">edit</span>{" "}
          </Link>
        }
      />
    );
  };

  const RegionDelete = ({ id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <FormControlLabel
          control={
            <IconButton
              aria-label="add an alarm"
              color="error"
              onClick={() => {
                setOpenModal(true);
              }}
              disableRipple>
              <span className="material-icons">delete</span>{" "}
            </IconButton>
          }
        />
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteRegion"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}></DeleteModal>
      </>
    );
  };

  const RegionColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "managerName",
      headerName: "Manager",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "states",
      headerName: "States",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <RegionEdit index={params.row.id} />
            <RegionDelete id={params.row.id} name={params.row["name"] || ""} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box marginY={"10px"}>
        <BaseButton
          text={"Create New Region"}
          marginLeft={"0px !important"}
          to="addRegion"
          endIcon={"terrain"}
        />
      </Box>

      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <BaseDataGrid
        rows={regions}
        columns={RegionColumns}
        error={isError}
        loading={isLoading}
        autoHeight={false}
        height={{ md: "54dvh", xl: "55dvh" }}
      />
    </>
  );
}
