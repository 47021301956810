import React, { useEffect } from "react";
import {
  useAddCorporateGroupMutation,
  useUpdateCorporateGroupMutation,
  useGetCorporateGroupByIdQuery,
  useAddCorporateGroupLogoMutation,
  useUpdateCorporateGroupLogoMutation,
  useDeleteCorporateGroupLogoMutation,
} from "../../../../../../store/apis/CorporateGroupApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import BaseRichTextEditor from "../../../../../shared/baseForm/BaseRichTextEditor";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { BaseButton } from "../../../../../shared/BaseButton";
import { CORPORATE_GROUP_FIELDS } from "./forms.constants";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";

export default function AddCorporateGroup() {
  const [searchParams] = useSearchParams();

  const corporateId = searchParams.get("id");

  const isEdit = !!corporateId;

  const {
    data: corporateData,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetCorporateGroupByIdQuery(corporateId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const [
    addCorporateGroup,
    {
      isError: isAddError,
      isSuccess: isAddSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddCorporateGroupMutation();

  const [
    updateCorporateGroup,
    {
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdateCorporateGroupMutation();

  const [addLogo] = useAddCorporateGroupLogoMutation();

  const [updateLogo] = useUpdateCorporateGroupLogoMutation();

  const [deleteLogo] = useDeleteCorporateGroupLogoMutation();

  const isSuccess = isAddSuccess || isUpdateSuccess;
  const isError = isAddError || isUpdateError;
  const error = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const navigate = useNavigate();

  if (isSuccess) {
    navigate(-1);
  }

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isError && error) {
      setErrorsForProperties({ setError: setError, responseError: error });
    }
  }, [error]);

  useEffect(() => {
    if (currentSuccess && !currentFetching && corporateData) {
      reset(corporateData);
    }
  }, [corporateData]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateCorporateGroup(data);
    } else {
      addCorporateGroup({
        ...data,
        documentContainer: "",
      });
    }
  };

  function handleDeleteLogo() {
    deleteLogo(corporateId);
  }

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("CorporateGroupId", corporateId);
      formData.append("File", file);

      if (corporateData?.imageUrl) {
        // update
        updateLogo(formData);
      } else {
        // add
        addLogo(formData);
      }
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseButton
            text="Back To Corporate Group List"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />

          <Divider sx={{ marginBottom: "15px", border: "none" }} />
          <Box padding={"8px"}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}>
              <GeneratedForm
                control={control}
                list={CORPORATE_GROUP_FIELDS}
                errors={errors}
              />
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  component={"p"}
                  fontWeight={600}
                  fontSize={14}
                  color={"primary"}
                  sx={{ lineHeight: 1 }}>
                  WAAG Disclaimer. Leave blank to use the standard text.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BaseRichTextEditor
                  label="WAAG Disclaimer; Leave blank to use the standard text."
                  id="waagdisclaimer"
                  name="waagdisclaimer"
                  placeholder=""
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  component={"p"}
                  fontWeight={600}
                  fontSize={14}
                  color={"primary"}
                  sx={{ lineHeight: 1 }}>
                  Spreadsheet Guildlines. Leave blank to use the standard text.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BaseRichTextEditor
                  label="Spreadsheet Guidelines; Leave blank to use the standard text."
                  id="spreadsheetGuidelines"
                  name="spreadsheetGuidelines"
                  placeholder=""
                  control={control}
                  errors={errors}
                />
              </Grid>
              {isEdit ? (
                <Grid item xs={11}>
                  {corporateData?.imageUrl ? (
                    <Box
                      component="img"
                      sx={{
                        width: "50%",
                        marginLeft: "20px",
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                      alt="Corporate Group Logo"
                      src={corporateData?.imageUrl}
                    />
                  ) : undefined}

                  {corporateData?.imageUrl ? (
                    <Box component="div" sx={{ marginTop: "20px" }}>
                      <Button
                        component="label"
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                          borderRadius: "var(--borderRadius)",
                          marginRight: "20px",
                        }}
                        startIcon={
                          <span className="material-icons">image</span>
                        }>
                        <input
                          type="file"
                          accept=".png, .jpg, ,.jpeg, .bmp"
                          hidden
                          onChange={handleFileUpload}
                        />
                        Update Logo
                      </Button>
                      <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{ borderRadius: "var(--borderRadius)" }}
                        onClick={handleDeleteLogo}
                        startIcon={
                          <span className="material-icons">remove</span>
                        }>
                        Remove Logo
                      </Button>
                    </Box>
                  ) : (
                    <Box component="div" sx={{ marginTop: "20px" }}>
                      <Button
                        component="label"
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{ borderRadius: "var(--borderRadius)" }}
                        startIcon={
                          <span className="material-icons">image</span>
                        }>
                        <input
                          type="file"
                          accept=".png, .jpg, ,.jpeg, .bmp"
                          hidden
                          onChange={handleFileUpload}
                        />
                        Add Logo
                      </Button>
                    </Box>
                  )}
                </Grid>
              ) : undefined}
              <Grid item md={12}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Submit" : "Create"}
                />
              </Grid>
              <Grid item md={12}>
                <ValidationErrorSummary errors={error} formErrors={errors} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
