import { Box, Button, Card } from "@mui/material";
import React from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { useGetRecipeFoodTypeQuery } from "../../../../../store/apis/RecipeFoodTypeAPIs";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../shared/EditButton";
import DeleteButton from "../../../../shared/DeleteButton";

export const DislikesIntolerances = () => {
  const [searchParams] = useSearchParams();
  const currentLocation = useLocation();
  const canEdit = currentLocation.state?.canEdit;
  const recipeId = searchParams.get("recipeId");

  const {
    data: RecipeFoodTypeData,
    isFetching: loading,
    isError: hasError,
  } = useGetRecipeFoodTypeQuery(
    {
      id: recipeId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const RecipeFoodTypesColumn = [
    {
      field: "name",
      minWidth: 1150,
      flex: 1,
      headerName: "Food Type",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return canEdit === true ? (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addDislikesIntolerances?id=${params.row.id}&recipeId=${recipeId}`}
              data={RecipeFoodTypeData}
              title="add DislikesIntolerances"
            />

            <DeleteButton
              index={params.row.id}
              entityName={params.row?.name}
              apiPath="deleteRecipesFoodType"
            />
          </div>
        ) : null;
      },
    },
  ];
  return (
    <Card>
      {canEdit === true ? (
        <Box marginTop={"10px"}>
          <Button
            component={Link}
            to={`addDislikesIntolerances?recipeId=${recipeId}`}
            color={"primary"}
            className="baseButton"
            startIcon={<span className="material-icons">thumb_down</span>}
            variant="contained">
            Add New
          </Button>
        </Box>
      ) : null}
      <Box padding={"10px"}>
        <BaseDataGrid
          rows={RecipeFoodTypeData}
          columns={RecipeFoodTypesColumn}
          error={hasError}
          loading={loading}
          autoHeight={false}
          height={{ md: "60vh", xl: "63vh" }}
        />
      </Box>
    </Card>
  );
};
