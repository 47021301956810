import { FormControlLabel } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/EditButton.md)
 */
export const EditButton = ({
  state,
  title,
  index,
  To,
  data,
  icon,
  onClick,
  prefix = "Edit",
}) => {
  return (
    <FormControlLabel
      className="editButton"
      control={
        <Link
          onClick={onClick}
          to={To}
          className="editIcon"
          state={{
            title: `${prefix} ${title}`,
            data: data?.find((row) => row.id === index),
            ...state,
          }}>
          <span className="material-icons">
            {/* {icon === "visibility" ? "visibility" : "edit"} */}
            {icon ? icon : "edit"}
          </span>
        </Link>
      }
    />
  );
};
