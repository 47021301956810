import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  facilityName: null,
}

export const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setFacilityName: (state, action) => {
      state.facilityName = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFacilityName } = userSlice.actions

export default userSlice.reducer